import Vue from 'vue';
import VueCookies from 'vue-cookies';
import { datadogLogs } from '@datadog/browser-logs';
import jwt from 'jsonwebtoken';
import axios from '../../axios';
import router from '../../router';
import { TOASTER_MESSAGE } from '../../constants';

Vue.use(VueCookies);
const isObject = function (obj) {
  return obj === Object(obj);
};
const { getCurrencies } = require('@/services/user/user.service');

export default {
  state() {
    return {
      message: 'Vuex is working',
      token: '',
      error: null,
      sucessMessage: null,
      currencyData: {},
    };
  },
  getters: {
    getCurrencyData: state => state.currencyData,
  },
  mutations: {
    setUser(state, payload) {
      state.token = payload.token;
      Vue.$cookies.set('token', state.token);
      Vue.$cookies.set('isLoggedIn', true);
      if (!payload.isNotRedirect) {
        router.push('/');
        const nextPath = Vue.$cookies.get('nextPath');
        const afterLoginPath = Vue.$cookies.get('afterLoginPath');
        if (!payload.isNotRedirect) {
          router.push(nextPath || '/');
          Vue.$cookies.set('nextPath', '');
        }
        if (afterLoginPath) {
          router.push(afterLoginPath);
          Vue.$cookies.set('afterLoginPath', '');
        }
      }
    },
    setGuestUser(state, payload) {
      state.token = payload.token;
      Vue.$cookies.set('token', state.token);
      Vue.$cookies.set('isLoggedIn', true);
    },
    setLoginError(state, payload) {
      state.error = payload?.response?.data?.error || TOASTER_MESSAGE.TROUBLE;
    },
    setSignUpError(state, payload) {
      state.error = payload?.response?.data?.error || TOASTER_MESSAGE.TROUBLE;
    },
    setSignUpErrorSignup(state, payload) {
      state.error = payload?.response?.data?.error || 'Please enter valid mobile number and date of birth';
    },
    // setResetPasswordError(state, payload) {
    //   if (payload) {
    //     state.error = TOASTER_MESSAGE.TROUBLE;
    //   }
    // },
    signup(state, payload) {
      state.token = payload.token;
      Vue.$cookies.set('token', payload.token);
      Vue.$cookies.set('isLoggedIn', true);
      const prevRoute = localStorage.getItem('prevRoute');
      if (!payload.fromLogin && prevRoute) {
        router.push(prevRoute);
      } else {
        router.push('/');
      }
    },
    passwordResetted(state) {
      state.sucessMessage = 'You will receive a password at your email address in a few minutes.';
    },
    resetError(state, payload) {
      state.error = payload;
    },
    resetSuccessMessage(state, payload) {
      state.sucessMessage = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
    setCurrencyData(state, newData) {
      state.currencyData = newData;
      Vue.$cookies.set('getCurrencyData', newData);
    },
  },
  actions: {
    async loginUser({ commit }, payload) {
      try {
        const getVatgstDetails = await getCurrencies();
        if (!Vue.$cookies.get('localeInfo') || !Vue.$cookies.get('localeInfo')?.countryNameVatgst) {
          const filterCountry = getVatgstDetails.data.filter(detail => detail.countryname === 'Australia');
          payload.vatgst = filterCountry[0]._id;
        } else if (Vue.$cookies.get('localeInfo') && Vue.$cookies.get('localeInfo').countryNameVatgst !== '') {
          const filterCountry = getVatgstDetails.data.filter(detail => detail.countryname === Vue.$cookies.get('localeInfo').countryNameVatgst);
          payload.vatgst = filterCountry[0]._id;
        }

        const { data } = await axios.post('user/login', payload);
        const usr = jwt.decode(data.token);
        datadogLogs.logger.info('authentication', {
          evt: {
            name: 'authentication',
            type: 'timeflyz',
            outcome: 'ok',
          },
          usr,
        });
        commit('setUser', data);
      } catch (err) {
        commit('setLoginError', err);
      }
    },
    async loginGuestUser({ commit }, payload) {
      try {
        const getVatgstDetails = await getCurrencies();
        if (!Vue.$cookies.get('localeInfo') || !Vue.$cookies.get('localeInfo')?.countryNameVatgst) {
          const filterCountry = getVatgstDetails.data.filter(detail => detail.countryname === 'Australia');
          payload.vatgst = filterCountry[0]._id;
        } else if (Vue.$cookies.get('localeInfo') && Vue.$cookies.get('localeInfo').countryNameVatgst !== '') {
          const filterCountry = getVatgstDetails.data.filter(detail => detail.countryname === Vue.$cookies.get('localeInfo').countryNameVatgst);
          payload.vatgst = filterCountry[0]._id;
        }
        const { data } = await axios.post('user/login', payload);
        const usr = jwt.decode(data.token);
        datadogLogs.logger.info('authentication', {
          evt: {
            name: 'authentication',
            type: 'guest',
            outcome: 'ok',
          },
          usr,
        });
        commit('setGuestUser', data);
      } catch (err) {
        commit('setLoginError', err);
      }
    },
    async googleSignin({ commit }, payload) {
      try {
        const getVatgstDetails = await getCurrencies();
        let filterCountry = '';
        if (!Vue.$cookies.get('localeInfo') || !Vue.$cookies.get('localeInfo')?.countryNameVatgst) {
          filterCountry = getVatgstDetails.data.filter(detail => detail.countryname === 'Australia');
        } else if (Vue.$cookies.get('localeInfo') && Vue.$cookies.get('localeInfo').countryNameVatgst !== '') {
          filterCountry = getVatgstDetails.data.filter(detail => detail.countryname === Vue.$cookies.get('localeInfo').countryNameVatgst);
        }

        const { data } = await axios.post('user/login/google', {
          code: isObject(payload) ? payload.payload : payload,
          client: 'WEB',
          signupDevice: $cookies.get('device'),
          vatgst: filterCountry[0]._id,
        });
        data.isNotRedirect = isObject(payload) ? payload?.isNotRedirect : false;
        const usr = jwt.decode(data.token);
        datadogLogs.logger.info('authentication', {
          evt: {
            name: 'authentication',
            type: 'google',
            outcome: 'ok',
          },
          usr,
        });
        commit('setUser', data);
      } catch (err) {
        commit('setLoginError', err);
      }
    },
    async facebookSignin({ commit }, payload) {
      try {
        const getVatgstDetails = await getCurrencies();
        let filterCountry = '';
        if (!Vue.$cookies.get('localeInfo') || !Vue.$cookies.get('localeInfo')?.countryNameVatgst) {
          filterCountry = getVatgstDetails.data.filter(detail => detail.countryname === 'Australia');
        } else if (Vue.$cookies.get('localeInfo') && Vue.$cookies.get('localeInfo').countryNameVatgst !== '') {
          filterCountry = getVatgstDetails.data.filter(detail => detail.countryname === Vue.$cookies.get('localeInfo').countryNameVatgst);
        }

        const { data } = await axios.post('user/login/facebook', {
          code: isObject(payload) ? payload.payload : payload,
          signupDevice: $cookies.get('device'),
          vatgst: filterCountry[0]._id,
        });
        data.isNotRedirect = isObject(payload) ? payload?.isNotRedirect : false;
        const usr = jwt.decode(data.token);
        datadogLogs.logger.info('authentication', {
          evt: {
            name: 'authentication',
            type: 'facebook',
            outcome: 'ok',
          },
          usr,
        });
        commit('setUser', data);
      } catch (err) {
        commit('setLoginError', err);
      }
    },
    async appleSignin({ commit }, payload) {
      try {
        const getVatgstDetails = await getCurrencies();
        let filterCountry = '';
        if (!Vue.$cookies.get('localeInfo') || !Vue.$cookies.get('localeInfo')?.countryNameVatgst) {
          filterCountry = getVatgstDetails.data.filter(detail => detail.countryname === 'Australia');
        } else if (Vue.$cookies.get('localeInfo') && Vue.$cookies.get('localeInfo').countryNameVatgst !== '') {
          filterCountry = getVatgstDetails.data.filter(detail => detail.countryname === Vue.$cookies.get('localeInfo').countryNameVatgst);
        }

        const { data } = await axios.post('user/login/apple', {
          response: {
            identityToken: isObject(payload) ? payload.payload : payload,
            signupDevice: $cookies.get('device'),
            vatgst: filterCountry[0]._id,
          },
        });
        data.isNotRedirect = payload === Object(payload) ? payload?.isNotRedirect : false;
        const usr = jwt.decode(data.token);
        datadogLogs.logger.info('authentication', {
          evt: {
            name: 'authentication',
            type: 'apple',
            outcome: 'ok',
          },
          usr,
        });
        commit('setUser', data);
      } catch (err) {
        commit('setLoginError', err);
      }
    },
    async registerGuestUser({ commit }, payload) {
      try {
        const { fromLogin, ...signUpRecord } = payload;

        const getVatgstDetails = await getCurrencies();
        let filterCountry = '';
        if (!Vue.$cookies.get('localeInfo') || Vue.$cookies.get('localeInfo').countryNameVatgst === '') {
          filterCountry = getVatgstDetails.data.filter(detail => detail.countryname === 'Australia');
          signUpRecord.vatgst = filterCountry[0]._id;
        }
        if (Vue.$cookies.get('localeInfo') && Vue.$cookies.get('localeInfo').countryNameVatgst !== '') {
          filterCountry = getVatgstDetails.data.filter(detail => detail.countryname === Vue.$cookies.get('localeInfo').countryNameVatgst);
          signUpRecord.vatgst = filterCountry[0]._id;
        }
        const { data } = await axios.post('user/create', signUpRecord);
        const usr = jwt.decode(data.token);
        datadogLogs.logger.info('authentication', {
          evt: {
            name: 'authentication',
            type: 'guest',
            outcome: 'ok',
          },
          usr,
        });
        commit('setGuestUser', data);
      } catch (err) {
        commit('setLoginError', err);
      }
    },
    async guestToken({ commit }, payload) {
      try {
        const { data } = await axios.post('user/guestToken', payload);
        commit('setGuestUser', data);
      } catch (err) {
        commit('setLoginError', err);
      }
    },
    async signUpUser({ commit }, payload) {
      try {
        const { fromLogin, ...signUpRecord } = payload;


        const getVatgstDetails = await getCurrencies();
        let filterCountry = '';
        if (!Vue.$cookies.get('localeInfo') || Vue.$cookies.get('localeInfo').countryNameVatgst === '') {
          filterCountry = getVatgstDetails.data.filter(detail => detail.countryname === 'Australia');
          signUpRecord.vatgst = filterCountry[0]._id;
        }
        if (Vue.$cookies.get('localeInfo') && Vue.$cookies.get('localeInfo').countryNameVatgst !== '') {
          filterCountry = getVatgstDetails.data.filter(detail => detail.countryname === Vue.$cookies.get('localeInfo').countryNameVatgst);
          signUpRecord.vatgst = filterCountry[0]._id;
        }

        const { data } = await axios.post('user/create', signUpRecord);
        datadogLogs.logger.info('sign-up-user', {
          evt: { name: 'sign-up' },
          ...data,
        });
        commit('signup', { ...data, fromLogin });
      } catch (err) {
        commit('setSignUpErrorSignup', err);
      }
    },
    async resetPassword({ commit }, payload) {
      try {
        const { data } = await axios.put('user/me/password/forgot', payload);
        datadogLogs.logger.info('Change Password', data);
        commit('passwordResetted', data);
      } catch (err) {
        commit('setSignUpError', err);
      }
    },
    resetUserError({ commit }) {
      commit('resetError', null);
    },
    setLoginError({ commit }) {
      commit('setLoginError');
    },
    resetSuccessMessage({ commit }) {
      commit('resetSuccessMessage', null);
    },
    setError({ commit }, payload) {
      commit('setError', payload);
    },
    setCurrencyData({ commit }, newData) {
      commit('setCurrencyData', newData);
    },
  },
};
