<template>
  <div class="view-booking-container">
    <div class="view-booking-subcontainer">
      <div class="view-booking-header">
        <Button
          @buttonClicked="goToBookings()"
          type="link"
          value="Back"
          icon="mdi-keyboard-backspace"
          class="back-button"
        />
        <v-flex d-flex xs6 justify-end>
          <h1>Your booking</h1>
        </v-flex>
        <v-flex xs4></v-flex>
      </div>
      <div class="card-section-container">
        <div class="card-section-right">
          <v-img
            v-if="
              booking?.hotelId?.photos && booking?.hotelId?.photos?.length > 0
            "
            height="168px"
            :src="booking?.hotelId?.photos[0]"
          />
          <div
            class="no-image-card"
            v-if="
              booking?.hotelId?.photos && booking?.hotelId?.photos?.length === 0
            "
          >
            <div>
              <v-img src="@/assets/no-image.svg"></v-img>
            </div>
          </div>
          <div class="cards">
            <div class="hotel-name">
              {{ booking?.hotelId?.name }}
            </div>
            <!-- <v-flex d-flex class="stars">
              <v-rating readonly v-model="booking.hotelId.rating"
              background-color="yellow lighten-3" half-increments
              color="#FFC338" small ></v-rating>
            </v-flex> -->
            <v-card class="bed-amenties">
              <div>{{ booking?.roomId?.name }}</div>
              <div class="bed-details">
                <img src="@/assets/hotel-bed.svg" />
                <div class="bed-counts">
                  <span v-if="booking?.roomId?.kingBeds !== 0">
                    {{ booking?.roomId?.kingBeds }} King Beds
                  </span>
                  <span v-if="booking?.roomId?.queenBeds !== 0">
                    {{ booking?.roomId?.queenBeds }} Queen Beds
                  </span>
                  <span v-if="booking?.roomId?.singleBeds !== 0">
                    {{ booking?.roomId?.singleBeds }} Single Beds
                  </span>
                  <span v-if="booking?.roomId?.doubleBeds !== 0">
                    {{ booking?.roomId?.doubleBeds }} Double Beds
                  </span>
                </div>
              </div>
              <div class="bed-details">
                <img src="@/assets/key.png" />
                <div class="bed-counts">Access hotel amenties</div>
              </div>
            </v-card>
            <v-card>
              <div class="card-section">
                <b>Reservation details</b>
              </div>
              <div class="card-section">
                <div>
                  <b>Reference</b>
                </div>
                <div>{{ booking.bookingId }}</div>
              </div>
              <div class="card-section">
                <div>
                  <b>Name</b>
                </div>
                <div>
                  {{ booking?.guest?.firstName }} {{ booking?.guest?.lastName }}
                </div>
              </div>
              <div class="card-section">
                <div>
                  <b>Status</b>
                </div>
                <div>{{ booking.status }}</div>
              </div>
              <div class="card-section">
                <div>
                  <b>Check in date</b>
                </div>
                <div>{{ booking.date }}</div>
              </div>
              <div class="card-section">
                <div>
                  <b>Check in time</b>
                </div>
                <div>{{ booking.checkIn }}</div>
              </div>
              <div class="card-section">
                <div>
                  <b>Stay duration</b>
                </div>
                <div>{{ booking.duration }}h</div>
              </div>
            </v-card>
          </div>
          <v-card>
            <div class="card-section">
              <b>Room details</b>
            </div>
            <div class="card-section">
              <div>
                <b>{{ booking?.roomId?.name }}</b>
              </div>
              <div>
                {{ booking.duration }}h for
                <span>
                  {{ get(booking, 'charge.userVatgst.shortName', 'AU$') }}{{ parseFloat(get(booking, 'charge.cost', 0) * booking.charge.userExchangeRate).toFixed(2) }}
                </span>
              </div>
            </div>
          </v-card>
          <v-card>
            <div class="card-section">
              <b>Payment details</b>
            </div>
            <div class="card-section">
              <div>
                <b>Cost</b>
              </div>
              <div>
                {{ get(booking, 'charge.userVatgst.shortName', 'AU$') }}{{ parseFloat(get(booking, 'charge.cost', 0) * booking.charge.userExchangeRate).toFixed(2) }}
              </div>
            </div>
            <div class="card-section">
              <div>
                <b>Discount</b>
              </div>
              <div>{{ get(booking, 'charge.userVatgst.shortName', 'AU$') }}{{ parseFloat(totalDiscount * booking.charge.userExchangeRate).toFixed(2) }}</div>
            </div>
            <div class="card-section">
              <div>
                <b>Surcharge</b>
              </div>
              <div>
                {{ get(booking, 'charge.userVatgst.shortName', 'AU$') }}{{ parseFloat(get(booking, 'charge.surcharge', 0) * booking.charge.userExchangeRate).toFixed(2) }}
              </div>
            </div>
            <div class="card-section">
              <div>
                <b>Total</b>
              </div>
              <div>
                {{ get(booking, 'charge.userVatgst.shortName', 'AU$') }}{{ parseFloat(get(booking, 'charge.total', 0) * booking.charge.userExchangeRate).toFixed(2) }}
              </div>
            </div>
            <div class="card-section">
              <div>
                <b>Payment method</b>
              </div>
              <div v-if="booking.payAtHotel">Pay at hotel</div>
              <div v-else>Pay Now</div>
            </div>
            <div class="card-section">
              <div>
                <b>Status</b>
              </div>
              <div v-if="booking.refund">Refunded</div>
              <div
                v-else-if="
                  (booking.charge && booking.charge.status === 'success') ||
                  booking.charge.status === 'charged'
                "
              >
                Paid
              </div>
              <div v-else>Not Paid</div>
            </div>
            <div
              class="card-section"
              v-if="booking.charge && booking.charge.receiptUrl"
            >
              <div>
                <b>Online booking confirmation</b>
              </div>
              <a target="_blank" :href="booking.charge.receiptUrl"
                >Click to open</a>
            </div>
            <div class="card-section"
                 v-if="booking.charge && booking.charge.receiptUrl">
              <div>
                <b>Booking confirmation PDF</b>
              </div>
              <span class="href-text" @click="goToReceipt">Click to open</span>
            </div>
          </v-card>
          <v-card>
            <div class="card-section">
              <div>
                <b>Guest comments</b>
              </div>
            </div>
            <div class="card-section">
              <div>{{ get(booking, 'guest.comment', 'None') }}</div>
            </div>
          </v-card>
        </div>
        <div class="card-section-left">
          <v-card>
            <div class="card-section">
              <div>
                <b>Your comments</b>
              </div>
            </div>
            <div class="card-section">
              <div v-if="booking.ownerComment">{{ booking.ownerComment }}</div>
              <div v-if="!booking.ownerComment">None</div>
            </div>
          </v-card>
        </div>
      </div>
      <Button
        v-if="booking.status === 'Booked'"
        @buttonClicked="handleClickCancel()"
        class="cancel-booking"
        type="link"
        value="Cancel booking"
      />
    </div>
    <div v-if="openDialog">
      <v-dialog v-model="openDialog" max-width="370px">
        <Dialog
          :dialogData="cancelAlert"
          :onDialogResponse="cancelCurrentBooking"
          :onCloseDialog="closeDialog"
          mode="alert"
          title="Cancel this Booking?"
          alertActionMessage="Cancel booking"
        />
      </v-dialog>
    </div>
    <!--<v-snackbar :timeout="timeout" v-model="snackbar" bottom
    right>{{ toasterMessage }}</v-snackbar>-->
    <div v-if="openErrorDialog">
      <v-dialog v-model="openErrorDialog" max-width="370px">
        <ErrorDialog
          :dialogData="toasterMessage"
          :onCloseDialog="closeErrorDialog"
          mode="snackbar-error"
        />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapMutations,
  mapActions,
} from 'vuex';
import lodash, { get } from 'lodash';
import moment from 'moment-timezone';
import { SNACKBAR_TIMEOUT } from '@/constants';
import Button from '@/common/Button.vue';
import Dialog from '@/common/Dialog.vue';
import ErrorDialog from '@/common/ErrorDialog.vue';

export default {
  name: 'ViewBooking',
  data() {
    return {
      get,
      booking: {},
      timeout: SNACKBAR_TIMEOUT,
      openErrorDialog: false,
      snackbar: false,
      toasterMessage: '',
      openDialog: false,
      rating: 5,
      cancelAlert:
        'If you cancel this booking, its status will be changed to “Cancelled”. Are you sure you want to cancel it?',
    };
  },
  components: {
    Button,
    Dialog,
    ErrorDialog,
  },
  computed: {
    ...mapState({
      selectedBooking: state => state.bookings.selectedBooking,
      bookingError: state => state.bookings.bookingError,
    }),
    totalDiscount() {
      return (
        this.booking.charge.hotelDiscount + this.booking.charge.timeflyzDiscount
      );
    },
  },
  created() {
    this.booking = lodash.cloneDeep(this.selectedBooking);
    if (lodash.isEmpty(this.booking)) {
      this.goToBookings();
    }
  },
  watch: {
    bookingError() {
      if (this.bookingError !== null) {
        this.snackbar = true;
        this.openErrorDialog = true;
        this.toasterMessage = this.bookingError;
      }
      // setTimeout(() => this.resetError(), SNACKBAR_TIMEOUT);
    },
  },
  methods: {
    ...mapMutations(['setBooking']),
    ...mapActions(['cancelBooking', 'resetBookingError']),
    isGreaterThan24Hours() {
      const todayUTC = moment().utc();
      const checkinUTC = moment(this.booking.reservationStart)
        .tz(this.booking?.timezone || 'Australia/Sydney')
        .utc();
      return (
        todayUTC.isBefore(checkinUTC) && todayUTC.diff(checkinUTC, 'hours', true) < -24
      );
    },
    handleClickCancel() {
      this.cancelAlert = this.isGreaterThan24Hours() ? 'If you cancel this booking, its status will be changed to “Cancelled”. Are you sure you want to cancel it?' : 'Your hotel is booked for less than 24h. You can cancel it now, but you won’t be able to receive a refund.';
      this.openDialog = true;
    },
    goToBookings() {
      this.$router.push({ path: '/bookings/upcoming' });
    },
    goToReceipt() {
      this.$router.push({ path: `/receipt/${this.booking?._id}` });
    },
    async cancelCurrentBooking() {
      this.openDialog = false;
      await this.cancelBooking(this.booking);
      if (!this.bookingError) {
        this.snackbar = true;
        this.openErrorDialog = true;
        this.toasterMessage = 'Your hotel booking was cancelled';
        this.booking.status = 'Cancelled';
      }
    },
    closeDialog() {
      this.openDialog = false;
    },
    closeErrorDialog() {
      this.resetBookingError();
      this.snackbar = false;
      this.openErrorDialog = false;
      this.$router.push({ path: '/' });
    },
    resetError() {
      this.snackbar = false;
      this.resetBookingError();
    },
  },
  beforeDestroy() {
    // remove booking from store
    this.setBooking({});
  },
};
</script>

<style lang="scss" scoped>
.href-text {
  color: #ff4500;
  cursor: pointer;
  text-decoration: underline;
  font-size: 16px;
}
.view-booking-container {
  padding: 2.9rem 0 2.9rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .no-image-card {
    height: 168px;
    background-color: #c4c5cc;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .view-booking-subcontainer {
    width: 970px;

    ::v-deep .v-text-field__details {
      display: none;
    }

    ::v-deep .v-input__slot {
      margin-bottom: unset !important;
    }

    .view-booking-header {
      display: flex;
      align-items: center;
      margin-bottom: 40px !important;

      .back-button {
        margin-right: 40px;
      }
    }

    .v-card {
      margin-bottom: 17px;
    }

    .card-section-right {
      width: 570px;

      .hotel-name {
        font-size: 18px;
        line-height: 24px;
      }

      .bed-details {
        display: flex;
        align-items: center;
        margin-top: 8px;

        img {
          width: 15px;
          height: 15px;
        }

        .bed-counts {
          margin-left: 10px;
        }
      }

      .stars {
        display: flex;
        padding: 10px 0 25px 0;

        .star {
          margin-right: 2px;
        }
      }

      ::v-deep .v-rating .v-icon {
        padding: unset !important;
      }

      .cards {
        padding: 15px;
        background-color: #ffffff;
        margin-bottom: 15px;

        .v-card {
          border: 2px solid #dcdee6;
          box-shadow: none !important;
        }
      }
    }

    .card-section-left {
      width: 370px;
      margin-left: 30px;
    }

    .cancel-booking {
      margin-top: 10px;

      ::v-deep .link-button {
        width: 570px;
        font-weight: bold;
      }
    }
  }

  .card-section {
    padding: 12px 16px;
    border-bottom: 1px solid #dcdde6;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .comments {
      display: flex;
      flex: 100%;
    }

    ::v-deep .v-text-field {
      width: 270px;
    }
  }

  .bed-amenties {
    font-size: 16px;
    padding: 10px 15px;
  }

  .card-section-container {
    display: flex;
    flex-direction: row;
  }
}

// responsiveness design
@media only screen and (max-width: 1024px) {
  .view-booking-subcontainer {
    width: 100% !important;
  }

  .view-booking-header {
    h1 {
      font-size: 24px !important;
    }
  }

  .card-section-container {
    flex-direction: column !important;
  }

  .card-section-right {
    width: 100% !important;
  }

  .card-section-left {
    width: 100% !important;
    margin-left: unset !important;
  }

  .cancel-booking {
    ::v-deep .link-button {
      width: 100% !important;
    }
  }
}
</style>
