import qs from 'qs';
import Vue from 'vue';
import VueRouter from 'vue-router';
import ViewBooking from '@/components/Bookings/ViewBooking.vue';
import BookingSuccess from '@/views/BookingSuccess.vue';
import SearchHotels from '../views/SearchHotels.vue';
import Favourites from '../views/Favourites.vue';
import Bookings from '../views/Bookings.vue';
import ViewHotel from '../views/ViewHotel.vue';
import SignUp from '../views/SignUp.vue';
import Login from '../views/Login.vue';
import Home from '../views/Homepage.vue';
import NotFound from '../views/NotFound.vue';


['push', 'replace'].forEach((method) => {
  const originalMethod = VueRouter.prototype[method];
  VueRouter.prototype[method] = function m(location) {
    return originalMethod.call(this, location).catch((error) => {
      if (error.toString().indexOf('NavigationDuplicated') === 0) {
        return;
      }
      throw error;
    });
  };
});

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('../views/ResetPassword.vue'),
  },
  {
    path: '/change-password/:token',
    name: 'change-password',
    component: () => import('@/common/ChangePassword.vue'),
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: () => import('@/common/UpdatePassword.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/emailVerify/:userID',
    name: 'emailVerify',
    component: () => import('../views/EmailVerify.vue'),
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUp,
  },
  {
    path: '/appleLogin',
    name: 'AppleLogin',
    component: () => import('@/common/AppleLogin.vue'),
  },
  {
    path: '/appleAuthorize',
    name: 'AppleAuthorize',
    component: () => import('@/common/AppleAuthorize.vue'),
  },
  {
    path: '/view-hotel/:id',
    name: 'viewHotel',
    component: ViewHotel,
  },
  {
    path: '/book-hotel',
    meta: {
      subheader: 'number-tabs',
    },
    component: () => import('@/views/BookHotel.vue'),
    children: [
      {
        path: '',
        redirect: 'confirm',
      },
      {
        path: 'confirm',
        name: 'confirm',
        meta: {
          subheader: 'number-tabs',
        },
        component: () => import('@/components/BookHotel/Confirm/Confirm.vue'),
      },
      {
        path: 'guests',
        name: 'guests',
        meta: {
          subheader: 'number-tabs',
          requiresAuth: true,
        },
        component: () => import('@/components/BookHotel/Guests/Guests.vue'),
      },
      {
        path: 'payment',
        name: 'payment',
        meta: {
          subheader: 'number-tabs',
          requiresAuth: true,
        },
        component: () => import('@/components/BookHotel/Payment/Payment.vue'),
      },
    ],
  },
  {
    path: '/favourites',
    name: 'favourites',
    component: Favourites,
  },
  {
    path: '/hotels',
    name: 'hotels',
    component: SearchHotels,
  },
  {
    path: '/account/:mode',
    name: 'account',
    component: () => import('../views/Account.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/bookings/:mode',
    name: 'bookings',
    component: Bookings,
    meta: { requiresAuth: true },
  },
  {
    path: '/view-booking',
    name: 'ViewBooking',
    component: ViewBooking,
    meta: { requiresAuth: true },
  },
  {
    path: '/booking-success',
    name: 'BookingSuccess',
    component: BookingSuccess,
    meta: { requiresAuth: true },
  },
  {
    path: '/receipt/:reservationID/',
    name: 'Receipt',
    component: () => import('../views/Receipt.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '*',
    name: 'page-not-found',
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    const result = qs.stringify(query);
    return result ? (`?${result}`) : '';
  },
});

router.beforeEach((to, from, next) => {
  const accessToken = Vue.$cookies.get('token');
  if (from.path !== '/login' && from.path !== '/signup') {
    localStorage.setItem('prevRoute', from.path);
  }
  if ((to.path === '/login' || to.path === '/reset-password' || to.path === '/signup') && accessToken) {
    const nextPath = Vue.$cookies.get('nextPath');
    next({ path: nextPath || '/hotels' });
    Vue.$cookies.set('nextPath', '');
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!accessToken) {
      Vue.$cookies.set('nextPath', to.path);
      next({
        path: '/login',
        // query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
