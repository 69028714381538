<template>
    <div class="notfound-container">
<v-container class="bg-surface-variant child-container"  :style="{ marginTop: loggedIn ? 0 : '90px' }">
    <v-row no-gutters>
      <v-col>
        <v-sheet class="pa-10 ma-2 back-color">
          <div class="No-page-found-txt-1">404</div>
          <div class="bottom-line"/>
          <v-img class="No-page-found-img-mobile" src="@/assets/page_not_found_img.png" />
          <div class="No-page-found-txt-2">Oops! The page you are looking for could not be found.</div>
          <div class="home-btn" @click="wordPressSite">
            Back to Home</div>
        </v-sheet>
      </v-col>
      <v-col >
        <v-sheet class="pa-10 ma-2 back-color">
          <v-img class="No-page-found-img" src="@/assets/page_not_found_img.png" />
        </v-sheet>
      </v-col>
    </v-row>
</v-container>
    </div>
</template>
<script>
import Vue from 'vue';
import VueCookies from 'vue-cookies';

Vue.use(VueCookies);
export default {
  name: 'NotFound',
  data() {
    return {
      isMobile: window.innerWidth <= 768,
      loggedIn: this.$cookies.get('isLoggedIn'),
    };
  },
  methods: {
    wordPressSite() {
      window.location.href = 'https://timeflyz.co/';
    },
  },
};
</script>
<style lang="scss" scoped>
  .notfound-container {
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
   // background: #ddd;
    .child-container{
      width: 75%;
    }
    .back-color{
      background-color: #f8f8f8;
    }
    .No-page-found-img {
      width: 350px;
      height: 350px;
    }
    .No-page-found-img-mobile{
      width: 250px;
      height: 250px;
      margin-top: 20px;
    }
    .bottom-line{
      width: 87px;
      height: 10px;
      background: #ff4500;
      margin-top: -20px;
    }
    .No-page-found-txt-1{
      font-size: 90px;
      color: #ff4500;
      font-weight: 700;
    }
    .No-page-found-txt-2{
      font-size: 22px;
      color: #808080;
      font-weight: 800;
      margin-top: 70px;
    }
    .home-btn{
      justify-items: center;
      text-align: center;
      font-size: 18px;
      color: #fff;
      font-weight: 700;
      padding: 10px;
      width: 130px;
      background: #ff4500;
      margin-top: 20px;
      border-radius: 30px;
      cursor: pointer;
    }
  }
  // responsiveness design
  @media only screen and (min-width: 768px) {
    .notfound-container{
    .child-container{
      .No-page-found-img-mobile{
      display: none;
    }
    }
  }
  }
  @media only screen and (max-width: 768px) {
    .notfound-container{
    .child-container{
      width: 100%;
      .No-page-found-img-mobile{
      display: block;
    }
    .No-page-found-img{
      display: none;
    }
    .No-page-found-txt-2{
      font-size: 17px;
      margin-top: 50px;
    }
    }
  }
  }
</style>
