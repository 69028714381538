<template>
  <div :class="{'width-100' : isSearch}">
    <v-btn
      v-if="type !== 'link'"
      :icon="type === 'icon' ? true : false"
      :outlined="type === 'outlined' ? true : false"
      :type="type"
      @click="onButtonClick"
      :color="color"
      :height="height"
      :width="width"
      :class="buttonClass"
      :disabled="disabled"
      :loading="loading"
      :rounded="rounded"
      :style="btnStyle"
    >
      <svgicon v-if="icon && customIcon" :icon="icon" width="20" height="20" color="white">
      </svgicon>
      <v-icon :color="iconColor" v-if="icon && !customIcon">{{icon}}</v-icon>
      <span class="button-label" v-if="value">{{value}}</span>
    </v-btn>
    <div v-if="type === 'link'" class="link-button" :class="buttonClass" @click="onButtonClick">
      <svgicon v-if="icon && customIcon" :icon="icon" :width="width" :height="height"></svgicon>
      <v-icon v-if="icon && !customIcon">{{icon}}</v-icon>
      {{value}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Button',
  // isSearch prop is temporary fix for the search button in search-hotels page
  props: [
    'type',
    'color',
    'height',
    'width',
    'value',
    'buttonClass',
    'disabled',
    'icon',
    'loading',
    'customIcon',
    'iconColor',
    'isSearch',
    'block',
    'rounded',
    'btnStyle',
  ],
  methods: {
    onButtonClick() {
      this.$emit('buttonClicked');
    },
  },
};
</script>

<style lang="scss" scoped>
.link-button {
  cursor: pointer;
  color: #080914;
  opacity: 0.6;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .v-icon {
    padding-right: 5px;
  }
  ::v-deep svg {
    fill: #080914;
  }
}
.outlined-button {
  cursor: pointer;
  border: 1px solid #DCDDE6;
  justify-content: flex-start;
  font-size: 16px;
  .v-icon {
    font-size: 16px;
  }
}
.link-button:hover {
  color: $primary-color;
  opacity: 1;
  .v-icon {
    color: $primary-color;
  }
  ::v-deep svg {
    fill: $primary-color;
  }
}
// custom buttom
.v-btn {
  font-size: 1.3rem;
  font-weight: 500;
  transition: background-color 0.15s ease-in;
  text-transform: uppercase;
  letter-spacing: 2px;
  min-height: 40px;
}
.v-btn--contained.primary.error:hover {
  background: #e86a2d !important;
}
.v-btn--contained.error:hover {
  background: #e44648 !important;
}
.v-btn--contained {
  box-shadow: unset;
  ::v-deep .v-btn__content {
    font-weight: bold;
  }
}
.error-link:hover {
  color: $error-color;
}
.width-100 {
  width: 100%;
}
</style>
