<template>
	<v-layout row class="signup-form">
		<div class="overlay">
			<v-card class="remove-shadow" min-width="30%" v-bind:style= "[isToken ? {'margin-top': '0'} : {'margin-top': '5%'}]">
				<v-card-title class="header-title">{{ title }}</v-card-title>
				<v-flex class="social-media-container">
					<GoogleSignin @handleCredential="emitGoogleSignup" />
					<!-- <VFBLoginScope class="margin-top-1" :app-id="facebookAppId" @login="facebookSignup">
						<button class="facebook-button" slot-scope="scope" @click="scope.login">
							<v-icon class="facebook-icon" color="white">mdi-facebook</v-icon>Continue with Facebook
						</button>
					</VFBLoginScope> -->
					<AppleSignin @handleCredential="handleCredentialAppleResponse" class="margin-top-1"/>
                    <div class="signup-with-email">
                        Or signup with your email:
                    </div>
				</v-flex>
				<v-card class="card-color">
					<v-form class="pa-6" @submit.prevent="signUp">
						<v-flex class="mb-2">
							<span class="label">First name *</span>
						</v-flex>
						<TextField
							@emitTextValue="getFirstName"
							label
							:rules="[FORM_VALIDATION.required, FORM_VALIDATION.firstname]"
							placeholder="E.g Harry"
							:erro="error"
						/>
						<v-flex class="mb-2">
							<span class="label">Last name *</span>
						</v-flex>
						<TextField
							@emitTextValue="getLastName"
							label
							:rules="[FORM_VALIDATION.required, FORM_VALIDATION.lastname]"
							placeholder="E.g How"
							:erro="error"
						/>
                        <v-flex class="mb-2">
							<span class="label">Email *</span>
						</v-flex>
						<TextField
							@emitTextValue="getEmailAddress"
							label
							:rules="emailRules"
							placeholder="hello@timeflyz.com"
							:error="error"
						/>
						<v-flex class="mb-2">
							<span class="label">Mobile *</span>
							<vue-tel-input class="tel-input" v-model="mobile" @validate="validateMobileNumber"></vue-tel-input>
							<span class="phone-error" v-if="mobileError !== ''">{{ mobileError }}</span>
						</v-flex>
						<v-flex class="mb-2">
							<span class="label">Date of birth (guests must be over 18) *</span>
							<v-menu
								v-model="showDate"
								:close-on-content-click="false"
								transition="scale-transition"
								offset-y
								min-width="auto"
							>
								<template v-slot:activator="{ on, attrs }">
								<v-text-field
                  single-line
									outlined
									:value="computedDate"
									append-icon="mdi-calendar"
									readonly
                  :rules="[FORM_VALIDATION.required]"
									v-bind="attrs"
									v-on="on"
									@click:append="showDate = true"
                  :error="error"
                  clearable
								></v-text-field>
								</template>
								<v-date-picker
								no-title
								:value="now"
								@input="inputDate"
                :max="maxDate"
								></v-date-picker>
							</v-menu>
						</v-flex>
						<v-flex class="mb-1">
							<span class="label">Password *</span>
						</v-flex>
						<v-text-field
							v-model="password"
							placeholder="Password"
							single-line
							outlined
							:rules="[FORM_VALIDATION.required, FORM_VALIDATION.password]"
							:append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
							:type="show ? 'text' : 'password'"
							@click:append="show = !show"
							:error="error"
							:error-messages="errorMessages"
						></v-text-field>
                        <v-flex class="password-condition" v-if="showPasswordRule">
                            <span class="label">*Minimum 8 characters length, at least one uppercase & special character.</span>
                        </v-flex>
						<v-flex d-flex class="mb-3 checkbox-controls">
							<v-checkbox v-model="isChecked" color="#ff4500" hide-details></v-checkbox>
							I agree to the&nbsp; <a @click="goToTermsAndConditions()">Terms and conditions</a>
						</v-flex>
						<v-flex class="mt-2 mb-3">
							<Button
								type="submit"
								value="Sign up"
								color="primary"
								height="40px"
								width="100%"
								:disabled="disabled"
							/>
						</v-flex>
						<v-flex xs12 class="account">
							<v-flex xs12 class="already-a-user">
                                <hr />
                                <span>Already a TimeFlyz user?</span>
                                <hr />
                            </v-flex>
						</v-flex>
                        <v-flex xs12>
						    <Button
                                type="button"
                                @buttonClicked="login"
                                value="Log in"
                                width="100%"
                            />
                        </v-flex>
					</v-form>
					<!--<v-snackbar
        v-model="snackbar"
        bottom right
        :timeout="timeout"
        >
          {{ signupError }}
					</v-snackbar>-->
					<div v-if="openDialog">
						<v-dialog v-model="openDialog" max-width="370px">
							<Dialog :dialogData="signupError" :onCloseDialog="closeDialog" mode="snackbar-error" />
						</v-dialog>
					</div>
				</v-card>
			</v-card>
		</div>
	</v-layout>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { VFBLoginScope } from 'vue-facebook-login-component';
import moment from 'moment';
import { SNACKBAR_TIMEOUT } from '@/constants';
import validation from '@/validations';
import 'vue-tel-input/dist/vue-tel-input.css';
import Dialog from '@/common/ErrorDialog.vue';
import GoogleSignin from '../components/SSO/GoogleSignin.vue';
import TextField from './TextField.vue';
import Button from './Button.vue';
import AppleSignin from '../components/SSO/AppleSignin.vue';


export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: null,
    },
    errorMessages: {
      type: String,
      default: null,
    },
    timeout: SNACKBAR_TIMEOUT,
  },
  data() {
    return {
      openDialog: false,
      snackbar: false,
      firstName: '',
      lastName: '',
      email: '',
      isEmailValid: false,
      mobile: '',
      mobileError: '',
      password: '',
      showPasswordRule: true,
      dob: '',
      showDate: false,
      show: false,
      isChecked: false,
      maxDate: moment().subtract(18, 'years').format('YYYY-MM-DD'),
      emailRules: [
        validation.FORM_VALIDATION.required,
        validation.FORM_VALIDATION.email,
      ],
      params: {
        client_id: process.env.VUE_APP_WEB_CLIENT_ID,
      },
      FORM_VALIDATION: validation.FORM_VALIDATION,
      now: moment().subtract(18, 'years').format('YYYY-MM-DD'),
	  isToken: false,
    };
  },
  components: {
    TextField,
    Button,
    GoogleSignin,
    AppleSignin,
    VFBLoginScope,
    Dialog,
  },
  computed: {
    ...mapState({
      signupError: state => state.user.error,
    }),
    disabled() {
      if (
        !!this.firstName
					&& !!this.lastName
					&& this.isEmailValid
					&& this.mobileError === ''
					&& !!this.password
					&& this.isChecked
      ) {
        return false;
      }
      return true;
    },
    facebookAppId() {
      return process.env.VUE_APP_FACEBOOK_APP_ID;
    },
    computedDate() {
      return this.dob
        ? moment(this.dob).format('ddd DD MMM, YYYY')
        : '';
    },
  },
  watch: {
    $route() {
      this.setHeaderAccess();
    },
    signupError() {
      if (this.signupError !== null) {
        this.snackbar = true;
        this.openDialog = true;
      }
      // setTimeout(() => this.resetError(), SNACKBAR_TIMEOUT);
    },
    email() {
      if (validation.FORM_VALIDATION.email(this.email) === true) {
        this.isEmailValid = true;
      } else {
        this.isEmailValid = false;
      }
    },
    password() {
      if (validation.FORM_VALIDATION.password(this.password) === true) {
        this.showPasswordRule = true;
      } else {
        this.showPasswordRule = false;
      }
    },
  },
  methods: {
    ...mapActions(['resetUserError', 'setLoginError']),
    facebookSignup(facebookUser) {
      this.emitFacebookSignup(facebookUser?.authResponse?.accessToken);
    },
    handleCredentialAppleResponse(credential) {
      this.emitAppleLogin(credential);
    },
    onSuccess(googleUser) {
      // This only gets the user information: id, name, imageUrl and email
      this.emitGoogleSignup(googleUser.getAuthResponse().id_token);
    },
    onFailure() {
      this.setLoginError();
    },
    getFirstName(name) {
      this.firstName = name;
    },
    getLastName(name) {
      this.lastName = name;
    },
    getEmailAddress(email) {
      this.email = email;
    },
    setMobileNumber(_telnumber, telNumberObj) {
      this.mobile = telNumberObj.number;
    },
    emitFacebookSignup(token) {
      this.$emit('emitFacebookSignup', token);
    },
    emitGoogleSignup(token) {
      this.$emit('emitGoogleSignup', token);
    },
    emitAppleLogin(token) {
      this.$emit('emitAppleLogin', token);
    },
    signUp() {
      this.$emit('signup', {
        firstName: this.firstName,
        lastName: this.lastName,
        fullName: `${this.firstName} ${this.lastName}`,
        email: this.email,
        mobile: this.mobile,
        dob: moment(`${this.dob}T00:00:00.000Z`),
        password: this.password,
        userType: 'APP',
        signupDevice: this.$cookies.get('device'),
        encryption: false,
        vector: '5183666c72eec9e4',
      });
    },
    login() {
	  if (this.$route.path === '/book-hotel/confirm') {
        this.$emit('switchToSignup');
      } else {
        this.$router.push({ path: '/login' });
      }
    },
    validateMobileNumber(telnumber) {
      const valid = validation.FORM_VALIDATION.mobile(telnumber.formatted);
      this.mobileError = valid === true ? '' : valid;
    },
    goToTermsAndConditions() {
      this.$router.push('/terms-and-conditions');
    },
    navigateToAdminSignup() {
      window.open('https://timeflyz.co/list-your-hotel/', '_self');
    },
    resetError() {
      this.snackbar = false;
      this.resetUserError();
    },
    closeDialog() {
      this.snackbar = false;
      this.openDialog = false;
      this.resetUserError();
    },
    inputDate(text) {
      this.dob = text;
      this.showDate = false;
    },
    setHeaderAccess() {
      const accessToken = Vue.$cookies.get('token');
      if (accessToken) {
        this.isToken = true;
      } else {
        this.isToken = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
	.signup-form {
		height: 100%;
		position: relative;
		background-image: url("../assets/signup-min.jpg");
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		.overlay {
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.5);
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 50px 0px 50px 0px;
		}
		// Styles to customise google login button
		::v-deep .abcRioButtonContents {
			margin: unset !important;
		}
		::v-deep .abcRioButtonContentWrapper {
			display: flex;
			justify-content: flex-start;
		}
		.v-card__title {
			padding: 17px 24px;
			font-size: 18px;
			font-weight: bold;
		}
		.social-media-container {
			padding: 30px 0px 0px;
			display: flex;
			align-items: center;
			flex-direction: column;
		}
		.label {
			font-size: 16px;
		}
		.checkbox-controls {
			.v-input--selection-controls {
				padding: unset;
				margin: unset;
			}
			a {
				color: black;
				text-decoration: none;
			}
			a:hover {
				text-decoration: underline;
			}
		}
		.remove-shadow {
			box-shadow: none;
		}
		.card-color {
			background: white;
			box-shadow: none;
			border-top-left-radius: unset !important;
			border-top-right-radius: unset !important;
		}
		.account {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			color: #080914;
			opacity: 0.5;
			font-size: 16px;
			padding-top: 8px;
		}
		.cursor {
			cursor: pointer;
		}
		.sign-in-here:hover {
			color: $primary-color;
			font-weight: bold;
		}
		.tel-input {
			border: 2px solid #DCDEE6;
			background-color: #fff;
			height: 38px;
		}
		.phone-error {
			color: #F00;
		}
		.margin-top-1 {
			margin-top: 1rem;
		}
		.facebook-button {
			width: 199px;
			height: 38px;
			color: #fff;
			font-size: 14px;
			border-radius: 3px;
			background-color: #3c57a4;
			display: flex;
			justify-content: center;
			align-items: center;
			font-family: sans-serif;
			margin-bottom: 10px;
			.facebook-icon {
				margin-right: 5px;
			}
		}
		.google-button {
			min-width: 15rem;
			width: 100%;
			color: #000;
			font-size: 18px;
			font-weight: bold;
			box-sizing: border-box;
			box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 0px;
			padding: 1rem;
			border-radius: 0.25rem;
			display: flex;
			justify-content: center;
			font-family: sans-serif;
			.google-icon {
				margin-right: 2rem;
			}
		}
        .header-title {
            color: #ff4500;
            justify-content: center;
            font-size: 24px;
            font-weight: bold;
        }
        .signup-with-email {
            font-size: 14px;
            font-weight: 600;
            margin-top: 20px;
        }
        .password-condition {
            margin-top: -22px;
            margin-bottom: 30px;
        }
        .already-a-user {
            margin: 20px 0 !important;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            hr {
                width: 30px;
            }
        }
	}

	// responsiveness design
	@media only screen and (max-width: 768px) {
		.signup-form {
			.v-card {
				min-width: 100%;
			}
		}
	}
</style>
