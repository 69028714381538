<template>
  <v-card class="empty-state-container" max-height="360" min-height="360">
    <div class="empty-state-sub-container">
      <v-img v-if="imageUrl" :height="imageHeight" :width="imageWidth" :src="imageUrl"></v-img>
      <h3>{{emptyStateHeading}}</h3>
      <div class="empty-state-label">{{emptyStateLabel}}</div>
      <Button
        v-if="buttonValue"
        @buttonClicked="buttonClicked"
        :loading="inprogress"
        type="flat"
        :value="buttonValue"
        color="primary"
        height="40px"
      />
    </div>
  </v-card>
</template>

<script>
import Button from '@/common/Button.vue';

export default {
  name: 'EmptyStateCard',
  props: [
    'emptyStateHeading',
    'emptyStateLabel',
    'buttonValue',
    'inprogress',
    'imageUrl',
    'imageHeight',
    'imageWidth',
  ],
  components: {
    Button,
  },
  methods: {
    buttonClicked() {
      this.$emit('emptyStateClicked');
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-state-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .empty-state-sub-container {
    width: 420px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .v-image {
      margin-bottom: 2rem;
    }
    .empty-state-label {
      color: rgba(8, 9, 20, 0.7);
      line-height: 20px;
      margin: 3px 0 24px 0;
    }
  }
}
</style>
