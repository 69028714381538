<template>
    <div>
        <v-layout row class="login-form" >
            <div class="overlay">
                <LoginForm header="Login" @emitLogin="login" @emitGoogleLogin="googleLogin" @emitFacebookLogin="facebookLogin" @emitAppleLogin="appleLogin" />
            </div>
        </v-layout>
        <Footer />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LoginForm from '@/common/LoginForm.vue';
import Footer from '@/components/HomeFooter.vue';

export default {
  components: {
    LoginForm,
    Footer,
  },
  computed: {
    ...mapGetters({
      getCurrencyData: 'getCurrencyData',
    }),
  },
  methods: {
    ...mapActions(['loginUser', 'googleSignin', 'facebookSignin', 'appleSignin']),
    async login(email, password) {
      await this.loginUser({
        email,
        password,
        userType: 'APP',
        vatgst: this.getCurrencyData._id,
      });
    },
    async googleLogin(token) {
      await this.googleSignin(token);
    },
    async facebookLogin(token) {
      await this.facebookSignin(token);
    },
    async appleLogin(token) {
      await this.appleSignin(token);
    },
  },
};
</script>

<style scoped lang="scss">
    .login-form {
		height: 100%;
		position: relative;
		background-image: url("../assets/Relaxing in hotel-1-min.jpg");
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		.overlay {
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.5);
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 50px 0px 50px 0px;
		}
    }
    @media only screen and (max-width: 768px) {
		.login-form {
			.v-card {
				min-width: 100% !important;
			}
		}
    }
</style>
