<template>
  <div>
    <span v-if="label">{{label}}</span>
    <v-textarea
      autocomplete="off"
      v-model="textValue"
      @input="emitTextValue()"
      :auto-grow="autoGrow"
      outlined
      :rows="rows"
      :placeholder="placeholder"
      :row-height="rowHeight"
      :rules="rules"
      :required="required"
    ></v-textarea>
  </div>
</template>

<script>
export default {
  name: 'TextArea',
  data() {
    return {
      textValue: '',
    };
  },
  props: ['label', 'placeholder', 'autoGrow', 'rows', 'rowHeight', 'value', 'rules', 'required'],
  methods: {
    emitTextValue() {
      this.$emit('emitTextValue', this.textValue, this.label);
    },
  },
  mounted() {
    this.value ? (this.textValue = this.value) : ''; // eslint-disable-line
    this.emitTextValue();
  },
  watch: {
    value(val) {
      this.textValue = val;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
</style>
