<template>
  <div class="google-map-container">
    <gmap-map :center="center" :options="mapOptions" :zoom="15">
      <gmap-marker
        :key="index"
        v-for="(marker, index) in markers"
        :position="marker.position"
        :icon="marker.selectedMarker ? markerIcon : markerBlack"
        @click="!marker.selectedMarker ? emitMarkerClicked() : ''"
      >
        <gmap-info-window v-if="marker.markerInfo" :options="infoWindowOption">
          <div class="window" :class="{'selected-marker' : marker.selectedMarker}">
            <div class="info-window">${{marker.markerInfo}}</div>
            <div class="info-window-arrow"></div>
          </div>
        </gmap-info-window>
      </gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
import * as lodash from 'lodash';
import markerIcon from '@/assets/marker-icon.svg';
import markerBlack from '@/assets/marker-black.svg';
import { MAP_STYLES } from '@/constants';

export default {
  name: 'GoogleMap',
  data() {
    return {
      center: { lat: -33.87143, lng: 151.22840999999994 },
      places: [],
      currentPlace: null,
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        zoomControlOptions: {
          position: 1,
        },
        styles: MAP_STYLES,
      },
      infoWindowOption: {
        pixelOffset: {
          height: 10,
        },
      },
      markerBlack,
      markerIcon,
    };
  },
  props: ['markers'],
  mounted() {
    this.setCenter();
  },

  methods: {
    setCenter() {
      const index = lodash.findIndex(this.markers, ['selectedMarker', true]);
      this.center = this.markers[index].position;
    },
    emitMarkerClicked() {
      this.$emit('emitMarkerClicked');
    },
  },
};
</script>

<style lang="scss" scoped>
.google-map-container {
  height: 100%;
  .vue-map-container {
    width: 100%;
    height: 100%;
  }
  ::v-deep .gm-style-iw button {
    display: none !important;
  }
  ::v-deep .gm-style-pbc {
    opacity: 0 !important;
  }
  ::v-deep .gm-style .gm-style-iw-t::after {
    display: none;
  }
  ::v-deep .gm-style .gm-style-iw-c {
    padding: unset !important;
    background: transparent;
    box-shadow: unset;
    border-radius: unset;
  }
  .window {
    position: relative;
    height: 50px;
    width: 100px;
    text-align: center;
  }
  .info-window {
    background: black;
    color: white;
    font-weight: bold;
    font-size: 16px;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
  }
  .info-window-arrow {
    background: black;
    box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, 0.4);
    content: "";
    height: 8px;
    left: 50%;
    position: absolute;
    top: 80%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 8px;
  }
  .selected-marker {
    .info-window {
      background: $primary-color;
    }
    .info-window-arrow {
      background: $primary-color;
    }
  }
  ::v-deep .gm-style .gm-style-iw-d {
    overflow: hidden !important;
  }
}
</style>
