<template>
 <div class="button-group">
      <!-- <div class="facebook pt-2">
      <Button
            type="outlined"
            value="Continue with Facebook"
            buttonClass="outlined-button"
            color="#080914"
            height="40px"
            width="100%"
          />
      </div> -->
      <!-- <div class="google pt-2 pb-2">
      <Button
            type="outlined"
            :value="title"
            icon="mdi-google"
            iconColor="red"
            buttonClass="outlined-button"
            color="#080914"
            height="40px"
            width="100%"
          />
      </div> -->
      </div>
</template>

<script>
// import Button from './Button.vue';

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  components: {
    // Button,
  },
};
</script>

<style>

</style>
