<template>
  <div class="main-container" v-bind:style= "[!isLoggedIn && !isMobile && { 'margin-top': '150px'}]">
    <div class="filter-view">
    <FilterHeader @onSearchClicked="onSearchClicked" />
    </div>
    <div :class="{ 'search-hotel-top-margin': isLoggedIn }"
                 class="search-hotel-container" >
    <div class="how-it-work-container">
      <SortPanel :isLoggedIn="isLoggedIn"
                :selectDate="this.filters.date"
                @emitReset="clearFilter"
                @emitSelectedFilters="handelModalFilter"
                />
      <div class="toggle-btn">
        <v-flex d-flex >
          <div class="maplist" @click="openFilter"><div class="text-map-filter">Filter</div><v-icon color="#ff4500" size="30"
                class="mr-2">
                mdi-filter
        </v-icon></div>
        <div class="lineDraw"/>
          <div class="maplist"><v-icon color="#ff4500" size="30" @click="openMap" class="mr-2" v-if="!this.mapShowStatus">
            mdi-map-search-outline
        </v-icon><v-icon color="#ff4500" size="30" @click="openMap" class="mr-2" v-else>
          mdi-format-list-bulleted
        </v-icon><div class="text-map-filter">{{this.mapShowStatus ? 'List': 'Map'}}</div></div>
        </v-flex>
      </div>
    </div>
      <div>
      <v-flex d-flex
              class="hotels-header"
              v-if="filters.search?.type === 'Current Location' && !hotels.length">
        <div class="hotel-location">
          <span class="capitalize">{{ microStay }}</span> Hotels in Sydney
        </div>
        <div class="results" v-if="!this.manageElement" >{{ defaultHotels.length }} results</div>
      </v-flex>
            <v-flex v-else
              d-flex
              class="hotels-header">
        <div class="hotel-location">
          <div style="margin-left: 20px; margin-right: 20px;">
          <span class="capitalize">{{ microStay }}</span> Hotels in {{ filters?.search?.name }}
        </div>
        </div>
        <div class="results" >{{ hotels.length }} results</div>
      </v-flex>
    </div>
    <v-row no-gutters >
      <v-col cols="3" class="filterCard">
        <div class="hotel-maplist">
                <v-img
                  src="@/assets/map.png"
                  v-if="!this.mapShowStatus"
                  style="border-radius: 10px; height: 150px"
                >
                <div class="map-button" @click="openMap">
                  Show on map
                </div>
              </v-img>
              <v-img src="@/assets/list_view.png" style="border-radius: 10px; height: 150px" v-else>
                <div class="map-button" @click="openMap">
                  Show list view
                </div>
              </v-img>
      </div>
      <v-card class="filter-container">
        <v-card-title class="filter-text-container">
          <span class="filter-text">Select Filters</span>
          <div class="filter-text-reset" @click="clearFilter"><a>Reset</a></div>
        </v-card-title>
           <div class="filter-line"/>
        <v-card-text style="height: 190px; border-bottom: 1px solid #808080;">
      <p class="heading">
      Purpose
      </p>
         <v-container fluid>
           <v-list v-for="purpose in purposes" :key="purpose.return">
    <v-checkbox
      v-model="selectedPurposes"
              :label="purpose.display"
              :value="purpose.display"
              class="custom-checkbox"
              style="margin-top: -15px; padding: 0;"
    ></v-checkbox>
          </v-list>
            </v-container>

        </v-card-text>
        <!-- <div class="filter-line"/> -->
  <v-card-text style="height: 190px; border-bottom: 1px solid #808080;" >
          <p class="heading"> Duration</p>
         <v-container fluid >
  <v-list v-for="duration in durations" :key="duration.return">
    <v-checkbox
      v-model="selectedDuration"
              :label="duration.display"
              :value="duration.time"
              class="custom-checkbox"
              style="margin-top: -15px; padding: 0;"
    ></v-checkbox>
          </v-list>
            </v-container>
        </v-card-text>
        <!-- <div class="filter-line"/> -->
        <v-card-text style="height: 233px; border-bottom: 1px solid #808080;" >
          <p class="heading">Star Rating</p>
  <v-container fluid >
     <v-list v-for="starRating in starRatings" :key="starRating.return">
    <v-checkbox
      v-model="selectedStarRating"
              :value="starRating.rate"
              class="custom-checkbox"
              style="margin-top: -15px; padding: 0;"
    ><template v-slot:label >
        <v-icon  color="#FDCC0D" v-for="n in starRating.return"
        :key="n">mdi-star</v-icon>
      </template></v-checkbox>
          </v-list>
  </v-container>
        </v-card-text>
        <!-- <div class="filter-line"/> -->
          <v-card-text v-bind:style= "[selectedCheckInTime === 'Choose Time' ? {'height': '200px', 'border-bottom': '1px solid #808080'} : {'height': '130px', 'border-bottom': '1px solid #808080'}]" >
          <p class="heading" style="margin-bottom: 2px;">Check-in time</p>
         <v-container fluid >
            <v-radio-group
            v-model="selectedCheckInTime"
            column
          >
            <v-radio
              label="Anytime"
              value="Anytime"
              class="custom-checkbox"
              style="margin: 0; padding: 0; margin-bottom: 10px;"
            ></v-radio>
            <v-radio
              label="Choose Time"
              value="Choose Time"
              class="custom-checkbox"
              style="margin: 0; padding: 0;"
            ></v-radio>
    </v-radio-group>
    <div v-if="selectedCheckInTime === 'Choose Time'">
    <TimeSelector :date="this.filters.date" v-model="timeSelected" @onChange="getTime" />
    </div>
            </v-container>
        </v-card-text>
        <!-- <div class="filter-line"/> -->
         <v-card-text style="height: 270px; border-bottom: 1px solid #808080;">
        <p class="heading" style="margin-bottom: 23px;">Sort</p>
        <v-radio-group
            v-model="dialogm"
            column
            v-for="sort in sortLists"
            :key="sort.text"
          >
            <v-radio
              :label="sort.text"
              :value="sort.value"
               style="margin-top: -10px; padding: 0; margin-left: 10px"
            ></v-radio>
            </v-radio-group>
        </v-card-text>
        <!-- <div class="filter-line"/> -->
        <v-card-actions  class="apply-btn-container" >
          <v-btn
            color="#ff4500"
            size="x-large"
            variant="text"
            class="apply-btn"
            @click="handelFilter"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-col>
      <v-col>
      <!-- <v-row class="loader"
             v-if="loadingInProgress"
             align="center"
             justify="center">
        <v-progress-circular indeterminate
      color="primary"></v-progress-circular>
      </v-row> -->
      <div ref="hotelCardsRef">
        <div v-if="filters.search?.type === 'Current Location' && !hotels.length">
          <HotelCards v-if="!hotels.length"
                      :hotels='defaultHotels' :viewMap="this.mapView" :searchValue="this.filters" :currency="getCurrencyData"/>
        </div>
        <div v-else>
          <div v-if="hotelLoading"
               class="no-search-results" >
               <v-progress-circular
      :size="70"
      :width="7"
      color="#ff4500"
      indeterminate
    ></v-progress-circular>
          </div>
          <div v-else>
          <HotelCards v-if="hotels.length" :hotels="hotels" :viewMap="this.mapView" :searchValue="this.filters" :currency="getCurrencyData"/>
          <div v-else class="no-hotel-found">
            <v-img class="No-hotel-img" src="@/assets/No-hotel-found.png" />
            <div class="No-hotel-txt-1">Oh no!</div>
            <div class="No-hotel-txt-2">It looks like we don't have listings in that city right now.</div>
            <div class="No-hotel-txt-3">Consider checking out nearby destinations or drop us a suggestion, and we'll work on expanding our offerings.</div>
            <a class="No-hotel-txt-4">info@TimeFlyz.co</a>
            </div>
          </div>
        </div>
      </div>
      </v-col>
    </v-row>

    </div>
    <v-overlay :opacity="0.8"
               :value="loadingInProgress">
      <v-progress-circular indeterminate
                           size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar
      v-model="snackbar"
      bottom right
      :timeout="timeout"
    >
      {{ searchHotelsError }}
    </v-snackbar>
    <div v-if="openDialog">
      <v-dialog v-model="openDialog"
                max-width="370px">
        <Dialog :dialogData="searchHotelsError"
                :onCloseDialog="closeDialog"
                mode="snackbar-error" />
      </v-dialog>
    </div>
  </div>
</template>searchHotelscreated()

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import moment from 'moment';
import Lodash from 'lodash';
import { SNACKBAR_TIMEOUT, TOASTER_MESSAGE, SORT_LISTS } from '@/constants';
import FilterHeader from '@/components/SearchHotels/FilterHeader.vue';
import HotelCards from '@/components/SearchHotels/HotelCards.vue';
import SortPanel from '@/components/SearchHotels/SortPanel.vue';
import Dialog from '@/common/ErrorDialog.vue';
import TimeSelector from '@/common/TimeSelector.vue';
import Vue from 'vue';
import VueCookies from 'vue-cookies';

Vue.use(VueCookies);
const { getLoggedInUserDetailService } = require('@/services/user/user.service');
const { getCurrencies } = require('@/services/user/user.service');

const {
  getLocationLatLng,
  getHomepageDetails,
} = require('@/services/homepage/homepage.service');

export default {
  name: 'SearchHotels',
  data() {
    return {
      hotels: [],
      dialogm: '',
      defaultHotels: [],
      seachBarSection: [],
      filters: {
        query: undefined,
        search: {
          value: undefined,
          name: undefined,
          type: undefined,
        },
        date: undefined,
        time: null,
        rating: '',
        sort: null,
        duration: '3,6,12',
        types: '',
      },
      loadingInProgress: true,
      snackbar: false,
      openDialog: false,
      timeout: SNACKBAR_TIMEOUT,
      isLoggedIn: false,
      microStay: '',
      selectedSort: null,
      mapView: false,
      manageElement: false,
      dateSelected: this.getCurrentDate(),
      timeSelected: undefined,
      purposes: [{ return: 1, display: 'Business' }, { return: 2, display: 'Leisure' }, { return: 3, display: 'Transit' }],
      selectedPurposes: [],
      durations: [{ return: 3, display: '3 Hours', time: '3' }, { return: 6, display: '6 Hours', time: '6' }, { return: 12, display: '12 Hours', time: '12' }],
      selectedDuration: [],
      starRatings: [{ return: 5, rate: '5' }, { return: 4, rate: '4' }, { return: 3, rate: '3' }, { return: 2, rate: '2' }],
      selectedStarRating: [],
      selectedCheckInTime: '',
      sortLists: SORT_LISTS,
      dateTime: { searchDate: '', checkInTime: null },
      hotelLoading: false,
      isMobile: false,
    };
  },
  components: {
    HotelCards,
    SortPanel,
    FilterHeader,
    TimeSelector,
    Dialog,
  },
  mounted() {
    const { query } = this.$route;
    this.filters = query;
    this.checkAccess();
    this.getBannerContent();
    this.searchHotels();
    this.setHeaderAccess();
    // this.handleElement();
  },

  computed: {
    ...mapState({
      searchHotelsError: state => state.hotels.searchError,
    }),
    ...mapGetters({
      getCurrencyData: 'getCurrencyData',
    }),
    ...mapGetters(['mapShowStatus']),
  },
  watch: {
    searchHotelsError() {
      if (this.searchHotelsError !== null) {
        this.snackbar = true;
        this.openDialog = true;
      }
      this.loadingInProgress = false;
    },
  },
  // created() {
  //   // Call the function when the component is created (route accessed)
  //   this.isMobile = window.innerWidth <= 768;
  // },
  methods: {
    ...mapActions(['searchHotelsByFilterOptions', 'resetSearchError', 'setMap', 'setCurrencyData']),
    async setHeaderAccess() {
      const isLoggedIn = this.$cookies.get('isLoggedIn');
      const token = this.$cookies.get('token');
      this.isMobile = window.innerWidth <= 768;
      if ((isLoggedIn === 'false' && !token) || isLoggedIn === null) {
        const getVatgstDetails = await getCurrencies();
        if (!Vue.$cookies.get('localeInfo') || Vue.$cookies.get('localeInfo').countryNameVatgst === '') {
          const filterCountry = getVatgstDetails.data.filter(detail => detail.countryname === 'Australia');
          this.setCurrencyData(filterCountry[0]);
        }
        if (Vue.$cookies.get('localeInfo') && Vue.$cookies.get('localeInfo').countryNameVatgst !== '') {
          const filterCountry = getVatgstDetails.data.filter(detail => detail.countryname === Vue.$cookies.get('localeInfo').countryNameVatgst);
          this.setCurrencyData(filterCountry[0]);
        }
      } else {
        const userDetails = await getLoggedInUserDetailService();
        this.setCurrencyData(userDetails.data.vatgstDetails[0]);
      }
    },
    async getBannerContent() {
      this.loadingInProgress = true;
      try {
        const homepageContentDetails = await getHomepageDetails('SEARCH BAR SECTION');
        if (homepageContentDetails.status === 200) {
          this.seachBarSection = {
            title: homepageContentDetails.data[0].title,
            imageArray: homepageContentDetails.data[0].photos,
          };
          this.loadingInProgress = false;
        } else {
          throw new Error();
        }
      } catch (err) {
        this.snackbar = true;
        this.openDialog = true;
        this.toasterMessage = TOASTER_MESSAGE.TROUBLE;
        this.loadingInProgress = false;
      }
    },
    getCurrentDate() {
      // Add 30 minutes to current time and update time to nearest 30 minute interval
      const currentDate = new Date();
      const updatedDate = new Date(currentDate.getTime() + 30 * 60000);
      const remainder = 30 - (moment(updatedDate).minute() % 30);
      return new Date(moment(updatedDate).add(remainder, 'minutes'));
    },
    getTime(time) {
      this.timeSelected = moment(time, 'h:mm a').format('HH:mm');
    },
    closeDialog() {
      this.snackbar = false;
      this.openDialog = false;
      this.resetSearchError();
    },

    handelModalFilter([types, time, rate, pick, check, checkTime]) {
      const { query } = this.$route;
      this.hotels = [];
      this.filters.sort = pick === '' ? null : pick;
      this.filters.duration = time;
      this.filters.rating = rate;
      this.filters.types = types;
      this.filters.time = check === 'Anytime' ? null : checkTime;
      this.filters = _.merge(this.filters, query);
      this.searchHotels();
    },

    handelFilter() {
      const { query } = this.$route;
      this.hotels = [];
      this.filters.sort = this.dialogm === '' ? null : this.dialogm;
      this.filters.duration = this.selectedDuration.join();
      this.filters.rating = this.selectedStarRating.join();
      this.filters.types = this.selectedPurposes.join();
      this.filters.time = this.selectedCheckInTime === 'Anytime' ? null : this.timeSelected;
      this.filters = _.merge(this.filters, query);
      this.searchHotels();
    },
    clearFilter() {
      this.hotels = [];
      this.selectedPurposes = [];
      this.selectedDuration = [];
      this.selectedStarRating = [];
      this.dialogm = '';
      this.selectedCheckInTime = '';
      this.filters.sort = null;
      this.filters.time = null;
      this.filters.duration = '3,6,12';
      this.filters.rating = '';
      this.filters.types = '';
      this.searchHotels();
    },
    sortHotelLists() {
      switch (this.selectedSort) {
        case 'PHTL':
          this.hotels = Lodash.orderBy(this.hotels, ['price'], ['desc']);
          this.defaultHotels = Lodash.orderBy(this.defaultHotels, ['price'], ['desc']);
          break;
        case 'PLTH':
          this.hotels = Lodash.orderBy(this.hotels, ['price'], ['asc']);
          this.defaultHotels = Lodash.orderBy(this.defaultHotels, ['price'], ['asc']);
          break;
        case 'RHTL':
          this.hotels = Lodash.orderBy(this.hotels, ['rating'], ['desc']);
          this.defaultHotels = Lodash.orderBy(this.defaultHotels, ['rating'], ['desc']);
          break;
        case 'RLTH':
          this.hotels = Lodash.orderBy(this.hotels, ['rating'], ['asc']);
          this.defaultHotels = Lodash.orderBy(this.defaultHotels, ['rating'], ['asc']);
          break;
        case 'HASC':
          this.hotels = Lodash.orderBy(this.hotels, ['name'], ['asc']);
          this.defaultHotels = Lodash.orderBy(this.defaultHotels, ['name'], ['asc']);
          break;
        case 'HDESC':
          this.hotels = Lodash.orderBy(this.hotels, ['name'], ['desc']);
          this.defaultHotels = Lodash.orderBy(this.defaultHotels, ['name'], ['desc']);
          break;
        default: break;
      }
    },
    // Search hotel based on micro stay
    setSelectedMicroStay(value) {
      this.microStay = value;
      this.searchHotels();
    },
    checkAccess() {
      const isLoggedIn = this.$cookies.get('isLoggedIn');
      const token = this.$cookies.get('token');
      if ((isLoggedIn === 'false' && !token) || isLoggedIn === null) {
        this.isLoggedIn = false;
      } else {
        this.isLoggedIn = true;
      }
    },

    changeString(string) {
      // Replace double quotes around keys with single quotes
      const stringWithSingleQuotes = string.replace(/(\w+):/g, '"$1":');

      // Replace escaped double quotes around values with double quotes
      const formattedString = stringWithSingleQuotes.replace(/\\"/g, '"');

      return formattedString;
    },

    async searchHotels() {
      // this.handleElement();
      this.hotelLoading = true;
      let lon; let lat; let name;
      if (Lodash.isEmpty(this.filters)) {
        return;
      }

      if (this.filters.search?.type === 'Current Location') {
        [lat, lon] = this.filters.search.value;
      } else if (this.filters?.search?.type === 'Location') {
        const getLatLng = await getLocationLatLng(
          this.filters?.search?.value,
        );
        lat = Lodash.get(getLatLng, 'data.result.geometry.location.lat');
        lon = Lodash.get(getLatLng, 'data.result.geometry.location.lng');
      } else {
        // eslint-disable-next-line prefer-destructuring
        name = this.filters?.search?.name;
      }
      this.dateTime.checkInTime = this.filters.time === '' ? null : this.filters.time;
      this.dateTime.searchDate = moment(this.filters.date).format('YYYY-MM-DD');
      // TODO add validation for date and time
      const { data } = await this.$api.post('mobile/hotel/search',
        {
          lon,
          lat,
          date: moment(this.filters.date).format('YYYY-MM-DD'),
          time: this.filters.time === '' || this.filters.time === undefined ? null : this.filters.time,
          duration: this.filters.duration === '' ? '3,6,12' : this.filters.duration,
          types: this.filters.types,
          name,
          rating: this.filters.rating,
          sort: this.filters.sort === '' ? null : this.filters.sort,
        });
      this.hotels = data.map(hotel => ({
        ...hotel,
        price: Lodash.minBy(hotel.rooms, 'threeHourRate')?.threeHourRate,
        rating: hotel.rating || 0,
      }));
      // if (data.length !== 0) {
      //   this.loadingInProgress = false;
      // } else {
      //   this.loadingInProgress = false;
      // }
      setTimeout(() => {
        this.hotelLoading = false;
      }, 2000);
      this.defaultHotels = [];
      if (this.filters.search?.type === 'Current Location' && this.hotels?.length === 0) {
        [lat, lon] = ['-33.8688', '151.2093'];
        const { data: defaultData } = await this.$api.post('mobile/hotel/search',
          {
            lon,
            lat,
            date: moment(this.filters.date).format('YYYY-MM-DD'),
            time: this.filters.time === '' ? null : this.filters.time,
            duration: this.filters.duration,
            rating: this.filters.rating,
            types: this.filters.types,
            sort: this.filters.sort === '' ? null : this.filters.sort,
            name,
          });
        this.defaultHotels = defaultData.map(hotel => ({
          ...hotel,
          price: Lodash.minBy(hotel.rooms, 'threeHourRate')?.threeHourRate,
          rating: hotel.rating || 0,
        }));
      }
      // this.sortHotelLists();
      // this.loadingInProgress = false;
    },
    onSearchClicked(filters) {
      this.filters = filters;
      this.searchHotels();
      this.$router.push({
        name: 'hotels',
        query: filters,
      });
    },
    goto(refName) {
      const element = this.$refs[refName];
      const top = element.offsetTop;
      window.scrollTo(0, top);
    },
    resetError() {
      this.snackbar = false;
      this.resetSearchError();
    },
    openMap() {
      this.setMap();
    },

    handleElement() {
      window.addEventListener('resize', (event) => {
        if (window.innerWidth < 768) {
        // disable scrolling or do whatever you want to do
          this.manageElement = true;
        } else {
          this.manageElement = false;
        }
      });
    },
    openFilter() {
      // this.$refs.handleFilter.handleOpenFilter();
      this.$emit('filter-event');
    },
  },
};
</script>

<style lang="scss" scoped>

.main-container{
  align-items: center;
  margin: 6% auto 0px;
}
.filter-header {
  position: fixed;
  top: 56px;
  left: 0;
  right: 0;
  z-index: 5;
}

// .v-card__text {
//   display: flex !important;
//   flex-direction: column;
// }

.custom-checkbox .v-input {
  margin: 0;
  padding: 0;
}

.custom-checkbox {
  font-size: 18px;
}

.filterCard{
  margin-right: -25px;
  margin-top: 5px;
 // background: #ff4500;
  border-radius: 5px;
  box-shadow: #ddd;
  .hotel-maplist {
      display: flex;
      align-items: center;
      cursor: pointer;
      object-fit: contain;
      width: 80%;
      height: 160px;
      margin-bottom: 10px;
      justify-content: center;
      margin-left: 20px;
    }
      .text-map-filter{
        font-size: 22px;
        color:#ff4500;
    }

  .filter-container{
    width: 80%;
    // height: 1220px;
    margin-left: 20px;
  }

  .v-card {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    //padding-top: 20px;
    padding-bottom: 20px;
    // flex-grow: 1;
    // overflow: auto;
    // display: flex !important;
    // flex-direction: column;
  }
}

.map-view{
  width: 220px;
  height: 150px;
  margin-left: 20px;
  margin-top: 8px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.map-button {
  font-size: 17px;
  width: 120px;
  color: #f0f0f0;
  text-align: center;
  background: #ff4500;
  padding: 8px;
  border-radius: 20px;
  position: absolute;
  /* top: 76px; */
  right: 58px;
  bottom: 28px;
  font-weight: bold;
  cursor: pointer;
  }


.search-hotel-container {

  .hotels-header {
    margin-bottom: 20px;
    justify-content: space-between;

    .hotel-location {
      font-size: 35px;
      line-height: 32px;
      color: #080914;
      font-weight: bold;
      display: flex;
    }

    .results {
      color: rgba(8, 9, 20, 0.7);
      font-size: 18px;
     // margin-right: 70px;
    }
  }
  .toggle-btn {
    display: none;
  }

  .search-results-outer-container {
    margin-right: -25px;
  }

  .search-results {
    flex-wrap: wrap;

    .hotel-card-outer {
      margin: 20px 20px 0 0;
      cursor: pointer;
    }
  }

  .capitalize {
    text-transform: capitalize;
    // margin-left: 20px;
  }
}

.apply-btn-container{
  justify-content: center;
  align-items: center;
.apply-btn {
  width: 90%;
  color: #ffffff;
  font-size: 20px;
}
}

.v-input__control .v-label {
  color: red;
}

.search-hotel-top-margin {
  margin-top: 0;
}

.how-it-work-container {
  margin-bottom: 2.9rem;
  display: none;
}

.no-search-results {
  margin-top: 150px;
  font-size: 18px;
  line-height: 24px;
  padding: 30px;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  min-height: 145px;
}

.no-hotel-found {
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
  .No-hotel-txt-1{
    font-size: 20px;
  color: #ff4500;
  font-weight: 700;
}
  .No-hotel-txt-2{
  font-size: 14px;
  color: #ff4500;
  font-weight: 700;
}
  .No-hotel-txt-3{
  font-size: 14px;
  color: #ff4500;
  width: 40%;
  margin-top: 10px;
  font-weight: 700;
  margin-left: 30%;
  margin-bottom: 50px;
}
.No-hotel-txt-4 {
  font-size: 12px;
  color: #ff4500;
  font-weight: 700;
}
}

.No-hotel-img {
  width: 150px;
  height: 150px;
  margin-left: 42%;
}

.footer-div {
  margin-top: 40px;
}

.loader {
  min-height: 145px;
  position:sticky;
}

.heading {
  font-size: 20px;
  font-weight: 700;
  color: #ff4500;
}

.v-application p {
    margin-bottom: 10px;
}

.v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
}

.filter-text-container {
  display: flex;
  justify-content: space-between;
  .filter-text {
  font-size: 24px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.8);
}

.filter-text-reset {
  font-size: 15px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
}
}

.filter-line {
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.4);
  margin-top: 5px;
}

  .filter-view {
    display: none;
  }

// responsiveness design
@media only screen and (max-width: 768px) {
  .main-container {
    width: 100%;
  }
  .filter-header {
    top: 113px;
  }

  .filter-view {
    display: block;
    //margin-left: 10px;
  }
.search-hotel-container {
  .hotels-header {
    .hotel-location {
    margin-left: -15px ;
      .hotel-maplist {
        display: none;
    }
  }
  .results {
    font-size: 12px;
    margin-right: 0;
    display: none;
  }
  }
}
.search-hotel-container .hotels-header .hotel-location {
  font-size: 28px;
}
span {
  font-size: 12px;
}
.filterCard{
  margin-left: 0;
  display: none;
}

.filter-container{
  display: none;
}

.how-it-work-container {
  display: block;
  .maplist {
      display: flex;
      align-items: center;
    }
  .text-map-filter{
    font-size: 25px;
    color:#ff4500
  }
  .lineDraw{
      width: 2px;
      height: 30px;
      margin: 0 10px;
      background-color: #ff4500;
    }
}

.main-container{
  margin: 0px;
}

.no-hotel-found .No-hotel-txt-3 {
  width: 80%;
  margin-left: 10%;
}

.No-hotel-img {
  width: 120px;
  height: 120px;
  margin-left: 34%;
}
  .toggle-btn {
    display: block !important;
    margin-left: 5rem;
  }

}
.responsive-image {
  width: 100%;
  height: 250px;
}

@media (max-width: 600px) {
  .responsive-image {
    width: 100%;
    height: 250px;
  }
}

@media (min-width: 601px) and (max-width: 768px) {
  .responsive-image {
    width: 75%;
    height: 250px;
  }
}

@media (min-width: 769px) {
  .responsive-image {
    width: 95%;
    height: 250px;
    margin-left: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
  }
}

</style>
