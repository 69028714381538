<template>
  <div>
    <span class="label-header" v-if="label">{{label}}</span>
    <v-text-field
      :autofocus="autofocus"
      autocomplete="off"
      v-model="textValue"
      :id="id"
      @input="emitTextValue()"
      :placeholder="placeholder"
      :prepend-inner-icon="prependInnerIcon"
      :append-icon="appendIcon"
      :type="type"
      single-line
      outlined
      :error="error"
      :error-messages="errorMessages"
      :rules="rules"
      :hint="hint"
      :persistent-hint="persistentHint"
      :prefix="prefix"
      :required="required"
      :class="textFiledClass"
      :disabled="disabled"
      @blur="onBlur()"
      @click:prepend-inner="prependClick()"
      @click:append="appendClick()"
      @keypress="type === 'number' ? isNumber($event) : null"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: 'TextField',
  data() {
    return {
      textValue: '',
    };
  },
  props: [
    'label',
    'placeholder',
    'prependInnerIcon',
    'appendIcon',
    'type',
    'value',
    'error',
    'errorMessages',
    'rules',
    'hint',
    'persistentHint',
    'prefix',
    'appendClickType',
    'prependClickType',
    'required',
    'textFiledClass',
    'id',
    'disabled',
    'autofocus',
  ],
  mounted() {
    if (this.value) {
      this.textValue = this.value;
    }
    this.emitTextValue();
  },
  watch: {
    value(val) {
      this.textValue = val;
    },
  },
  methods: {
    emitTextValue() {
      this.$emit('emitTextValue', this.textValue, this.label);
    },
    appendClick() {
      switch (this.appendClickType) {
        case 'increment':
          this.textValue = this.textValue + 1;
          this.emitTextValue();
          break;
        default:
          break;
      }
    },
    prependClick() {
      switch (this.prependClickType) {
        case 'decrement':
          this.textValue = this.textValue - 1;
          this.emitTextValue();
          break;
        default:
          break;
      }
    },
    onBlur() {
      this.$emit('emitOnBlur');
    },
    // eslint-disable-next-line consistent-return
    isNumber($event) {
      if ($event.charCode === 0 || /\d/.test(String.fromCharCode($event.charCode))) {
        return true;
      }
      $event.preventDefault();
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
.label-header {
  font-size: 16px;
}
</style>
