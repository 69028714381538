<template>
  <div class="footer-container">
    <div class="background-image-container">
      <v-parallax class="parallx-image" height="500" src="../assets/footer.jpg">
      </v-parallax>
      <v-parallax class="parallx-image-mobile" height="720" src="../assets/footer.jpg">
      </v-parallax>
    </div>
    <div class="background-gradient"></div>
    <div class="footer-wrapper">
      <div class="main-links-container">
        <div class="links-column logo-container">
          <div>
            <div class="logo-wrapper">
              <img
                width="695"
                height="185"
                src="../assets/TimeFlyz-Info-e1682873070758.png"
                class="attachment-full size-full wp-image-505"
                alt=""
                loading="lazy"
                srcset="
                  ../assets/TimeFlyz-Info-e1682873070758.png        695w,
                  ../assets/TimeFlyz-Info-e1682873070758-300x80.png 300w
                "
                sizes="(max-width: 695px) 100vw, 695px"
              />
            </div>
            <p>Make the most of every hour</p>
          </div>
        </div>
        <div class="links-column">
          <h4 class="link-heading">Are you a hotelier?</h4>
          <ul class="link-list">
            <li>
              <a href="https://timeflyz.co/list-your-hotel/">Join TimeFlyz</a>
            </li>
          </ul>
        </div>
        <div class="links-column">
          <h4 class="link-heading">Get in touch</h4>
          <ul class="link-list">
            <li>
              <a href="https://timeflyz.co/contact-us/">Contact Us</a>
            </li>
            <li>
              <a href="https://timeflyz.co/faq/">FAQ</a>
            </li>
          </ul>
          <div class="social-items">
            <a href="https://www.instagram.com/timeflyzco/">
              <font-awesome-icon :icon="['fab', 'instagram']" />
            </a>
            <a href="https://www.facebook.com/timeflyzco">
              <font-awesome-icon :icon="['fab', 'facebook-f']" />
            </a>
            <a href="https://www.linkedin.com/company/timeflyz/">
              <font-awesome-icon :icon="['fab', 'linkedin']" />
            </a>
          </div>
        </div>
      </div>
      <div class="copy-rights-container">
        <div class="text-container">Copyright © 2024 TimeFlyz, All rights reserved.</div>
        <div class="text-container">
          <ul>
            <li>
              <a href="https://timeflyz.co/terms-of-use/">
                <span>
                  <font-awesome-icon :icon="['fas', 'circle']" />
                </span>
                Terms of use
              </a>
            </li>
            <li>
              <a href="https://timeflyz.co/privacy-policy/">
                <span>
                  <font-awesome-icon :icon="['fas', 'circle']" />
                </span>
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeFooter',
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    getParallaxHeight();
  },
  methods: {
    getParallaxHeight() {
      this.isMobile = window.innerWidth <= 768;
      return window.innerWidth <= 768;
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-container {
  display: flex;
  justify-content: center;
  position: relative;
  color: white;

  .background-image-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    .parallx-image{
      width: 100%;
      @media (max-width: 1024px) {
        display: block;
      }
      @media (max-width: 767px) {
        display: none;
      }
    }
    .parallx-image-mobile{
      width: 100%;
      @media (max-width: 1024px) {
        display: none;
      }
      @media (max-width: 767px) {
        display: block;
      }
    }
    .background-image {
      width: 100%;
      height: 170%;
     // background-image: url("../assets/footer.jpg");
      background-repeat: no-repeat;
      background-position: bottom right;
      background-repeat: no-repeat;
      background-size: cover;
      transform: translateY(-70px);

      @media (max-width: 1024px) {
        display: none;
        // background-position: center left;
        // transform: translateY(-130px);
      }
      @media (max-width: 767px) {
        display: block;
        // background-position: -238px 0px;
        // transform: translateY(-162.89391px);
      }
    }
  }

  .background-gradient {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: transparent;
    background-image: linear-gradient(21deg, #15181b00 0%, #010507 100%);
    z-index: 1;
  }

  .footer-wrapper {
    max-width: 1200px;
    padding: 10px;
    z-index: 2;

    @media (max-width: 1024px) {
      padding: 3em 1em 0em 1em;
    }
    @media (max-width: 767px) {
      padding: 2em 1em 0em 1em;
    }

    .main-links-container {
      padding: 0em 0em 2em 0em;
      border-bottom: solid 1px #ffffff2b;
      display: flex;
      flex-wrap: wrap;

      .links-column {
        width: 33.3333%;
        padding: 2em 2em 2em 2em;

        @media (max-width: 767px) {
          width: 100%;
          padding: 1em 1em 1em 1em;
        }

        @media (max-width: 1024px) {
          &.logo-container {
            width: 100%;
          }
        }
        @media (min-width: 1025px) {
          &.logo-container > div {
            padding-right: 25%;
          }
        }

        .logo-wrapper {
          margin-bottom: 20px;
          img {
            max-width: 100%;
            height: auto;
          }
        }
        p {
          margin-bottom: 0.9rem;
        }
      }
    }

    .copy-rights-container {
      padding: 1em 0em;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .text-container {
        padding: 10px;
        text-align: center;
        font-size: 13px;
        font-family: Heebo, sans-serif;
        line-height: 21px;
        list-style: none;
        @media (max-width: 1024px) {
          width: 100%;
          font-size: 11px;
        }

        ul {
          list-style: none;
          padding: 0px;
          display: flex;
          justify-content: center;
          @media (max-width: 1024px) {
            width: 100%;
          }
          li {
            margin: 0 7px;
            a {
              color: white;
              text-decoration: none;
              display: flex;
              align-items: center;
              span {
                color: #fe4500;
                font-size: 6px;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
}
.link-heading {
  font-size: 18px;
  line-height: 1.3em;
  font-family: Inter, sans-serif;
  color: #fe4500;
  margin-bottom: 20px;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.66);
}
.link-list {
  list-style: none;
  padding: 0px;
  li {
    a {
      color: white;
      text-decoration: none;
      font-size: 14px;
      line-height: 21px;
      font-family: Heebo, sans-serif;
    }
  }
}
.social-items {
  display: flex;
  margin-top: 20px;
  a {
    display: flex;
    width: 32px;
    height: 32px;
    background-color: #ffffff2b;
    border-radius: 50%;
    margin-right: 5px;
    color: white;
    justify-content: center;
    align-items: center;
  }
}
</style>
