<template>
  <img class="avatar" src="../assets/logo.png" />
</template>

<script>
export default {
  name: 'Avatar',
};
</script>

<style lang="scss" scoped>
.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
</style>
