var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-app',{staticClass:"web-container",class:{'container' : this.$route.name !== 'home' &&
    this.$route.name !== 'login' &&
    this.$route.name !== 'signup' &&
    this.$route.name !== 'TermsAndConditions' &&
    this.$route.name !== 'PrivacyPolicy' &&
    this.$route.name !== 'Faq' &&
    this.$route.name !== 'About' &&
    this.$route.name !== 'PressRoom' &&
    this.$route.name !== 'ContactUs'}},[_c('Header'),_c('v-main',{staticClass:"app-outer-container",style:([_vm.isAppMobile ? { padding: '0px'}: _vm.isToken?{padding: '90px 0px 0px'}: { padding: '0px'}])},[_c('router-view')],1),(_vm.$route.meta.subheader === 'number-tabs')?_c('div',{staticClass:"subheader-wrapper"},[_c('NumberTabs',{attrs:{"headerDetails":_vm.tabs}})],1):_vm._e(),(_vm.viewFooter)?_c('div',[_c('Footer')],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }