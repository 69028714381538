import { EMAIL_PATTERN } from '@/constants';
import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();
export const validateMobile = (mobileString) => {
  try {
    return phoneUtil.isValidNumber(
      phoneUtil.parse(mobileString),
    );
  } catch (error) {
    return false;
  }
};

const validation = {
  FORM_VALIDATION: {
    firstname: v => /^[a-z ,.'-]+$/i.test(v) || 'Please enter the valid first name',
    lastname: v => /^[a-z ,.'-]+$/i.test(v) || 'Please enter the valid last name',
    password: v => /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(v) || 'The password must be of minimum 8 characters length,\r\nshould contain at least one uppercase & a special character',
    required: v => !!v || '*Required',
    email: v => EMAIL_PATTERN.test(v) || 'Please enter the valid email address',
    mobile: v => !v || validateMobile(v) || 'Please enter the valid mobile number',
  },
};
export default validation;
