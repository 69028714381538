<template>
    <div>
        <Banner :searchTitle='seachBarSection?.title' :imageArray='seachBarSection.imageArray' />
        <FilterHeader/>
        <v-container class="container details-container">
            <div class="details-card" v-for="(benefits, i) in benefitsBanner[0]" :key="i">
                <v-img :src="benefits.image[0]"
                class="benefitsImageContainer"
                contain
                />
                <span class="details-heading">{{benefits?.title}}</span>
                <span class="details-label">
                {{benefits.description}}
                </span>
            </div>
        </v-container>
        <div class="micro-stay-container">
            <span class="details-main-heading">What's your reason for travel?</span>
            <v-container class="flex-container micro-stay-background">
                <div class="business"
                @click="emitSelectedMicroStay('Business')"
                v-for="(reaseonTravel, i) in reasonForTravel[0]" :key="i">
                    <div class="micro-stay-card overlay"
                    :class="{'card-selected' : microStay === 'Business'}">
                    <span class="details-heading text-color">{{reaseonTravel?.title}}</span>
                    <v-img width="365px" :src="reaseonTravel.image[0]" style="min-height:243px;" />
                    <span class="details-label-micro-stay text-color">
                        {{reaseonTravel.description}}
                    </span>
                    </div>
                </div>
            </v-container>
        </div>
        <div class="details-container-deals">
            <span class="details-heading-deals">Get the best deals</span>
            <span class="details-label-deals">
                Sign up and we'll send you the best deals straight to your inbox
            </span>
             <v-form ref="form" v-model="valid" class="details-sub-container"
               @submit.prevent="submitSubscription">
              <v-card-title primary-title class="justify-center">
              <div>
                  <TextField
                      id="emailAutocomplete"
                      label="EMAIL"
                      class="deals-email-text-box"
                      :value="email"
                      :rules="emailRules"
                      :required="true"
                      @emitTextValue="(value, label) => { this.email = value}"
                  />
              </div>
              </v-card-title>
              <v-card-actions class="justify-center" lg4 xl4 md12 sm12 xs12>
                  <Button
                      :disabled="!valid"
                      type="flat"
                      class="details-label-email-container"
                      value="Subscribe"
                      color="primary"
                      height="35px"
                      width="100%"
                      style=" text-transform: uppercase"
                  />
              </v-card-actions>
               <div v-if="openErrorDialog">
                  <v-dialog v-model="openErrorDialog" max-width="370px">
                    <ErrorDialog
                      :dialogData="toasterMessage"
                      :onCloseDialog="closeErrorDialog"
                      mode="snackbar-error"
                    />
                  </v-dialog>
                </div>
             </v-form>
        </div>
        <div class="details-container-deals">
            <span class="details-heading-deals">
                {{microStayArray[0]?.title}}
            </span>
            <v-container class="flex-container">
                <div class="p-10 business"
                v-for="(microImages, i) in microStayArray[0]?.image" :key="i">
                    <v-img width="220px" :src="microImages" />
                </div>
            </v-container>
        </div>
        <div class="details-container-deals">
            <span class="details-heading-deals">
                Book your hotel on the go with the TimeFlyz App
            </span>
            <v-container class="flex-container">
                <div class="p-10 business">
                    <a href="https://apps.apple.com/app/id1633408481" target="_blank"><v-img width="180px" src="@/assets/Appstore.png" /></a>
                </div>
                <div class="p-10 business">
                     <a href="https://play.google.com/store/apps/details?id=co.timeflyz" target="_blank"><v-img width="180px" src="@/assets/Playstore.png" /></a>
                </div>
            </v-container>
        </div>
        <Footer />
    </div>
</template>

<script>
import Banner from '@/components/SearchHotels/Banner.vue';
import Footer from '@/components/HomeFooter.vue';
import TextField from '@/common/TextField.vue';
import FilterHeader from '@/components/SearchHotels/FilterHeader.vue';
import ErrorDialog from '@/common/ErrorDialog.vue';
import {
  SNACKBAR_TIMEOUT,
  TOASTER_MESSAGE,
} from '@/constants';
import validation from '@/validations';
import addSubscription from '@/services/subscription/subscription.service';

const {
  getHomepageDetails,
} = require('@/services/homepage/homepage.service');

export default {
  name: 'Home',
  metaInfo() {
    return {
      title: 'Timeflyz - Homepage',
      meta: [
        { name: 'description', content: 'Timeflyz - Description' },
        { property: 'og:title', content: 'Timeflyz - Title' },
        { property: 'og:site_name', content: 'Timeflyz' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  data() {
    return {
      microStay: '',
      seachBarSection: [],
      benefitsBanner: [],
      reasonForTravel: [],
      microStayArray: [],
      timeout: SNACKBAR_TIMEOUT,
      snackbar: false,
      toasterMessage: '',
      loadingInProgress: false,
      emailRules: [
        validation.FORM_VALIDATION.required,
        validation.FORM_VALIDATION.email,
      ],
      email: '',
      valid: true,
      openErrorDialog: false,
    };
  },
  components: {
    Banner,
    Footer,
    TextField,
    FilterHeader,
    ErrorDialog,
  },
  created() {
    this.getHomepageContent();
  },
  methods: {
    async getHomepageContent() {
      this.loadingInProgress = true;
      try {
        const homepageContentDetails = await getHomepageDetails('SEARCH BAR SECTION');
        if (homepageContentDetails.status === 200) {
          this.seachBarSection = {
            title: homepageContentDetails.data[0].title,
            imageArray: homepageContentDetails.data[0].photos,
          };
          this.loadingInProgress = false;
        } else {
          throw new Error();
        }
      } catch (err) {
        this.snackbar = true;
        this.toasterMessage = TOASTER_MESSAGE.TROUBLE;
        this.loadingInProgress = false;
      }
      try {
        const homepageContentDetails = await getHomepageDetails('BENEFITS SECTION');
        if (homepageContentDetails.status === 200) {
          this.benefitsBanner = [{
            0: {
              image: homepageContentDetails.data[0].photos,
              title: homepageContentDetails.data[0].title,
              description: homepageContentDetails.data[0].description,
            },
            1: {
              image: homepageContentDetails.data[1].photos,
              title: homepageContentDetails.data[1].title,
              description: homepageContentDetails.data[1].description,
            },
            2: {
              image: homepageContentDetails.data[2].photos,
              title: homepageContentDetails.data[2].title,
              description: homepageContentDetails.data[2].description,
            },
          }];
          this.loadingInProgress = false;
        } else {
          throw new Error();
        }
      } catch (err) {
        this.snackbar = true;
        this.toasterMessage = TOASTER_MESSAGE.TROUBLE;
        this.loadingInProgress = false;
      }
      try {
        const homepageContentDetails = await getHomepageDetails('REASON FOR TRAVEL SECTION');
        if (homepageContentDetails.status === 200) {
          this.reasonForTravel = [{
            0: {
              image: homepageContentDetails.data[0].photos,
              title: homepageContentDetails.data[0].title,
              description: homepageContentDetails.data[0].description,
            },
            1: {
              image: homepageContentDetails.data[1].photos,
              title: homepageContentDetails.data[1].title,
              description: homepageContentDetails.data[1].description,
            },
            2: {
              image: homepageContentDetails.data[2].photos,
              title: homepageContentDetails.data[2].title,
              description: homepageContentDetails.data[2].description,
            },
          }];
          this.loadingInProgress = false;
        } else {
          throw new Error();
        }
      } catch (err) {
        this.snackbar = true;
        this.toasterMessage = TOASTER_MESSAGE.TROUBLE;
        this.loadingInProgress = false;
      }
      try {
        const homepageContentDetails = await getHomepageDetails('ENJOY HOTEL SECTION');
        if (homepageContentDetails.status === 200) {
          this.microStayArray = [{
            image: homepageContentDetails.data[0].photos,
            title: homepageContentDetails.data[0].title,
          }];
          this.loadingInProgress = false;
        } else {
          throw new Error();
        }
      } catch (err) {
        this.snackbar = true;
        this.toasterMessage = TOASTER_MESSAGE.TROUBLE;
        this.loadingInProgress = false;
      }
    },
    async submitSubscription() {
      await addSubscription(this.email);
      this.openErrorDialog = true;
      this.toasterMessage = 'Thank you! Your subscription has been confirmed';
    },
    closeErrorDialog() {
      this.email = '';
      this.valid = true;
      this.toasterMessage = '';
      this.openErrorDialog = false;
    },
  },
  watch: {
    microStay(value) {
      this.$emit('emitSelectedMicroStay', value);
    },
  },
};
</script>

<style lang="scss" scoped>
// responsiveness design
@media only screen and (max-width: 768px) {
  .details-main-heading{
    font-size: 25px !important;
  }
  .details-label-deals{
    font-size: 13px !important;
  }
  .details-heading-deals {
    font-size: 20px !important;
    font-weight: bold;
  }
  .deals-email-text-box {
      width: 300px !important;
  }
  .micro-stay-background {
      justify-content: center !important;
  }
}
.benefitsImageContainer {
    width: 40px;
}
.details-container {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 50px;
}
.details-card {
    display: flex;
    flex: 33.33;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.details-heading {
    text-align: center;
    color: #080914;
    font-weight: bold;
    font-size: 22px;
    margin: 13px 0 11px 0;
    line-height: 30px;
}
.details-label {
    display: block;
    width: 290px;
    text-align: center;
    color: rgba(8, 9, 20, 0.7);
    font-size: 16px;
}
.micro-stay-container {
    margin-top: 20px;
    padding-bottom: 2.9rem;
    background-color: #DCF0D6;
    padding:40px;
    text-align: center;
    justify-content: center;
}
.flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.text-color {
    color: #000 !important;
}
.details-main-heading {
    text-align: center;
    color: #FF4500;
    font-weight: bold;
    font-size: 36px;
    margin: 13px 0 11px 0;
    line-height: 30px;
}
.micro-stay-background {
    justify-content: space-between;
}
.p-10{
  padding: 10px;
}
.details-label-micro-stay {
    display: block;
    width: 350px;
    text-align: center;
    color: rgba(8, 9, 20, 0.7);
    font-size: 16px;
    margin-top: 20px;
}
.details-container-deals{
    margin-top: 20px;
    justify-content: center;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column
}
.details-heading-deals {
    font-size: 36px;
    margin: 13px 0 11px 0;
    line-height: 30px;
}
.details-label-deals{
    display: block;
    text-align: center;
    color: rgba(8, 9, 20, 0.7);
    font-size: 16px;
}
.details-label-email-container{
    justify-content: center;
    align-items: center;
    min-width: 100px !important;
    margin-top: -20px;
    font-family:Arial, Helvetica, sans-serif;
    padding:6px 17px;
}
.deals-email-text-box {
    width: 400px;
    text-align: left;
    font-family:Arial, Helvetica, sans-serif;
}
.deals-email-container{
    border: 1px solid red;

}
.subscribe-btn{
    font-size: 16px;
}
</style>
