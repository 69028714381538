<template>
	<v-card class="dialog-modal">
		<v-card-title v-if="mode!=='login'">
			<v-row d-flex align-content="center" justify="space-between">
				<h3>{{title}}</h3>
				<Button
					:class="{'alert' : mode === 'alert'}"
					type="icon"
					color="black"
					@buttonClicked="onCloseDialog"
					icon="mdi-close"
					height="25px"
				/>
			</v-row>
		</v-card-title>
		<div class="dialog-container">
			<Alert
				:alertActionMessage="alertActionMessage"
				:alertMessage="dialogData"
				@emitAlertSuccess="onDialogResponse"
				@emitCancleAlert="onCloseDialog"
				v-if="mode === 'alert'"
			></Alert>
			<CompareRates v-if="mode === 'compareRates'"></CompareRates>
			<div v-if="mode==='login'">
				<LoginForm v-if="mode==='login'" header="Login" @emitLogin="login" @emitGoogleLogin="onGoogleSigninSuccess" @emitFacebookLogin="onFacebookLoginSuccess" @emitAppleLogin="onAppleSigninSuccess" @switchToSignup="switchAuth" />
			</div>
			<div v-if="mode==='signup'">
				<SignUpForm
					title="Sign up"
					@switchToSignup="switchAuth"
					@signup="register"
					@emitGoogleSignup="googleSignin"
					@emitFacebookSignup="facebookLogin"
					@emitAppleLogin="appleSignin"
				/>
			</div>
			<div v-if="mode==='updateProfile'">
				Simply enter a password to create your free account.
				You’ll be able to manage bookings, save favourites, and rebook hotels.
				<v-form ref="loginForm" class="text-field-container" @submit.prevent="updateData()">
					<div class="mb-2">
						<span>First Name</span>
					</div>
					<TextField label :value="guestDetails.firstName" placeholder="E.g. Harry" :disabled="true" />
					<div class="mb-2">
						<span>Last Name</span>
					</div>
					<TextField label :value="guestDetails.lastName" placeholder="E.g. How" :disabled="true" />
					<div class="mb-2">
						<span>Mobile number</span>
					</div>
					<TextField
						label
						type="number"
						placeholder="E.g 0432 3200 30"
						:value="guestDetails.mobile"
						:disabled="true"
					/>
					<div class="mb-2">
						<span>Email Address</span>
					</div>
					<TextField
						label
						:value="guestDetails.email"
						placeholder="E.g. hello@timeflyz.com"
						:rules="emailRules"
						:disabled="true"
					/>
					<div class="password mb-2">
						<span>Password</span>
						<!-- <span @click="routeToResetPasswordPage()" class="forget-password">
            Forgot your password ?
						</span>-->
					</div>
					<div>
						<v-text-field
							v-model="password"
							placeholder="Password"
							single-line
							outlined
							:append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
							:type="show ? 'text' : 'password'"
							@click:append="show = !show"
							:rules="[v => v.length > 0 || '*Required*']"
						></v-text-field>
					</div>
					<v-col>
						<Button
							type="submit"
							value="Update"
							color="primary"
							height="40px"
							width="100%"
							:disabled="password.length > 0 ? false : true"
						/>
					</v-col>
				</v-form>
			</div>
			<div v-if="mode==='guestDialog'">
				<h4>
					Hi {{guestDetails.firstName}},
					<br />We see you have made a previous booking on TimeFlyz, would you like to
					setup an account to manage all future bookings.
				</h4>
				<v-row>
					<Button
						type="flat"
						@buttonClicked="onGuestUpdatePassword"
						value="Yes"
						color="primary"
						height="40px"
						width="100%"
						style="margin-top:20px;margin-right:5px;"
					/>
					<Button
						class="cancel-button"
						buttonClass="error-link"
						@buttonClicked="onCloseDialog"
						type="flat"
						value="No"
						height="40px"
						width="100%"
						style="margin-top:20px;"
					/>
				</v-row>
			</div>
			<div v-if="mode==='guestSetPasswordDialog'">
				<v-form class="text-field-container" @submit.prevent="updatePassword()">
					<div class="password mb-2">
						<span>Password</span>
						<!-- <span @click="routeToResetPasswordPage()" class="forget-password">
            Forgot your password ?
						</span>-->
					</div>
					<div>
						<v-text-field
							v-model="password"
							placeholder="Password"
							single-line
							outlined
							:append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
							:type="show ? 'text' : 'password'"
							@click:append="show = !show"
							:rules="[v => v.length > 0 || '*Required*']"
						></v-text-field>
					</div>
					<v-col>
						<Button
							type="submit"
							value="Set Password"
							color="primary"
							height="40px"
							width="100%"
							:disabled="password.length > 0 ? false : true"
						/>
					</v-col>
				</v-form>
			</div>
		</div>
	</v-card>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { EMAIL_PATTERN } from '@/constants';
import validation from '@/validations';
import Button from '@/common/Button.vue';
import Alert from '@/common/Alert.vue';
import LoginForm from '@/common/LoginForm.vue';
import SignUpForm from '@/common/SignupForm.vue';

const { updateUserPasswordService } = require('@/services/user/user.service');

export default {
  name: 'Dialog',
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      show: false,
      isEmailValid: false,
      emailRules: [
        validation.FORM_VALIDATION.required,
        validation.FORM_VALIDATION.email,
      ],
      params: {
        client_id: process.env.VUE_APP_WEB_CLIENT_ID,
      },
    };
  },
  props: [
    'onCloseDialog',
    'onGuestUpdatePassword',
    'onDialogResponse',
    'title',
    'mode',
    'dialogData',
    'formMode',
    'alertActionMessage',
    'guestDetails',
    'isNotRedirect',
  ],
  components: {
    Button,
    Alert,
    LoginForm,
    SignUpForm,
  },
  mounted() {
    this.authType = this.mode;
    this.authTitle = this.title;
  },
  methods: {
    ...mapActions({
      googleSignin: 'googleSignin',
      appleSignin: 'appleSignin',
      facebookSignin: 'facebookSignin',
      loginGuestUser: 'loginGuestUser',
      registerGuestUser: 'registerGuestUser',
      setLoginError: 'setLoginError',
    }),
    getFirstNameValue(value) {
      this.firstName = value;
    },
    getLastNameValue(value) {
      this.lastName = value;
    },
    getEmailValue(value) {
      this.email = value;
      if (EMAIL_PATTERN.test(this.email)) {
        this.isEmailValid = true;
      } else {
        this.isEmailValid = false;
      }
    },
    facebookLogin(facebookUser) {
      this.facebookSignin(facebookUser.authResponse.accessToken);
    },
    async onGoogleSigninSuccess(googleUser) {
      // This only gets the user information: id, name, imageUrl and email
      await this.googleSignin({ payload: googleUser, isNotRedirect: this.isNotRedirect });
      this.$emit('loginSuccessful');
    },
    async onFacebookLoginSuccess(payload) {
      await this.facebookSignin({ payload, isNotRedirect: this.isNotRedirect });
      this.$emit('loginSuccessful');
    },
    async onAppleSigninSuccess(payload) {
      await this.appleSignin({ payload, isNotRedirect: this.isNotRedirect });
      this.$emit('loginSuccessful');
    },
    onFailure() {
      this.setLoginError();
    },
    async login(email, password) {
      await this.loginGuestUser({
        email,
        password,
        userType: 'APP',
        vatgst: this.getCurrencyData._id,
      });
      this.$emit('loginSuccessful');
    },
    async register(payload) {
      await this.registerGuestUser({ ...payload, fromLogin: false });
      this.$emit('registerSuccessful');
    },
    async updateData() {
      const userDetails = {
        password: this.password,
        email: this.guestDetails.email,
      };
      await updateUserPasswordService(userDetails);
      this.$emit('updateSuccessful');
    },
    async updatePassword() {
      const userDetails = {
        password: this.password,
        email: this.guestDetails.email,
      };
      const updatePass = await updateUserPasswordService(userDetails);
      this.$cookies.set('token', updatePass.data.token);
      this.$cookies.set('isLoggedIn', true);
      this.$emit('updateSuccessful');
    },
    switchAuth() {
      this.$emit('emitAuthType');
    },
  },
  computed: {
    ...mapGetters({
      getCurrencyData: 'getCurrencyData',
    }),
    ...mapState({
      loginError: state => state.user.error,
    }),
    disabled() {
      if (this.email && this.password && this.isEmailValid) {
        return false;
      }
      return true;
    },
    facebookAppId() {
      return process.env.VUE_APP_FACEBOOK_APP_ID;
    },
  },
};
</script>

<style lang="scss">
	.dialog-modal {
		.v-card__title {
			padding: 17px 24px !important;
			border-bottom: 1px solid #c4c5cc !important;
			font-size: 18px !important;
			font-weight: bold !important;
		}
		.dialog-container {
			padding: 24px;
		}
		.text-field-container {
			margin-top: 15px;
		}
		.alert {
			::v-deep .theme--light.v-btn.v-btn--icon:hover,
			::v-deep .theme--light.v-btn.v-btn--icon:focus {
				color: $error-color !important;
			}
		}
		.form-outline {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			::v-deep .abcRioButtonContents {
				margin: unset !important;
			}
			::v-deep .abcRioButtonContentWrapper {
				display: flex;
				justify-content: flex-start;
			}

			.margin-top-1 {
				margin-top: 1rem;
			}

			.google-button {
				min-width: 10rem;
				margin-top: 1rem;
				width: 100%;
				color: #000;
				font-size: 16px;
				font-weight: bold;
				box-sizing: border-box;
				box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 0px;
				padding: 0.5rem;
				border-radius: 0.25rem;
				display: flex;
				justify-content: center;
				font-family: sans-serif;
				.google-icon {
					margin-right: 1rem;
				}
			}

			.facebook-button {
				width: 199px;
				height: 38px;
				color: #fff;
				font-size: 14px;
				border-radius: 3px;
				background-color: #3c57a4;
				display: flex;
				justify-content: center;
				align-items: center;
				font-family: sans-serif;
				margin-bottom: 10px;
				.facebook-icon {
					margin-right: 5px;
				}
			}
		}
	}
</style>
