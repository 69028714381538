<template>
  <div class="custom-info-window" @click="handelHotel">
    <img :src="hotel.photos[0]" alt="Info Window Image" class="info-image"/>
    <div class="info-content">
      <div class="hotel-name"> {{hotel.name}} </div>
      <div class="single-rating-text-map">{{ hotel.rating }}<v-icon size="15" color="#f0f0f0" class="star-icon" >mdi-star</v-icon></div>
      <div v-if="available !== '--'" class="hotel-price">From : <div class="price-text">{{currency.shortName}}{{Math.round(available * currency.exchangeRate)}}</div></div>
      <div v-else class="no-available"> <v-icon color="#cf352e" class="no-available-icon">mdi-alert-circle</v-icon>Rooms are not available</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['hotel', 'available', 'currency'],
  methods: {
    checkPrice(hotel) {
      return Lodash.some(hotel.rooms, room => room.isAvailable) ? `${currency.shortName} ${Math.round(hotel.minimumPrice * currency.exchangeRate)}` : '--';
    },
    handelHotel() {
      this.$emit('selectHotel');
    },
  },
};
</script>

<style scoped>
.custom-info-window {
  max-width: 300px;
  display: flex;
}

.info-content {
  padding: 5px;
}

.info-image {
    width: 100px;
    height: 100px;
    padding: 5px;
    background-size: contain;
}
.hotel-name {
    font-size: 12px;
    font-weight: 700;
    width: 160px;
}

.single-rating-text-map {
font-size: 12px;
width: 40px;
color: #F0F0F0;
text-align: center;
background: #ff4500;
padding: 3px;
border-radius: 5px 5px 5px 0px;
margin-top: 5px;
}
.hotel-rate-container-map {
display: flex;
justify-content: space-between;
align-items: center;
width: 45%;
position: absolute;
bottom: 5px;
}

.hotel-price {
    font-size: 14px;
    font-weight: 700;
    position: absolute;
    bottom: 10px;
    display: flex;
}
.no-available {
    font-size: 12px;
    font-weight: 400;
    background: rgba(230, 58, 60, 0.1);
    position: absolute;
    bottom: 10px;
    padding: 2px;
}
.price-text {
    color: #ff4500;
    margin-left: 5px;
}

.no-available-icon {
  font-size: 18px;
  margin-right: 5px;
}
.star-icon {
  font-size: 15;
  margin-left: 2px;
}

@media only screen and (max-width: 768px) {
.hotel-name {
    font-size: 11px;
    font-weight: 700;
    width: 120px;
}
  .no-available {
    font-size: 9px;
    width: 120px;
}
.no-available-icon {
  font-size: 12px;
  margin-right: 3px;
  margin-top: -1px;
}
.single-rating-text-map {
font-size: 10px;
width: 40px;
}
.star-icon {
  font-size: 13px;
  margin-left: 2px;
  margin-top: -2px;
}
}
</style>
