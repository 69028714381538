<template>
  <v-menu offset-y
          :nudge-top="-5" v-if="!this.emptyTime">
    <template v-slot:activator="{ on, attrs }">
      <v-btn id="time-selector"
             height="40"
             width="100%"
             v-bind="attrs"
             v-on="on"
             :ripple="false"
             elevation="0"
             retain-focus-on-click>
        <v-icon color="grey"
                class="mr-2">
          mdi-calendar-clock
        </v-icon>
        {{ displayItem }}
      </v-btn>
    </template>
    <div v-if="items.length"
         class="content">
      <div v-for="(item, index) in items"
           :key="index"
           @click="handleClick(item)"
           :class="{ item: true, selected: selected === item.value }">
        {{ item.text }}
      </div>
    </div>
  </v-menu>
          <div v-else class="error-text">
          <v-icon color="primary" size="18">mdi-alert-circle</v-icon>
          <span class="notAvailable">Check-in times are not available for today.</span>
        </div>
</template>

<script>
import moment from 'moment';

export default {
  props: ['date', 'value'],
  emits: ['input', 'onChange'],
  components: {
  },
  data() {
    return {
      items: [],
      selected: this.value,
      emptyTime: false,
    };
  },
  mounted() {
    this.calculateHours();
  },
  computed: {
    displayItem() {
      const item = this.items.find(i => i.value === this.selected);
      return item?.text || 'Check-in time';
    },
  },
  methods: {
    nearest30min() {
      // const start = moment('2023/01/19 07:45 am', 'YYYY/MM/DD hh:mm a').add(30, 'minutes');
      const start = moment(); // .add(30, 'minutes');
      const remainder = 30 - (start.minute() % 30);
      return moment(start).add(remainder, 'minutes');
    },
    handleClick(item) {
      this.selected = item.value;
      this.$emit('input', item.value);
      this.$emit('onChange', item.value);
    },
    calculateHours() {
      let startMoment = this.date ? moment(this.date).startOf('date') : moment();
      if (startMoment.date() === moment().date()) {
        startMoment = this.nearest30min();
      }
      const endMoment = moment(startMoment).add(1, 'day');
      while (startMoment.date() !== endMoment.date()) {
        this.items.push({ text: startMoment.format('h:mm A'), value: startMoment.format('HH:mm') });
        startMoment.add(30, 'minutes');
      }
      if (this.items[0].value === '00:00') {
        this.items.splice(0, 16);
        this.items.splice(this.items.length - 5, 5);
      } else {
        this.items.splice(this.items.length - 5, 5);
      }

      if (this.items.length === 0) {
        this.emptyTime = true;
      }

      if (!this.value) {
        this.selected = this.items[0].value;
        this.$emit('input', this.items[0].value);
        this.$emit('onChange', this.items[0].value);
      }
    },
  },
  watch: {
    date() {
      this.items = [];
      this.calculateHours();
    },
  },
};
</script>

<style lang="scss" scoped>
#time-selector {
  ::v-deep .v-btn__content {
    justify-content: flex-start;
    text-transform: none;
    font-size: 16px;
    letter-spacing: 0px;
  }
}

.error-text {
  width: 100%;
  margin-top: -10px;
  color: $error-color;
  font-size: 15px;
  // background: rgba(230, 58, 60, 0.1);
  border-radius: 4px;
  padding: 5px 0px;
  align-items: center;
  span {
    margin-left: 10px;
    position: relative;
  }
}

.notAvailable{
  font-size: 14px;
}

.content {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    max-width: 450px;
  }

  max-height: 450px;
  overflow-y: auto;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;

  .item {
    width: 25%;

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      width: 33.33%;
    }

    text-align: center;
    padding: 10px;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;

    &.selected {
      color: $primary-color;
      font-weight: bold;
    }

    &:hover {
      color: #fff;
      background-color: rgba($secondary-color, .5);
    }
  }
}
</style>
