
<template>
  <v-container class="bg-surface-variant text-center">
           <!-- <v-btn outlined color="primary" class="pa-2 ma-2"  @click="dialog = true">
          Filters
       <v-icon color="#ffffff" class="ma-2">
          mdi-filter
        </v-icon>
      </v-btn> -->
          <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title style="sort-filter">
          <span class="text-h5">Filters</span>
          <v-icon color="#ff4500" class="close-filter" @click="dialog = false">
          mdi-window-close
        </v-icon>
        </v-card-title>
        <div class="filter-line"/>
        <v-card-text style="height: 180px'">
      <p class="heading">
      Purpose
      </p>
         <v-container fluid >
          <v-list v-for="purpose in purposes" :key="purpose.return">
    <v-checkbox
      v-model="selectedPurposes"
              :label="purpose.display"
              :value="purpose.display"
              class="custom-checkbox"
              style="margin-top: -15px; padding: 0;"
    ></v-checkbox>
     </v-list>
            </v-container>
        </v-card-text>
         <div class="filter-line"/>
        <v-card-text style="height: 180px;" >
          <p class="heading"> Duration</p>
         <v-container fluid >
  <v-list v-for="duration in durations" :key="duration.return">
    <v-checkbox
      v-model="selectedDuration"
              :label="duration.display"
              :value="duration.time"
              class="custom-checkbox"
              style="margin-top: -15px; padding: 0;"
    ></v-checkbox>
          </v-list>
            </v-container>
        </v-card-text>
         <div class="filter-line"/>
        <v-card-text style="height: 230px;" >
          <p class="heading">Star Rating</p>
  <v-container fluid >
     <v-list v-for="starRating in starRatings" :key="starRating.return">
    <v-checkbox
      v-model="selectedStarRating"
              :value="starRating.rate"
              class="custom-checkbox"
              style="margin-top: -15px; padding: 0;"
    ><template v-slot:label >
        <v-icon  color="#FDCC0D" v-for="n in starRating.return"
        :key="n">mdi-star</v-icon>
      </template></v-checkbox>
          </v-list>
  </v-container>
        </v-card-text>
         <div class="filter-line"/>
        <v-card-text v-bind:style= "[selectedCheckInTime === 'Choose Time' ? {'height': '190px'} : {'height': '150px'}]" >
          <p class="heading">Check-in time</p>
         <v-container fluid >
            <v-radio-group
            v-model="selectedCheckInTime"
            column
          >
            <v-radio
              label="Anytime"
              value="Anytime"
              class="custom-checkbox"
              style="margin: 0; padding: 0; margin-bottom: 10px;"
            ></v-radio>
            <v-radio
              label="Choose Time"
              value="Choose Time"
              class="custom-checkbox"
              style="margin: 0; padding: 0;"
            ></v-radio>
    </v-radio-group>
    <div v-if="selectedCheckInTime === 'Choose Time'">
    <TimeSelector :date="selectDate" v-model="timeSelected" @onChange="getTime" />
    </div>
            </v-container>
        </v-card-text>
         <div class="filter-line"/>
         <v-card-text style="height: 370px;">
        <p class="heading" style="margin-bottom: 40px;">Sort</p>
        <v-radio-group
            v-model="dialogm"
            column
            v-for="sort in sortLists"
            :key="sort.text"
          >
            <v-radio
              :label="sort.text"
              :value="sort.value"
               style="margin-top: -20px; padding: 0; margin-bottom: 10px; margin-left: 10px;"
            ></v-radio>
            </v-radio-group>
        </v-card-text>
         <div class="filter-line"/>
        <v-card-actions  style="padding-bottom: 70px;">
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="clearFilter"
          >
            Reset
          </v-btn>
          <v-btn
            color="#ff4500"
            variant="text"
            class="white--text"
            @click="selectFilters"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

    <!-- <v-row no-gutters>
     <v-col>

      </v-col>
      <v-col  v-if="!this.manageElement">
        <v-btn outline variant="tonal" class="pa-2 ma-2">
          Best Value
        <v-icon color="#ff4500" class="ma-2">
          mdi-alert-circle-outline
        </v-icon>
        </v-btn>

      </v-col>
            <v-col v-if="!this.manageElement">
        <v-btn outline variant="tonal" class="pa-2 ma-2">
         Lowest Price
        </v-btn>
      </v-col>
      <v-col v-if="!this.manageElement">
        <v-btn outline variant="tonal" class="pa-2 ma-2">
          Most Stars
        </v-btn>
      </v-col>
      <v-col v-if="!this.manageElement">
        <v-btn outline variant="tonal" class="pa-2 ma-2">
          Nearest To Me
        </v-btn>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import { SORT_LISTS } from '@/constants';
import TimeSelector from '@/common/TimeSelector.vue';
import moment from 'moment';

export default ({
  name: 'SortPanel',
  emits: ['emitReset', 'emitSelectedFilters'],
  data() {
    return {
      microStay: 'All',
      dialogm: '',
      selected: '',
      dialog: false,
      filterText: ['All Filters', 'Best Value', 'Lowest Price', 'Most Starts', 'Nearest From Me'],
      sortLists: SORT_LISTS,
      sortValue: {
        text: 'Recommended',
        value: '',
      },
      timeSelected: undefined,
      manageElement: false,
      dateSelected: this.getCurrentDate(),
      selectedCheckInTime: '',
      purposes: [{ return: 1, display: 'Business' }, { return: 2, display: 'Leisure' }, { return: 3, display: 'Transit' }],
      selectedPurposes: [],
      durations: [{ return: 3, display: '3 Hours', time: '3' }, { return: 6, display: '6 Hours', time: '6' }, { return: 12, display: '12 Hours', time: '12' }],
      selectedDuration: [],
      starRatings: [{ return: 5, rate: '5' }, { return: 4, rate: '4' }, { return: 3, rate: '3' }, { return: 2, rate: '2' }],
      selectedStarRating: [],
    };
  },
  components: {
    TimeSelector,
  },
  props: ['isLoggedIn', 'selectDate'],
  watch: {
    microStay(value) {
      this.$emit('emitSelectedMicroStay', value);
    },
    sortValue(value) {
      this.$emit('emitSelectedSort', value.value);
    },
  },
  mounted() {
    window.addEventListener('resize', (event) => {
      if (window.innerWidth < 768) {
        // disable scrolling or do whatever you want to do
        this.manageElement = true;
      } else {
        this.manageElement = false;
      }
    });
  },
  created() {
    // this.microStay = get(this.$route, 'query.microStay', 'All');
    this.$parent.$on('filter-event', this.handleopenFilter);
  },
  methods: {
    getCurrentDate() {
      // Add 30 minutes to current time and update time to nearest 30 minute interval
      const currentDate = new Date();
      const updatedDate = new Date(currentDate.getTime() + 30 * 60000);
      const remainder = 30 - (moment(updatedDate).minute() % 30);
      return new Date(moment(updatedDate).add(remainder, 'minutes'));
    },

    clearFilter() {
      this.$emit('emitReset',
        [this.selectedPurposes = [],
          this.selectedDuration = [],
          this.selectedStarRating = [],
          this.dialogm = '',
          this.selectedCheckInTime = '']);
      this.dialog = false;
    },
    selectFilters() {
      this.$emit('emitSelectedFilters',
        [this.selectedPurposes.join(),
          this.selectedDuration.join(),
          this.selectedStarRating.join(),
          this.dialogm,
          this.selectedCheckInTime,
          this.timeSelected]);
      this.dialog = false;
    },

    getTime(time) {
      this.timeSelected = moment(time, 'h:mm a').format('HH:mm');
    },
    handleopenFilter() {
      this.dialog = true;
    },
  },
});
</script>

<style scoped lang="scss">
.filter-sort-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-by-container {
  display: flex;
  align-items: center;

  span {
    font-weight: bold;
    margin-right: 10px;
    font-size: 16px;
  }

  .v-input--selection-controls {
    padding: 0px !important;
    margin-top: 0px !important;
  }

  ::v-deep .filter-radio-group .v-input__control {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: unset !important;
  }

  ::v-deep .filter-radio-group .v-input--radio-group__input {
    display: flex !important;
    flex-direction: row !important;
  }

  .v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
    margin-bottom: 0px !important;
  }
}

.details-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding-bottom: 2.9rem;
}

.micro-stay-container {
  display: flex;
  flex-direction: row;
}

.card-selected {
  border: 2px solid #FF4500;

  .text-color {
    color: #FF4500 !important;
  }
}

.text-color {
  color: white !important;
}

.overlay {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.micro-stay-background {
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  display: flex;
  flex: 33.33;
}

.business {
  background-image: url("../../assets/business-min.png");
}

.leisure {
  background-image: url("../../assets/leisure-min.png");
}

.transit {
  background-image: url("../../assets/transit-min.png");
}

.micro-stay-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 30px;
  position: relative;
}

.details-card {
  display: flex;
  flex: 33.33;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.details-heading {
  text-align: center;
  color: #080914;
  font-weight: bold;
  font-size: 18px;
  margin: 13px 0 11px 0;
}

.details-label {
  display: block;
  width: 290px;
  text-align: center;
  color: rgba(8, 9, 20, 0.7);
  font-size: 13px;
}

.heading {
  font-size: 18px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.6);
}

.filter-line {
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.4);
  margin-bottom: 10px;
}

.close-filter {
  margin-left: 65%;
}

// responsiveness design
@media only screen and (max-width: 768px) {
  .micro-stay-card {
    padding-bottom: 0px !important;
  }

  .micro-stay-background {
    width: 33.33%;
    height: 150px;
    margin-right: 1px;
  }

  .micro-stay-container {
    .details-label {
      display: none !important;
    }
  }
}

@media only screen and (max-width: 425px) {
  .filter-sort-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .filter-by-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .sort-hotels {
    width: 100%;
    margin-top: 20px;
  }
}
</style>
