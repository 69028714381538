<template>
  <v-card class="dialog-modal">
    <v-card-title>
      <v-row d-flex align="start" justify="space-between" class="overflow-auto">
        <h3>{{dialogData}}</h3>
      </v-row>
    </v-card-title>
    <div class="dialog-container">
      <div v-if="mode==='snackbar-error'">
        <v-col>
        <Button
            type="flat"
            @buttonClicked="onCloseDialog"
            value="Close"
            color="primary"
            height="40px"
            width="100%"
        />
        </v-col>
      </div>
    </div>
  </v-card>
</template>

<script>

export default {
  name: 'ErrorDialog',
  props: [
    'onCloseDialog',
    'onDialogResponse',
    'mode',
    'dialogData',
    'title',
  ],
  components: {
  },
  methods: {
  },
};
</script>

<style lang="scss">
.dialog-modal {
  .v-card__title {
    padding: 17px 24px !important;
    border-bottom: 1px solid #c4c5cc !important;
    font-size: 18px !important;
    font-weight: bold !important;
  }
  .dialog-container {
    padding: 24px;
  }
  .text-field-container {
    margin-top: 15px;
  }
  .alert {
    ::v-deep .theme--light.v-btn.v-btn--icon:hover,
    ::v-deep .theme--light.v-btn.v-btn--icon:focus {
      color: $error-color !important;
    }
  }
}
</style>
