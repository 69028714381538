<template>
  <div class="booking-success" v-bind:style= "[isMobile ? {'margin-top': '25%'} : {'margin-top': '0px'}]">
    <EmptyStateCard
      emptyStateHeading="Your hotel has been successfully booked!"
      :emptyStateLabel="displayMessage"
      buttonValue="View hotel"
      @emptyStateClicked="goToUpcommingBookings"
      :imageUrl="imageUrl"
      imageWidth="100px"
      imageHeight="100px"/>
    <div v-if="updatePassword">
      <v-dialog v-model="updatePassword" max-width="370px">
        <Dialog
          @updateSuccessful="getLoggedInUserDetails()"
          :onCloseDialog="closeDialog"
          mode="updateProfile"
          title="Create Account"
          :guestDetails="currentData"
          alertActionMessage="Cancel booking"
        />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import EmptyStateCard from '@/common/EmptyStateCard.vue';
import checkCircle from '@/assets/check-circle.svg';
import { HTTP_STATUS_CODE, TOASTER_MESSAGE } from '@/constants';

const { getLoggedInUserDetailService } = require('@/services/user/user.service');

export default {
  name: 'BookingSuccess',
  data() {
    return {
      imageUrl: checkCircle,
      updatePassword: false,
      currentData: {},
      updateSuccess: false,
      displayMessage: '',
      isMobile: window.innerWidth <= 768,
    };
  },
  components: {
    EmptyStateCard,
  },
  created() {
    localStorage.setItem('bookingStatus', 'true');
    this.getLoggedInUserDetails();
  },
  beforeRouteLeave(to) {
    localStorage.removeItem('displayMessage');
    window.location.href = to.fullPath;
  },
  methods: {
    goToUpcommingBookings() {
      this.$router.push('/bookings/upcoming');
    },
    async getLoggedInUserDetails() {
      this.displayMessage = await localStorage.getItem('displayMessage');
      try {
        const getLoggedInUserResponse = await getLoggedInUserDetailService();
        if (getLoggedInUserResponse.status === HTTP_STATUS_CODE.SUCCESS) {
          if (getLoggedInUserResponse.data.provider === 'Guest') {
            this.updatePassword = true;
            this.updateSuccess = false;
            this.currentData = getLoggedInUserResponse.data;
          } else {
            this.updateSuccess = true;
            this.updatePassword = false;
          }
        } else {
          throw new Error();
        }
      } catch (err) {
        this.snackbar = true;
        this.openDialog = true;
        this.toasterMessage = TOASTER_MESSAGE.TROUBLE;
      }
    },
    closeDialog() {
      this.updatePassword = false;
      if (!this.updateSuccess) {
        this.leaveToken();
      }
    },
    leaveToken() {
      this.$cookies.remove('token');
      this.$cookies.remove('isLoggedIn');
      window.location.href = '/';
      this.updatePassword = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.booking-success {
  padding: 2.9rem 0 2.9rem 0;
}
</style>
