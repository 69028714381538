<template>
  <v-flex class="tf-card-container">
    <v-flex class="tf-card-title px-4 my-3 pb-2">
        <v-flex class="tf-card-title-text" xs8>
            <slot name="title-text"></slot>
        </v-flex>
        <v-flex class="tf-card-icon" xs4>
            <slot name="title-icon"></slot>
        </v-flex>
    </v-flex>
    <v-flex class="tf-card-content">
      <slot name="content"></slot>
    </v-flex>
    <v-flex class="tf-card-footer py-4">
      <slot name="footer"></slot>
    </v-flex>
  </v-flex>
</template>

<script>
export default {};
</script>

<style lang="scss">
.tf-card-container {
  font-family: Abel;
  min-width: 470px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);

    .tf-card-title {
        border-bottom: 1px solid #E7E7E9;
        display: flex;

        .tf-card-title-text {
          font-size: 18px;
          line-height: 24px;
        }

      .tf-card-icon {
        display: flex;
        justify-content: flex-end;
        opacity: 0.35;
      }
    }
}

// responsiveness design
@media only screen and (max-width: 768px) {
  .tf-card-container {
    min-width: 100% !important;
  }
}
</style>
