import Alert from './Alert.vue';
import Avatar from './Avatar.vue';
import Carousel from './Carousel.vue';
import DatePicker from './DatePicker.vue';
import Button from './Button.vue';
import Dialog from './Dialog.vue';
import EmptyStateCard from './EmptyStateCard.vue';
import FGButton from './FGButton.vue';
import HotelCard from './HotelCard.vue';
import Menu from './Menu.vue';
import NumberTabs from './NumberTabs.vue';
import RoomCard from './RoomCard.vue';
import Select from './Select.vue';
import SignupForm from './SignupForm.vue';
// import SnackbarHeader from './SnackbarHeader.vue';
import Table from './Table.vue';
import Tabs from './Tabs.vue';
import TextArea from './TextArea.vue';
import TextField from './TextField.vue';
import TimePicker from './TimePicker.vue';
import TfCard from './TfCard.vue';

export default {
  Alert,
  Avatar,
  Button,
  Carousel,
  DatePicker,
  Dialog,
  EmptyStateCard,
  FGButton,
  HotelCard,
  Menu,
  NumberTabs,
  RoomCard,
  SignupForm,
  Select,
  // SnackbarHeader,
  Table,
  Tabs,
  TextArea,
  TextField,
  TimePicker,
  TfCard,
};
