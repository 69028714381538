const axios = require('axios');
const constants = require('@/constants');

module.exports = {
  getReviewTags: () => new Promise((resolve, reject) => {
    axios.get(`${constants.API.REVIEW}/tagList`,
      {
        headers: {},
      }).then(response => resolve(response))
      .catch(err => reject(err));
  }),
  saveRatingReview: payload => new Promise((resolve, reject) => {
    axios.post(constants.API.REVIEW, payload,
      {
        headers: {
          Authorization: `Bearer ${window.$cookies.get('token')}`,
        },
      }).then(response => resolve(response))
      .catch(err => reject(err));
  }),
  updateRatingReview: payload => new Promise((resolve, reject) => {
    axios.put(constants.API.REVIEW, payload,
      {
        headers: {
          Authorization: `Bearer ${window.$cookies.get('token')}`,
        },
      }).then(response => resolve(response))
      .catch(err => reject(err));
  }),
};
