<template>
  <div>
    <span>{{alertMessage}}</span>
    <div class="alert-action-container">
      <v-row align="center" justify="end">
        <Button
          class="cancel-button"
          buttonClass="error-link"
          @buttonClicked="cancleAlert"
          type="link"
          value="Cancel"
        />
        <Button
          type="flat"
          @buttonClicked="onAlertSuccess"
          :value="alertActionMessage"
          color="error"
          height="40px"
          width="100%"
        />
      </v-row>
    </div>
  </div>
</template>

<script>
import Button from '@/common/Button.vue';

export default {
  name: 'Alert',
  props: ['alertMessage', 'alertActionMessage'],
  components: {
    Button,
  },
  methods: {
    onAlertSuccess() {
      this.$emit('emitAlertSuccess');
    },
    cancleAlert() {
      this.$emit('emitCancleAlert');
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-action-container {
  margin-top: 40px;
}
.cancel-button {
  margin-right: 1rem;
}
</style>
