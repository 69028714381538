<template>
<SignUp />
</template>

<script>
import SignUp from '@/components/Signup.vue';

export default {
  components: {
    SignUp,
  },
};
</script>

<style>

</style>
