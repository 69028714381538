<template>
  <v-row>
    <vue-ctk-date-time-picker
      id="TimePicker"
      v-model="timeRange"
      :format="'h:mm A'"
      :formatted="'h:mm A'"
      :label="'Select time'"
      :onlyTime="true"
      :noLabel="true"
      :minuteInterval="30"
      color="#ff4500"
      button-color="#4a4c4c"
      @input="emitTimeValue"
    >
    </vue-ctk-date-time-picker>
  </v-row>
</template>

<script>
import moment from 'moment';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

export default {
  name: 'TimePicker',
  props: ['value'],
  components: {
    VueCtkDateTimePicker,
  },
  data() {
    return {
      timeRange: moment(new Date()).format('h:mm A'),
    };
  },
  mounted() {
    if (this.value) {
      this.timeRange = this.value;
    } else {
      this.timeRange = '';
    }
  },
  methods: {
    emitTimeValue(time) {
      this.timeRange = time;
      this.$emit('emitTimeValue', time);
    },
  },
};
</script>

<style lang="scss">
.light-timepicker{
  width: 100%;
  border: 1px solid red;
  height: 39px;
  background-color: #fff;
  display: flex;
  align-items: center;
}
.light-timepicker input{
  width: 100%;
  padding:8px;
}
.light-timepicker input:focus-visible{
  outline: none;
}

// responsiveness design
@media only screen and (max-width: 1263px) {
  .light-timepicker{
    width: unset;
    position: relative;
    z-index: 99;
  }
}
</style>
