module.exports = {
  // TODO to remove
  GOOGLE_MARKERS: [
    {
      position: {
        lat: -33.87143,
        lng: 151.22840999999994,
      },
      selectedMarker: true,
      markerInfo: '130',
    },
  ],
  SORT_LISTS: [
    {
      value: '',
      text: 'Recommended (Default)',
    },
    {
      value: {
        name: 'price',
        value: 'desc',
      },
      text: 'Price - high to low',
    },
    {
      value: {
        name: 'price',
        value: 'asc',
      },
      text: 'Price - low to high',
    },
    {
      value: {
        name: 'rating',
        value: 'desc',
      },
      text: 'Star Rating - high to low',
    },
    {
      value: {
        name: 'rating',
        value: 'asc',
      },
      text: 'Star Rating - low to high',
    },
    {
      value: {
        name: 'distance',
        value: 'asc',
      },
      text: 'Distance - closest proximity',
    },
  ],
  RATINGS_TYPE: [
    {
      value: 1,
      type: 'Poor',
    }, {
      value: 2,
      type: 'Average',
    }, {
      value: 3,
      type: 'Good',
    }, {
      value: 4,
      type: 'Very Good',
    }, {
      value: 5,
      type: 'Excellent',
    },
  ],
  // eslint-disable-next-line
  EMAIL_PATTERN: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  SNACKBAR_TIMEOUT: 3000,
  PAYMENT_PLATFORM: 'https://stripe.com',
  HTTP_STATUS_CODE: {
    SUCCESS: 200,
  },
  TOASTER_MESSAGE: {
    TROUBLE: 'Something went wrong! Please try again later.',
    CHANGES_SAVED: 'Changes Saved Successfully',
    LOGIN_ERROR: 'Email or Password is incorrect',
    CURRENCY_SAVE: 'Currency updated successfully.',
  },
  API: {
    HOMEPAGE: `${process.env.VUE_APP_ENV_URL}/cms/list`,
    HOTEL_NAME_SEARCH: `${process.env.VUE_APP_ENV_URL}/hotel/hotelList`,
    HOTEL_LAT_LNG_SEARCH: `${process.env.VUE_APP_ENV_URL}/hotel/geoLocation`,
    USER: `${process.env.VUE_APP_ENV_URL}/user/me`,
    BANUSER: `${process.env.VUE_APP_ENV_URL}/user/checkBanUser`,
    APPLE_UPDATE_EMAIL: `${process.env.VUE_APP_ENV_URL}/user/apple`,
    USEREMAIL: `${process.env.VUE_APP_ENV_URL}/user`,
    FAVOURITE_HOTELS: `${process.env.VUE_APP_ENV_URL}/mobile/user/favorites`,
    BOOK_ROOM: `${process.env.VUE_APP_ENV_URL}/reservation/hotel`,
    INVOICE_LISTING: `${process.env.VUE_APP_ENV_URL}/invoice`,
    GET_RECEIPT: `${process.env.VUE_APP_ENV_URL}/reservation/getReceipt`,
    GET_STRIPE_ACCOUNT_LINK: `${process.env.VUE_APP_ENV_URL}/user/billing/stripe/account/link`,
    STRIPE_AUTHORIZATION: `${process.env.VUE_APP_ENV_URL}/user/billing/stripe/authorize`,
    STRIPE_INTENT_CREATE: `${process.env.VUE_APP_ENV_URL}/reservation/stripeIntent`,
    USER_PASS: `${process.env.VUE_APP_ENV_URL}/user/guest`,
    REVIEW: `${process.env.VUE_APP_ENV_URL}/review`,
    CURRENCY_CONVERTER: `${process.env.VUE_CURRENCY_CONVERTER_API_STRIPE_KEY}`,
    STRIPE_API_KEY: `${process.env.STRIPE_API_KEY}`,
    FAQS: `${process.env.VUE_APP_ENV_URL}/faq/get`,
    SUBSCRIPTION: `${process.env.VUE_APP_ENV_URL}/subscription/create`,
    CONTACT: `${process.env.VUE_APP_ENV_URL}/contact/create`,
    ALIAS_APPLE_UPDATE_EMAIL: `${process.env.VUE_APP_ENV_URL}/user/alias-apple`,
    FORGOT_PASSWORD_EMAIL: `${process.env.VUE_APP_ENV_URL}/user/me/password/forgot`,
    ALL_CURRENCY: `${process.env.VUE_APP_ENV_URL}/vatgst/all`,
    ALL_PREFERENCE_CURRENCY: `${process.env.VUE_APP_ENV_URL}/vatgst/preference`,
    SAVE_CURRENCY: `${process.env.VUE_APP_ENV_URL}/user/userPreferences`,
  },
};
