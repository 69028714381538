import axios from 'axios';
import Vue from 'vue';
import VueCookies from 'vue-cookies';

Vue.use(VueCookies);

const base = axios.create({
  baseURL: process.env.VUE_APP_ENV_URL,
});

base.interceptors.request.use((config) => {
  const token = Vue.$cookies.get('token');
  const isLoggedIn = Vue.$cookies.get('isLoggedIn');
  if (token && isLoggedIn === 'true') {
    config.headers.Authorization = `Bearer ${token}`;
  }
  const localeInfo = Vue.$cookies.get('localeInfo');
  if (localeInfo) {
    config.headers['timezone'] = localeInfo.timezone;  // eslint-disable-line
    config.headers['country'] = localeInfo.country; // eslint-disable-line
    config.headers['currency'] = localeInfo.currency; // eslint-disable-line
  }
  return config;
});

base.interceptors.response.use(response =>
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  // eslint-disable-next-line implicit-arrow-linebreak
  response,
error =>
  // eslint-disable-next-line prefer-promise-reject-errors, implicit-arrow-linebreak
  Promise.reject(error?.response?.data?.message || error || 'Something went wrong! Please try again later'));


export default base;
