<template>
  <v-container :class="{ 'search-hotels-home': true, 'position-absolute': !isHotelsPage }">
    <div class="filter-actions">
      <form @submit.prevent="submitForm(true)">
        <v-flex class="search-container filter-mobile-cotainer"
                d-flex>
          <v-flex class="mr-8px"
                  lg5
                  xl4
                  md12
                  sm12
                  xs12>
            <div class="label-header"><v-icon class="map-icon" color="#ff4500">mdi-map-marker</v-icon>Hotel Name / Destination</div>
            <!-- :search-input.sync="searchValue" -->
            <!-- item-text="name"
							item-value="value" -->
            <v-autocomplete no-filter
                            v-model="filters.search"
                            :search-input.sync="filters.query"
                            :auto-select-first="true"
                            solo
                            :multiple="false"
                            :required="true"
                            placeholder="Search by hotel name / destination..."
                            :items="items"
                            item-text="name"
                            return-object>
              <!-- <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.type"></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template> -->
            </v-autocomplete>
          </v-flex>
          <v-flex class="mr-8px mb-5"
                  lg3
                  xl4
                  md12
                  sm12
                  xs12>
            <div class="label-header"><v-icon class="map-icon" color="#ff4500"> mdi-calendar</v-icon>Check in date</div>
            <!-- <div style="border: 5px solid #000"> -->
            <DatePicker v-model="filters.date" />
            <!-- </div> -->
          </v-flex>
          <!-- <v-flex class="mr-8px mb-5"
                  lg2
                  xl2
                  md12
                  sm12
                  xs12>
            <div class="label-header">Check in time</div>
            <TimeSelector :date="filters.date"
                          v-model="filters.time" />
          </v-flex> -->
          <!-- <v-flex class="mb-5"
                  lg2
                  xl2
                  md12
                  sm12
                  xs12>
            <div class="label-header">Length of Stay</div>
            <div class="hours hours-button">
              <div v-for="hour in stayingHours"
                   :class="{ 'selected-hour-btn': filters.duration === hour }"
                   @click="filters.duration = hour"
                   :key="hour"
                   class="hour-button pointer">{{ hour }}h</div>
            </div>
          </v-flex> -->
          <v-flex d-flex
                  align-center
                  lg3
                  xl3
                  md12
                  sm12
                  xs12
                  style="padding-top:22px; padding-bottom:10px">
            <Button type="submit"
                    value="Search"
                    color="primary"
                    height="35px"
                    width="100%"
                    style="width: 100%" />
          </v-flex>
        </v-flex>
      </form>
    </div>
  </v-container>
</template>

<script>
import { datadogLogs } from '@datadog/browser-logs';

import {
  get, map, isEmpty, debounce, concat,
} from 'lodash';
import moment from 'moment';
import DatePicker from '@/common/DatePicker.vue';
import Button from '@/common/Button.vue';
import TimeSelector from '@/common/TimeSelector.vue';
import VueCookies from 'vue-cookies';
import Vue from 'vue';
import constants from '@/constants';
import $scriptjs from 'scriptjs';

Vue.use(VueCookies);
export default {
  props: ['value'],
  emits: ['input', 'onSearchClicked'],
  name: 'FilterHeader',
  data() {
    return {
      options: [],
      items: [],
      currentLocation: {
        name: undefined,
        value: undefined, // [lat, lng]
      },
      filters: {
        query: undefined,
        search: {
          value: undefined,
          name: undefined,
          type: undefined,
        },
        date: this.getCurrentDate(),
        time: null,
        rating: '',
        sort: null,
        duration: '3,6,12',
        types: '',
      },
      stayingHours: ['3', '6', '12'],
    };
  },
  components: {
    DatePicker,
    Button,
    TimeSelector,
  },
  computed: {
    isHotelsPage() {
      return this.$route.path === '/hotels';
    },
  },
  async created() {
    if (isEmpty(this.$route.query)) {
      const cityModel = await this.getCity();
      this.currentLocation = {
        name: cityModel?.city?.names?.en || '',
        value: [cityModel?.location?.latitude, cityModel?.location?.longitude],
        type: 'Current Location',
      };
      this.items = [this.currentLocation];
      this.filters.query = this.currentLocation.name;
      this.filters.search = this.currentLocation;
    } else {
      const { query } = this.$route;
      this.items = [query.search];
      this.filters = _.merge(this.filters, query);
    }
  },
  methods: {
    getCurrentDate() {
      // Add 30 minutes to current time and update time to nearest 30 minute interval
      // const currentDate = new Date();
      // const updatedDate = new Date(currentDate.getTime() + 30 * 60000);
      // const remainder = 30 - (moment(updatedDate).minute() % 30);
      // return moment(updatedDate).add(remainder, 'minutes').format('YYYY-MM-DD');
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      const tomorrowFormatted = tomorrow;
      return moment(tomorrowFormatted).format('YYYY-MM-DD');
    },
    async fetchAutocompleteData(query) {
      const { data } = await this.$api.get(`${constants.API.HOTEL_NAME_SEARCH}/${query}`);
      const locations = map(get(data, 'address.predictions'), o => ({
        value: o.place_id,
        name: o.description,
        type: 'Location',
      }));
      if (locations.length > 0) {
        locations.unshift({ header: 'LOCATIONS' });
      }
      const hotels = map(get(data, 'hotelName'), o => ({
        value: o._id,
        name: o.name,
        type: 'Hotel',
      }));
      if (hotels.length > 0) {
        hotels.unshift({ header: 'HOTELS' });
      }
      this.items = concat(locations, hotels);
      // this.formatItems(locations, hotels);
    },
    submitForm() {
      datadogLogs.logger.info('web-query', this.filters);

      if (this.$route.name !== 'hotels') {
        this.$router.push({
          name: 'hotels',
          query: this.filters,
        });
      } else {
        this.$emit('onSearchClicked', this.filters);
      }
    },
    async getCity() {
      try {
        const { data } = await this.$api.get('set-up');
        $scriptjs('https://unpkg.com/country-to-currency', () => {
          Vue.$cookies.set('localeInfo', {
            timezone: moment.tz.guess(),
            country: data?.country?.isoCode || 'AU',
            currency: countryToCurrency[data?.country?.isoCode || 'AU'],
            countryNameVatgst: data?.country?.names?.en || '',
          });
        });
        return data;
      } catch (err) {
        return {};
      }
    },
  },
  watch: {
    'filters.query': debounce(function (query) {
      if (query && !map(this.items, 'name').includes(query)) {
        this.fetchAutocompleteData(query);
      }
    }, 300),
  },
};
</script>

<style lang="scss">
.show-datalist {
  display: block;
  position: absolute;
  background-color: #fff;
  width: auto;
  padding: 10px;
  z-index: 99;
  margin-top: -20px;
  border: 1px solid #ccc;
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
}

  .map-icon{
    font-size: 20px;
    margin-right: 10px;
  }

.show-datalist option {
  padding: 1px;
  margin: 2px;
  cursor: pointer;
}

.show-datalist option:hover {
  background-color: #ff4500;
  color: #fff;
}

.selected-list-item {
  background-color: #ff4500;
  color: #fff;
}

.search-hotels-home {
  width: 50%;
  top: 400px;
  left: 0;
  right: 0;
  z-index: 5;

  .filter-actions {
    align-items: center;
    background: none !important;
   // padding-top: 15px;
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.16);
    .search-container {
      background: #ffffff;
      padding: 10px 20px;
      max-width: 100%;
      border-radius: 4px;
      justify-content: space-between;
    }
  }

  .label-header {
    color: #ff4500;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 5px 0px;
  }

  .mr-8px {
    margin-right: 8px;
  }

  .hours-button {
    display: flex;
    align-items: center;
    gap: 5px;

    .hour-button {
      background: white;
      border: 2px solid #b7b9bd;
      box-sizing: border-box;
      border-radius: 4px;
      color: #a1a3a7;
      padding: 7px 17px;
    }

    .selected-hour-btn {
      background: #fef1eb !important;
      border: 2px solid #ff4500 !important;
      color: #ff4500;
    }
  }

  .nopad {
    background-color: #fff;
    height: 39px;
  }

  .vue__time-picker {
    width: 100% !important;

    input.display-time {
      height: 2.8em !important;
      width: 100% !important;
    }
  }
}

// responsiveness design
@media only screen and (max-width: 1263px) {
  .show-datalist {
    width: auto;
  }

  .filter-actions {
    padding: 12px !important;
  }

  .filter-mobile-cotainer {
    display: flex;
    flex-wrap: wrap;
    background: #b9b5b4;

    .search-button-container {
      margin-top: 12px;
    }
  }

  .search-hotels .mr-8px {
    margin-right: unset !important;
  }

  .search-hotels-home {
    top: 130px;
    bottom: 0px;
    height: 20%;
  }
}
@media only screen and (max-width: 768px) {
  .search-hotels-home{
    width: 100%;
    //margin: 5%;
  }
  .filter-actions {
    margin-top: 70px;
  }
}
</style>
<style scoped>
 ::v-deep .v-btn.v-size--default {
    text-transform: uppercase;
    font-size: 1rem;
  }
</style>
