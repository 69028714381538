const axios = require('axios');
const constants = require('@/constants');

module.exports = {
  getHomepageDetails: tagType => new Promise((resolve, reject) => {
    axios.post(constants.API.HOMEPAGE, { tag: tagType })
      .then((response) => {
        resolve(response);
      })
      .catch(err => reject(err));
  }),
  getHotelList: searchKey => new Promise((resolve, reject) => {
    axios.get(`${constants.API.HOTEL_NAME_SEARCH}/${searchKey}`)
      .then((response) => {
        resolve(response);
      })
      .catch(err => reject(err));
  }),
  getLocationLatLng: placeID => new Promise((resolve, reject) => {
    axios.get(`${constants.API.HOTEL_LAT_LNG_SEARCH}/${placeID}`)
      .then((response) => {
        resolve(response);
      })
      .catch(err => reject(err));
  }),
  getFAQs: () => new Promise((resolve, reject) => {
    axios.get(constants.API.FAQS)
      .then((response) => {
        resolve(response);
      })
      .catch(err => reject(err));
  }),
};
