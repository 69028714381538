<template>
	<div
		id="appleid-signin"
		data-mode="center-align"
		data-type="continue"
		data-color="white"
		data-border="true"
		data-border-radius="15"
		data-width="200"
		data-height="38"
	></div>
</template>

<script>
import $script from 'scriptjs';

export default {
  emits: ['handleCredential'],
  created() {
    $script(
      'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
      () => {
        AppleID.auth.init({
          clientId: 'com.timeflyz.web',
          scope: 'name email',
          redirectURI: `${window.location.origin}/login`,
          state: 'Initial user authentication request',
          nonce: 'nonce-2726c7f26c',
          usePopup: true,
        });
        document.addEventListener('AppleIDSignInOnSuccess', (event) => {
          this.$emit('handleCredential', event.detail.authorization.id_token);
        });
      },
    );
  },
};
</script>

<style>
</style>
