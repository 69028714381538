<template>
  <div :class="{ 'tabs-inactive': !isTabActive}">
    <v-tabs v-model="selectedTabIndex" height="56px" background-color="transparent">
      <v-tab v-for="tab of tabList" :key="tab" @click="emitTabChange(tab)">{{tab}}</v-tab>
    </v-tabs>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: ['tabList', 'activeTabIndex'],
  data() {
    return {
      selectedTabIndex: this.activeTabIndex,
      isTabActive: true,
    };
  },
  watch: {
    activeTabIndex(newValue) {
      this.selectedTabIndex = newValue;
      if (this.selectedTabIndex === -1) {
        this.isTabActive = false;
      } else {
        this.isTabActive = true;
      }
    },
  },
  methods: {
    emitTabChange(tab) {
      this.$emit('emitTabChange', tab);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tab {
  font-size: 16px;
  text-transform: unset;
  font-weight: unset;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab--active {
  font-weight: bold;
}
::v-deep .v-tabs-slider-wrapper {
  height: 3px !important;
}
.tabs-inactive {
  ::v-deep .v-tabs-slider-wrapper {
    display: none !important;
  }
  .theme--light.v-tabs > .v-tabs-bar .v-tab.v-tab--active {
    color: rgba(0, 0, 0, 0.54) !important;
    font-weight: unset;
  }
}
</style>
