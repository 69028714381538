<template>
  <v-footer absolute class="footer-section">
    <v-container class="width90">
      <v-flex align-center d-flex class="footer-top-section">
        <div>
          <v-img class="logo-image" width="145px" height="40px" src="@/assets/TimeFlyz.png" />
          <div class="margin-bottom-20-mobile" style="margin-left: 20px;">
            <span>info@timeflyz.co</span>
          </div>
        </div>
        <div class="app-logos margin-bottom-20-mobile">
          <a href="https://play.google.com/store/apps/details?id=co.timeflyz" target="_blank"><v-img class="logo pointer" src="@/assets/play_store_img.png" /></a>
          <a href="https://apps.apple.com/app/id1633408481" target="_blank"><v-img class="logo pointer" src="@/assets/app_store_img.png" /></a>
        </div>
        <div class="social-logos">
          <a href="https://www.facebook.com/timeflyzco" target="_blank"><v-icon class="social-icon pointer">mdi-facebook</v-icon></a>
          <a href="https://www.twitter.com/timeflyzco" target="_blank"><v-icon class="social-icon pointer">mdi-twitter</v-icon></a>
          <a href="https://www.instagram.com/timeflyzco" target="_blank"><v-icon class="social-icon pointer">mdi-instagram</v-icon></a>
          <a href="https://www.linkedin.com/company/timeflyz" target="_blank"><v-icon class="social-icon pointer">mdi-linkedin</v-icon></a>
        </div>
      </v-flex>
      <div class="border-bottom"></div>
      <v-flex align-center d-flex class="copyright-section">
        <div class="copy-right margin-bottom-20-mobile">
          Copyright © {{new Date().getFullYear()}} Timeflyz. All rights reserved.
        </div>
        <div class="privacy-policy">
          <div class="terms pointer" @click="goToFrequentlyAskedQuestions()">
            Frequently asked questions
          </div>
          <div class="terms pointer" @click="goToTermsAndConditions()">Terms and conditions</div>
          <div class="privacy pointer" @click="goToPrivacyPolicy()">Privacy policy</div>
        </div>
      </v-flex>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  methods: {
    goToTermsAndConditions() {
      window.open(' https://timeflyz.co/terms-of-use/', '_self');
    },
    goToPrivacyPolicy() {
      window.open('https://timeflyz.co/privacy-policy/', '_self');
    },
    goToFrequentlyAskedQuestions() {
      window.open('https://timeflyz.co/faq/', '_self');
    },
  },
};
</script>

<style lang="scss">
.footer-section {
  background-color: #FFFFFF !important;
  padding: 3rem 30px !important;
  // min-width: 1200px !important; // Remove when working on responsiveness
  position: unset !important;
  .container-fluid {
    margin: 0 auto;
    // max-width: 1200px;
  }
  .footer-top-section {
    justify-content: space-between;
  }
  .app-logos {
    display: flex;
    align-items: center;
    .logo {
      margin-right: 10px;
      border-radius: 10px;
    }
  }
  .social-logos {
    display: flex;
    align-items: center;
    .social-icon {
      margin-right: 30px;
    }
    .social-icon:hover {
      color: $primary-color !important;
    }
  }
  .border-bottom {
    background: #DCDDE6;
    height: 1px;
    widows: 100%;
    margin: 40px 0;
  }
  .copyright-section {
    justify-content: space-between;
    color: rgba(8, 9, 20, 0.7);
    font-size: 16px;
    line-height: 20px;
    .privacy-policy {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      .terms {
        margin-right: 20px;
      }
      .terms:hover {
        color: $primary-color !important;
      }
      .privacy:hover {
        color: $primary-color !important;
      }
    }
  }
  .width90 {
    width: 100%;
  }
  a {
    text-decoration: none;
  }
}

// responsiveness design
@media only screen and (max-width: 768px) {
  .copyright-section, .footer-top-section {
    display: flex;
    flex-direction: column;
  }
  .margin-bottom-20-mobile {
    margin-bottom: 20px;
  }
  .logo-image {
    margin: 0 auto 20px auto;
  }
}
</style>
