<template>
  <v-card class="table-card-container" v-show="tableItems.length > 0">
    <v-data-table
      :mobile-breakpoint="1025"
      :items-per-page="tableItems.length"
      :headers="tableHeaders"
      :items="tableItems"
      hide-default-footer
    >
      <template v-slot:item.button="{ item }">
        <div style="display: flex; justify-content:space-evenly; align-items: center;">
          <Button
            v-if="mode === 'reservation'"
            type="link"
            value="View"
            @buttonClicked="goToViewBooking(item)"
          />
          <Button
            v-if="mode === 'reservation' && !item.reviewed && tableName === 'past'"
            type="outlined"
            value="Review"
            color="#FF4500"
            @buttonClicked="reviewPopUp(item)"
          />
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <div class="table-status">{{item.status}}</div>
      </template>
      <template v-slot:item.duration="{ item }">
        <span>{{item.duration}}h</span>
      </template>
      <template v-slot:item.userComment="{ item }">
        <div v-if="item.userComment">
          <v-icon>mdi-message</v-icon>Yes
        </div>
        <div class="empty-item" v-if="!item.userComment"></div>
      </template>
      <template v-slot:item.amount="{ item }">
        <span>{{item.charge.userVatgst.shortName}}{{parseFloat(item.charge.total * item.charge.userExchangeRate).toFixed(2)}}</span>
      </template>
      <template v-slot:item.balance="{ item }">
        <span>{{item.charge.userVatgst.shortName}}{{Math.round(item.balance)}}</span>
      </template>
      <template v-slot:item.paid="{ item }">
        <span>{{item.charge.userVatgst.shortName}}{{Math.round(item.paid)}}</span>
      </template>
      <template v-slot:item.overnightRate="{ item }">
        <span>{{item.charge.userVatgst.shortName}}{{Math.round(item.overnightRate)}}</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';
import * as lodash from 'lodash';
import Button from './Button.vue';

export default {
  name: 'Table',
  props: [
    'tableHeaders',
    'tableItems',
    'tableName',
    'passedSearchValue',
    'passedDate',
    'hotelId',
    'mode',
  ],
  components: {
    Button,
  },
  methods: {
    ...mapMutations(['setBooking']),
    goToViewBooking(booking) {
      this.setBooking(lodash.cloneDeep(booking));
      this.$router.push({ path: '/view-booking' });
    },
    reviewPopUp(booking) {
      this.$emit('openReviewPopUp', booking);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-card-container {
  .table-status {
    box-sizing: border-box;
    border: 1px solid #c4c5cc;
    padding: 3px;
    border-radius: 2px;
    text-align: center;
    font-size: 11px;
    font-weight: bold;
  }
  .empty-item {
    border: 1px solid #0a091a;
    width: 17px;
  }
  .v-card__title {
    padding: 5px 16px;
    font-size: 16px;
    font-weight: bold;
  }
  ::v-deep .v-data-table th {
    height: 25px;
    background-color: #ebecf2;
  }
  .theme--light.v-data-table,
  ::v-deep .theme--light.v-data-table thead tr th {
    color: #0a091a;
  }
  ::v-deep .v-data-table th {
    padding: 4px 0 0 16px !important;
  }

  ::v-deep .v-data-table td:nth-last-child(1) {
    padding-right: 16px !important;
  }

  ::v-deep
    .theme--light.v-data-table
    tbody
    tr:hover:not(.v-data-table__expand-row) {
    background: transparent;
  }

  .v-data-table .v-icon {
    font-size: 16px;
    color: #0a091a;
  }

  ::v-deep .optimise-button .link-button:hover {
    background: $primary-color !important;
    color: white;
    opacity: 1;
    border-radius: 6px;
  }

  .small-text-field {
    width: 55px;
    ::v-deep
      .v-text-field.v-text-field--enclosed
      > .v-input__control
      > .v-input__slot {
      padding: 0 6px;
    }
    ::v-deep .v-text-field__details {
      display: none;
    }
    ::v-deep .v-text-field .v-input__slot {
      min-height: 32px !important;
    }
    ::v-deep
      .v-text-field.v-text-field--enclosed.v-text-field--single-line
      .v-text-field__prefix {
      margin-top: 2px;
    }
  }

  .table-header-button {
    display: flex;
    margin: 7px 16px;
    height: 40px;
    align-items: center;
    justify-content: center;
    ::v-deep .v-icon {
      font-size: 15px !important;
    }
    .cancle-button {
      margin-right: 1rem;
    }
  }
}

// responsiveness design
@media only screen and (max-width: 1025px) {
  ::v-deep .v-data-table-header-mobile {
    display: none;
  }
  .border-bottom-title {
    border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  }
}
</style>
<style scoped>
 ::v-deep .v-btn.v-size--default {
    font-size: 1rem;
    text-transform: uppercase;
  }
</style>
