<template>
  <div>
    <span v-if="label">{{label}}</span>
    <v-select
      :placeholder="placeholder"
      @input="emitSelectValue()"
      v-model="selectValue"
      :items="items"
      :rules="rules"
      :required="required"
      :disabled="disabled"
      :no-data-text="noDataText"
      outlined
    ></v-select>
  </div>
</template>

<script>
export default {
  name: 'Select',
  data() {
    return {
      selectValue: this.value,
    };
  },
  watch: {
    value(val) {
      this.selectValue = val;
    },
  },
  props: [
    'placeholder',
    'items',
    'value',
    'label',
    'rules',
    'required',
    'disabled',
    'noDataText',
  ],
  methods: {
    emitSelectValue() {
      this.$emit('emitSelectValue', this.selectValue, this.label);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
</style>
