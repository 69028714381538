<template>
    <div class="form-outline" v-bind:style= "[isToken ? {'margin-top': '0'} : {'margin-top': '5%'}]">
        <v-card class="remove-shadow">
            <v-card-title class="header-title">{{header}}</v-card-title>
            <v-flex class="social-media-container" v-if="header === 'Login'">
                <GoogleSignin @handleCredential="handleCredentialResponse" />
                <!-- <VFBLoginScope class="margin-top-1" :app-id="facebookAppId" @login="facebookLogin">
                    <button class="facebook-button" slot-scope="scope" @click="scope.login">
                        <v-icon class="facebook-icon" color="white">mdi-facebook</v-icon>Continue with Facebook
                    </button>
                </VFBLoginScope> -->
                <AppleSignin @handleCredential="handleCredentialAppleResponse" class="margin-top-1"/>
                <div class="login-with-email">
                    Or login with your email:
                </div>
            </v-flex>
            <v-card class="mx-auto card-color" v-if="header === 'Login'">
                <v-form
                    ref="loginForm"
                    v-if="header === 'Login'"
                    class="text-field-container"
                    @submit.prevent="emitLogin"
                >
                    <div class="mb-2">
                        <span>Email *</span>
                    </div>
                    <TextField
                        @emitTextValue="getEmailValue"
                        label
                        placeholder="E.g. hello@timeflyz.com"
                        :rules="emailRules"
                        :error="error"
                    />
                    <div class="password mb-2">
                        <span>Password *</span>
                    </div>
                    <div>
                        <v-text-field
                            v-model="password"
                            placeholder="Password"
                            single-line
                            outlined
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show ? 'text' : 'password'"
                            @click:append="show = !show"
                            :rules="[v => v.length > 0 || '*Required*']"
                            :error="error"
                            :error-messages="errorMessages"
                        ></v-text-field>
                    </div>
                    <div class="password mb-2">
                        <span @click="routeToResetPasswordPage()" class="forgot-password">Forgot your password?</span>
                    </div>
                    <v-col>
                        <Button
                            type="submit"
                            value="Log in"
                            color="primary"
                            height="40px"
                            width="100%"
                            :disabled="disabled"
                        />
                        <v-row justify="center" class="dont-have-account">
                            <hr/>
                            <span>You don't have an account yet?</span>
                            <hr/>
                        </v-row>
                        <Button
                            type="button"
                            @buttonClicked="goToSignUp"
                            value="Sign up"
                            width="100%"
                        />
                    </v-col>
                </v-form>
            </v-card>
            <v-card
                min-width="370"
                min-height="554"
                class="mx-auto card-color"
                v-if="header === 'Reset Password'"
            >
                <v-form
                    ref="resetForm"
                    v-if="header === 'Reset Password'"
                    class="text-field-container"
                    @submit.prevent="emitResetPassword"
                >
                    <TextField
                        @emitTextValue="getEmailValue"
                        label="Email address"
                        placeholder="E.g. hello@timeflyz.com"
                        :rules="emailRules"
                        :error="error"
                        :error-messages="errorMessages"
                    />
                    <v-col class="margin-top-1">
                        <Button
                            type="submit"
                            value="Reset password"
                            color="primary"
                            height="40px"
                            width="100%"
                            :disabled="disabledReset"
                        />
                        <v-row justify="center">
                            <Button class="margin-top-1" type="link" @buttonClicked="goToLogin" value="Cancel" />
                        </v-row>
                    </v-col>
                </v-form>
            </v-card>
        </v-card>
        <div v-if="openDialog">
            <v-dialog v-model="openDialog" max-width="370px">
                <Dialog :dialogData="loginError" :onCloseDialog="closeDialog" mode="snackbar-error" />
            </v-dialog>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { SNACKBAR_TIMEOUT, EMAIL_PATTERN } from '@/constants';
import validation from '@/validations';
import Dialog from '@/common/ErrorDialog.vue';
import Button from './Button.vue';
import TextField from './TextField.vue';
import GoogleSignin from '../components/SSO/GoogleSignin.vue';
import AppleSignin from '../components/SSO/AppleSignin.vue';

export default {
  name: 'LoginForm',
  props: ['header', 'error', 'errorMessages'],
  components: {
    Button,
    TextField,
    GoogleSignin,
    AppleSignin,
    Dialog,
  },
  data() {
    return {
      show: false,
      email: '',
      password: '',
      snackbar: false,
      openDialog: false,
      isEmailValid: false,
      timeout: SNACKBAR_TIMEOUT,
      emailRules: [
        validation.FORM_VALIDATION.required,
        validation.FORM_VALIDATION.email,
      ],
      //  TODO: For reference
      // renderParams: {
      //   width: 370,
      //   height: 50,
      //   longtitle: true,
      // },
      isToken: false,
    };
  },
  computed: {
    ...mapState({
      loginError: state => state.user.error,
    }),
    disabled() {
      if (this.email && this.password && this.isEmailValid) {
        return false;
      }
      return true;
    },
    disabledReset() {
      if (this.email && EMAIL_PATTERN.test(this.email)) {
        return false;
      }
      return true;
    },
    facebookAppId() {
      return process.env.VUE_APP_FACEBOOK_APP_ID;
    },
  },
  watch: {
    $route() {
      this.setHeaderAccess();
    },
    loginError() {
      if (this.loginError !== null) {
        this.snackbar = true;
        this.openDialog = true;
      }
      // setTimeout(() => this.resetError(), SNACKBAR_TIMEOUT);
    },
    email() {
      if (EMAIL_PATTERN.test(this.email)) {
        this.isEmailValid = true;
      } else {
        this.isEmailValid = false;
      }
    },
  },
  methods: {
    ...mapActions(['resetUserError', 'setLoginError']),
    handleCredentialResponse(credential) {
      this.emitGoogleLogin(credential);
    },
    handleCredentialAppleResponse(credential) {
      this.emitAppleLogin(credential);
    },
    facebookLogin(facebookUser) {
      this.emitFacebookLogin(facebookUser.authResponse.accessToken);
    },
    onFailure() {
      this.setLoginError();
    },
    goToSignUp() {
      if (this.$route.path === '/book-hotel/confirm') {
        this.$emit('switchToSignup');
      } else {
        this.$router.push({ path: '/signup' });
      }
    },
    goToLogin() {
      this.$router.push({ path: '/login' });
    },
    routeToResetPasswordPage() {
      this.$router.push({ path: '/reset-password' });
    },
    emitLogin() {
      this.$emit('emitLogin', this.email, this.password);
    },
    emitGoogleLogin(token) {
      this.$emit('emitGoogleLogin', token);
    },
    emitFacebookLogin(token) {
      this.$emit('emitFacebookLogin', token);
    },
    emitAppleLogin(token) {
      this.$emit('emitAppleLogin', token);
    },
    emitResetPassword() {
      this.$emit('emitResetPassword', this.email);
    },
    getEmailValue(value) {
      this.email = value;
    },
    resetError() {
      this.snackbar = false;
      this.resetUserError();
    },
    closeDialog() {
      this.snackbar = false;
      this.openDialog = false;
      this.resetUserError();
    },
    forgotPassword() {
      this.$router.push('/reset-password');
    },
    setHeaderAccess() {
      const accessToken = Vue.$cookies.get('token');
      if (accessToken) {
        this.isToken = true;
      } else {
        this.isToken = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
    .form-outline {
        display: flex;
        justify-content: center;
        align-items: center;
        // Styles to customise google login button
        ::v-deep .abcRioButtonContents {
            margin: unset !important;
        }
        ::v-deep .abcRioButtonContentWrapper {
            display: flex;
            justify-content: flex-start;
        }
        .social-media-container {
            padding-top: 30px;
            display: flex;
            align-items: center;
            flex-direction: column;
        }
        .card-color {
            background: white;
            box-shadow: none;
            border-top-left-radius: unset !important;
            border-top-right-radius: unset !important;
        }
        .text-field-container {
            padding: 24px;
            font-size: 16px;
            .password {
                display: flex;
                justify-content: space-between;
            }
            .forgot-password {
                color: #ff4500;
                cursor: pointer;
                font-size: 14px;
                font-weight: bold;
            }
        }
        .v-card__title {
            padding: 17px 24px;
            font-size: 18px;
            font-weight: bold;
        }
        .margin-top-1 {
            margin-top: 1rem;
        }
        .remove-shadow {
            box-shadow: none;
        }
        .facebook-button {
            width: 199px;
            height: 38px;
            color: #fff;
            font-size: 14px;
            border-radius: 3px;
            background-color: #3c57a4;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: sans-serif;
            margin-bottom: 10px;
            .facebook-icon {
                margin-right: 5px;
            }
        }
        .google-button {
            min-width: 15rem;
            width: 100%;
            color: #000;
            font-size: 18px;
            font-weight: bold;
            box-sizing: border-box;
            box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 0px;
            padding: 1rem;
            border-radius: 0.25rem;
            display: flex;
            justify-content: center;
            font-family: sans-serif;
            .google-icon {
                margin-right: 2rem;
            }
        }
        .header-title {
            color: #ff4500;
            justify-content: center;
            font-size: 24px;
            font-weight: bold;
        }
        .login-with-email {
            font-size: 14px;
            font-weight: 600;
            margin-top: 20px;
        }
        .dont-have-account {
            margin: 20px 0 !important;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            hr {
                width: 30px;
            }
        }
    }
</style>
