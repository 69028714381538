<template>
  <div class="banner-background">
    <div class="overlay">
      <div class="banner-text">
        <div v-html="searchTitle" v-bind:style= "[isMobile ? {'margin-top': '20%'} : isToken ? {'margin-top': '0px'}: {'margin-top': '20%'}]"></div>
      </div>
    </div>
    <Carousel :photos="imageArray" :showArrows="false" />
  </div>
</template>

<script>
import Carousel from '@/common/Carousel.vue';

export default ({
  name: 'Banner',
  props: ['searchTitle', 'imageArray', 'searchHotels'],
  data() {
    return {
      bannerImages: [],
      isMobile: false,
      isToken: false,
    };
  },
  watch: {
    $route() {
      this.setHeaderAccess();
    },
  },
  components: {
    Carousel,
  },
  methods: {
    setHeaderAccess() {
      const accessToken = Vue.$cookies.get('token');
      if (accessToken) {
        this.isToken = true;
      } else {
        this.isToken = false;
      }
      this.isMobile = window.innerWidth <= 768;
    },
  },
});
</script>

<style scoped lang="scss">
.overlay {
  position: absolute;
  width: 100%;
  height: 600px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  z-index: 1;
}
.banner-background {
  position: relative;
  height: 600px;
}
.banner-text {
  font-size: 55px;
  margin-top: 25px;
  color: white;
  font-weight: bold;
  width: 650px;
  text-align: center;
}

.padding {
  padding-top: 60px;
}

@media only screen and (max-width: 1265px) {
  .banner-text {
    font-size: 24px !important;
  }
}
</style>
