import Vue from 'vue';
import Vuetify from 'vuetify';
import * as VueGoogleMaps from 'vue2-google-maps';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import forEach from 'lodash/forEach';
import VueMeta from 'vue-meta';
import VueTelInput from 'vue-tel-input';
import { datadogLogs } from '@datadog/browser-logs';
/* font awesome */
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faInstagram, faLinkedin, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

import App from './App.vue';
import router from './router';
import store from './store';
import axios from './axios';
import vuetify from './plugins/vuetify';
import Common from './common';
import '@mdi/font/css/materialdesignicons.css';
import priceFilter from './filters/price.filter';

datadogLogs.init({
  clientToken: process.env.VUE_APP_DD_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'booking-portal',
  forwardErrorsToLogs: false,
  sessionSampleRate: 100,
  env: process.env.NODE_ENV,
});

datadogLogs.init({
  clientToken: process.env.VUE_APP_DD_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'booking-portal',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  env: process.env.NODE_ENV,
});

Vue.config.productionTip = false;
Vue.prototype.$api = axios;

Vue.use(Vuetify, {
  iconfont: 'mdi',
});

Vue.use(priceFilter);

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: 'places', // necessary for places input
  },
});

Vue.use(VueMeta);

Vue.use(VueTelInput, {
  inputOptions: {
    showDialCode: true,
  },
  autoFormat: true,
  dropdownOptions: {
    showDialCodeInList: false,
    showDialCodeInSelection: false,
    showSearchBox: true,
  },
  validCharactersOnly: true,
});

if (process.env.NODE_ENV === 'production') {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
  });

  const bugsnagVue = Bugsnag.getPlugin('vue');
  bugsnagVue.installVueErrorHandler(Vue);
}

/**
 * Register Global UI components
 */
forEach(Common, (value, key) => {
  Vue.component(key, value);
});

/**
 * Register Font Awesome
 */
library.add(faInstagram, faFacebookF, faLinkedin, faCircle);
Vue.component('font-awesome-icon', FontAwesomeIcon);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
