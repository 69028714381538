<template>
  <v-container class="search-hotels-home-header">
    <div class="filter-actions-header">
      <form @submit.prevent="submitForm(true)">
        <div class="search-container-header filter-mobile-cotainer-header">
           <v-row no-gutters  style="align-items: center;">
      <v-col cols="this.$route.name === 'hotels' ? '4': '5'">
                  <div class="d-flex"
                  style="height: 40px;">
            <v-icon class="map-icon" color="#ff4500">mdi-map-marker</v-icon>
            <v-autocomplete no-filter
                            v-model="filters.search"
                            :search-input.sync="filters.query"
                            :auto-select-first="true"
                            solo
                            :multiple="false"
                            :required="true"
                            placeholder="Search by hotel name / destination..."
                            :items="items"
                            item-text="name"
                            return-object>
            </v-autocomplete>
          </div>
        </v-col>
              <v-col cols="4">
            <div class="d-flex"   style="margin-left: 10px">
            <v-icon class="map-icon" color="#ff4500"> mdi-calendar</v-icon>
            <DatePicker v-model="filters.date" />
          </div>
        </v-col>
              <v-col cols="2">
            <Button type="submit"
                    value="Search"
                    color="primary"
                    height="35px"
                    width="100px" />
        </v-col>
        <!-- <v-col cols="1" v-if="this.$route.name === 'hotels'">
          <div class="maplist_header" @click="toggleMap">
            <v-icon color="#ff4500" class="mr-2" v-if="this.mapShowStatus" > mdi-format-list-bulleted-square </v-icon>
             <v-icon color="#ff4500" v-else
                class="mr-2">
           mdi-map-search-outline
        </v-icon>
        </div>

        </v-col> -->

        </v-row>
        </div>
      </form>
    </div>
  </v-container>
</template>

<script>
import { datadogLogs } from '@datadog/browser-logs';
import { mapGetters, mapActions } from 'vuex';
import {
  get, map, isEmpty, debounce, concat,
} from 'lodash';
import moment from 'moment';
import DatePicker from '@/common/DatePicker.vue';
import Button from '@/common/Button.vue';
import TimeSelector from '@/common/TimeSelector.vue';
import VueCookies from 'vue-cookies';
import Vue from 'vue';
import constants from '@/constants';
import $scriptjs from 'scriptjs';

Vue.use(VueCookies);
export default {
  props: ['value'],
  emits: ['input', 'onSearchClicked'],
  name: 'FilterHeader',
  data() {
    return {
      options: [],
      items: [],
      currentLocation: {
        name: undefined,
        value: undefined, // [lat, lng]
      },
      filters: {
        query: undefined,
        search: {
          value: undefined,
          name: undefined,
          type: undefined,
        },
        date: this.getCurrentDate(),
        time: null,
        rating: '',
        sort: null,
        duration: '3,6,12',
        types: '',
      },
      stayingHours: ['3', '6', '12'],
    };
  },
  components: {
    DatePicker,
    Button,
    TimeSelector,
  },
  computed: {
    ...mapGetters(['mapShowStatus']),
    isHotelsPage() {
      return this.$route.path === '/hotels';
    },
  },
  async created() {
    if (isEmpty(this.$route.query)) {
      const cityModel = await this.getCity();
      this.currentLocation = {
        name: cityModel?.city?.names?.en || '',
        value: [cityModel?.location?.latitude, cityModel?.location?.longitude],
        type: 'Current Location',
      };
      this.items = [this.currentLocation];
      this.filters.query = this.currentLocation.name;
      this.filters.search = this.currentLocation;
    } else {
      const { query } = this.$route;
      this.items = [query.search];
      this.filters = _.merge(this.filters, query);
    }
  },
  methods: {
    ...mapActions(['setMap']),
    getCurrentDate() {
      // Add 30 minutes to current time and update time to nearest 30 minute interval
      // const currentDate = new Date();
      // const updatedDate = new Date(currentDate.getTime() + 30 * 60000);
      // const remainder = 30 - (moment(updatedDate).minute() % 30);
      // return moment(updatedDate).add(remainder, 'minutes').format('YYYY-MM-DD');
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      const tomorrowFormatted = tomorrow;
      return moment(tomorrowFormatted).format('YYYY-MM-DD');
    },
    async fetchAutocompleteData(query) {
      const { data } = await this.$api.get(`${constants.API.HOTEL_NAME_SEARCH}/${query}`);
      const locations = map(get(data, 'address.predictions'), o => ({
        value: o.place_id,
        name: o.description,
        type: 'Location',
      }));
      if (locations.length > 0) {
        locations.unshift({ header: 'LOCATIONS' });
      }
      const hotels = map(get(data, 'hotelName'), o => ({
        value: o._id,
        name: o.name,
        type: 'Hotel',
      }));
      if (hotels.length > 0) {
        hotels.unshift({ header: 'HOTELS' });
      }
      this.items = concat(locations, hotels);
      // this.formatItems(locations, hotels);
    },
    submitForm() {
      datadogLogs.logger.info('web-query', this.filters);

      if (this.$route.name !== 'hotels') {
        this.$router.push({
          name: 'hotels',
          query: this.filters,
        });
      } else {
        this.$emit('onSearchClicked', this.filters);
      }
    },
    async getCity() {
      try {
        const { data } = await this.$api.get('set-up');
        $scriptjs('https://unpkg.com/country-to-currency', () => {
          Vue.$cookies.set('localeInfo', {
            timezone: moment.tz.guess(),
            country: data?.country?.isoCode || 'AU',
            currency: countryToCurrency[data?.country?.isoCode || 'AU'],
            countryNameVatgst: data?.country?.names?.en || '',
          });
        });
        return data;
      } catch (err) {
        return {};
      }
    },
    toggleMap() {
      this.setMap();
    },
  },
  watch: {
    'filters.query': debounce(function (query) {
      if (query && !map(this.items, 'name').includes(query)) {
        this.fetchAutocompleteData(query);
      }
    }, 300),
  },
};
</script>

<style lang="scss">
.show-datalist {
  display: block;
  position: absolute;
  background-color: #fff;
  width: auto;
  padding: 10px;
  z-index: 99;
  margin-top: -20px;
  border: 1px solid #ccc;
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
}

  .map-icon{
    font-size: 20px;
    margin-right: 10px;
  }

.show-datalist option {
  padding: 1px;
  margin: 2px;
  cursor: pointer;
}

.show-datalist option:hover {
  background-color: #ff4500;
  color: #fff;
}

.selected-list-item {
  background-color: #ff4500;
  color: #fff;
}

.search-hotels-home-header {
  width: 100%;
  //background: #a1a3a7;

  .filter-actions-header {
    align-items: center;
    background: none !important;
   // padding-top: 15px;
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.16);
    .search-container-header {
      background: #ffffff;
      padding: 10px;
      max-width: 100%;
      border-radius: 4px;
      justify-content: space-between;
    }
  }

  .label-header-header {
    color: #ff4500;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    //margin: 5px 0px;
  }

  .mr-8px {
    margin-right: 8px;
  }

  .nopad {
    background-color: #fff;
    height: 39px;
  }

  .vue__time-picker {
    width: 100% !important;

    input.display-time {
      height: 2.8em !important;
      width: 100% !important;
    }
  }
}

.maplist_header {
  margin-left: 10px;
  // background: #b9b5b4;
  padding: 7px 3px 7px 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  // box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.16);
}

// responsiveness design
@media only screen and (max-width: 1263px) {
  .show-datalist {
    width: auto;
  }

  .filter-actions {
    padding: 12px !important;
  }

  .filter-mobile-cotainer-header {
    display: flex;
    flex-wrap: wrap;
    background: #b9b5b4;

    .search-button-container {
      margin-top: 12px;
    }
  }

  .search-hotels .mr-8px {
    margin-right: unset !important;
  }

  .search-hotels-home {
    top: 130px;
    bottom: 0px;
    height: 20%;
  }
}
@media only screen and (max-width: 768px) {
  .search-hotels-home{
    width: 100%;
    margin-top: 20px;
  }
}
</style>
<style scoped>
 ::v-deep .v-btn.v-size--default {
    text-transform: uppercase;
    font-size: 1rem;
  }
</style>
