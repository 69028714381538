<template>
  <div>
    <v-app-bar app flat fixed elevation="0" class="header-container" style="background: transparent; height: 90px;" width="100%" v-bind:style= "[isMobile && this.$route.name === 'home' ? { height: '0px', 'background-color': ''} : hasAccess? { height: '90px', background: '#ddd'}: { height: '0px', 'background-color': '#fff', position: 'sticky'}]" >
      <v-container class="header-outer-container main-container" style="margin-top: 15px;">
        <div class="image-container" >
          <div class="mobile-header" v-if="isMobile">
            <div class="mobile-header-menu">
              <Menu
                class="outer-menu"
                type="icon"
                icon="mdi-menu"
                :items="this.hasAccess && !isMobile ? items : menuItems"
                :itemClick="this.hasAccess && itemClick"
                :selectedTabIndex="this.hasAccess && getSelectedItemsIndex()"
              />
            </div>
            <div @click="wordPressSite()">
            <v-img v-if="this.$route.name === 'home'" width="150px" height="40px" src="@/assets/logo-white.png" class="mobile-header-logo"></v-img>
            <v-img v-else width="150px" height="40px" src="@/assets/TimeFlyz.png" class="mobile-header-logo"></v-img>
            </div>
              <Button
                type="flat"
                v-if="!hasAccess"
                @buttonClicked="navigateToLogin"
                class="login-btn"
                value="Login"
                color="primary"
                icon="mdi-account"
                rounded
                btnStyle="text-transform: capitalize !important;"
              />
              <div class="otherLinks-Mobile-menu">
              <Menu
                v-if="hasAccess && isMobile"
                class="menu-right"
                type="icon"
                icon="mdi-account-circle"
                :items="this.hasAccess && items"
                :itemClick="this.hasAccess && itemClick"
                :selectedTabIndex="this.hasAccess && getSelectedItemsIndex()"
                style="margin-left: 60px;"
              />
            </div>
          </div>
          <v-toolbar-title class="header-logo" @click="wordPressSite()" v-else >
            <v-img width="145px" height="40px" src="@/assets/TimeFlyz.png"></v-img>
          </v-toolbar-title>

          <Menu
            class="header-menu"
            v-if="hasAccess && !guestProfile"
            type="icon"
            icon="mdi-dots-vertical"
            :items="items"
            :itemClick="itemClick"
            :selectedTabIndex="getSelectedItemsIndex()"
          />
        </div>
        <div class="header-filter" v-if="
            (this.$route.name === 'hotels' ||
            this.$route.name === 'viewHotel' ||
            this.$route.name === 'bookings' || this.$route.name === 'favourites' || this.$route.name.includes('account'))
          ">
           <HotelFilterHeader @onSearchClicked="onSearchClicked" />
        </div>
        <div
          :class="{
            'justify-end': !hasAccess,
            'justify-end': hasAccess && !guestProfile,
            homeHeader: true,
          }"
          class="action-content"
        >
          <div v-if="hasAccess && !guestProfile" class="action-content-tab">
            <Tabs
              :activeTabIndex="getSelectedTabIndex()"
              :tabList="tabList"
              @emitTabChange="changeTab"
            />
          </div>
          <div class="header-cta-wrapper" v-if="!hasAccess">
            <!-- <div @click="navigateToAdminSignup" class="hotel-signup pointer otherLinks-Mobile">
              Sign up as a Hotel
            </div> -->
            <div class="otherLinks-Web">
              <ul class="header-cta-list">
                <li>
                  <router-link to="/signup">Sign up</router-link>
                </li>
                <li>
                  <router-link to="/login">Login</router-link>
                </li>
              </ul>
            </div>
            <div class="otherLinks-Mobile">
              <Menu
                class="outer-menu"
                type="icon"
                icon="mdi-menu"
                :items="menuItems"
                :itemClick="menuItemClick"
                :selectedTabIndex="getSelectedMenuItemsIndex()"
              />
            </div>
          </div>
          <Menu
            class="outer-menu outer-menu-1"
            v-if="hasAccess && !guestProfile"
            type="icon"
            :icon="$vuetify.breakpoint.mobile ? 'mdi-menu' : 'mdi-dots-vertical'"
            :items="items"
            :itemClick="itemClick"
            :selectedTabIndex="getSelectedItemsIndex()"
          />
        </div>
        <div
          :class="{
            'justify-end': !hasAccess,
            'justify-end': hasAccess && !guestProfile,
          }"
          class="action-content"
        >
          <div v-if="hasAccess && !guestProfile" class="action-content-tab">
            <Tabs
              :activeTabIndex="getSelectedTabIndex()"
              :tabList="tabList"
              @emitTabChange="changeTab"
            />
          </div>
          <div class="header-cta-wrapper" v-if="!hasAccess">
            <div class="web-menu">
              <!-- <ul class="header-cta-list">
                <li>
                  <a href="https://timeflyz.co/">Home</a>
                </li>
                <li>
                  <a href="https://timeflyz.co/about-us/">About Us</a>
                </li>
                <li>
                  <a href="https://timeflyz.co/blog/">Blog</a>
                </li>
                <li>
                  <a href="https://timeflyz.co/faq/">FAQ</a>
                </li>
                <li>
                  <a href="https://timeflyz.co/list-your-hotel/">List Your Hotel</a>
                </li>
                <li>
                  <a href="https://timeflyz.co/contact-us/">Contact Us</a>
                </li>
              </ul> -->
              <Button
                type="flat"
                @buttonClicked="navigateToLogin"
                class="login-btn"
                :class="{
                    'login-btn': true,
                    homeHeader: this.$route.name === 'login',
                }"
                value="Login"
                color="primary"
                icon="mdi-account"
                rounded
                btnStyle="text-transform: capitalize !important;"
              />
            </div>
            <div class="mobile-menu" v-if="!isMobile">
              <Menu
                class="outer-menu"
                type="icon"
                icon="mdi-menu"
                :items="menuItems"
                :itemClick="menuItemClick"
              />
            </div>
          </div>
          <Menu
            class="outer-menu outer-menu-1"
            v-if="hasAccess && !guestProfile && !isMobile"
            type="icon"
            :icon="$vuetify.breakpoint.mobile ? 'mdi-menu' : 'mdi-dots-vertical'"
            :items="items"
            :itemClick="itemClick"
            :selectedTabIndex="getSelectedItemsIndex()"
          />
        </div>
      </v-container>
    </v-app-bar>
    <div v-if="openDialog">
      <v-dialog v-model="openDialog" max-width="370px">
        <Dialog
          :dialogData="alertMessage"
          :onDialogResponse="logOut"
          :onCloseDialog="closeDialog"
          mode="alert"
          title="Log out?"
          alertActionMessage="Log out"
        />
      </v-dialog>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import { datadogLogs } from '@datadog/browser-logs';
import Tabs from '@/common/Tabs.vue';
import Menu from '@/common/Menu.vue';
import Dialog from '@/common/Dialog.vue';
import eventBus from '@/eventBus';
import $scriptjs from 'scriptjs';
import HotelFilterHeader from '@/components/SearchHotels/HotelFilterHeader.vue';

const { getLoggedInUserDetailService } = require('@/services/user/user.service');

Vue.use(VueCookies);

export default {
  name: 'Header',
  data() {
    return {
      tabList: ['Home', 'Bookings', 'Favourites'],
      hasAccess: true,
      menuItems: [
        { title: 'About Us', path: 'https://timeflyz.co/about-us/' },
        { title: 'Blog', path: 'https://timeflyz.co/blog/' },
        { title: 'Faq', path: 'https://timeflyz.co/faq/' },
        { title: 'List Your Hotel', path: 'https://timeflyz.co/list-your-hotel/' },
        { title: 'Contact Us', path: 'https://timeflyz.co/contact-us/' },
      ],
      openDialog: false,
      alertMessage:
        'If you log out, you will need to log back in. Are you sure you want to log out?',
      guestProfile: false,
      items: this.$vuetify.breakpoint.mobile
        ? [
          { title: 'Home', icon: 'mdi-home', path: 'https://timeflyz.co/' },
          { title: 'Bookings', icon: 'mdi-list-box', path: '/bookings' },
          { title: 'Favourites', icon: 'mdi-heart', path: '/favourites' },
          {
            title: 'Personal Details',
            icon: 'mdi-account-circle',
            path: '/account/personaldetails',
          },
          {
            title: 'Currency',
            icon: 'mdi-coin',
            path: '/account/preferences',
          },
          { title: 'Change Password', icon: 'mdi-settings', path: '/account/resetpassword' },
          { title: 'Get in touch', icon: 'mdi-gesture-tap', path: '/account/getintouch' },
          { title: 'Log out', icon: 'mdi-logout', path: '/logout' },
        ]
        : [
          {
            title: 'Personal Details',
            icon: 'mdi-account-circle',
            path: '/account/personaldetails',
          },
          {
            title: 'Currency',
            icon: 'mdi-coin',
            path: '/account/preferences',
          },
          { title: 'Change Password', icon: 'mdi-settings', path: '/account/resetpassword' },
          { title: 'Get in touch', icon: 'mdi-gesture-tap', path: '/account/getintouch' },
          { title: 'Log out', icon: 'mdi-logout', path: '/logout' },
        ],
      meUser: {},
      manageElement: false,
      isMobile: false,
    };
  },
  components: {
    Tabs,
    Menu,
    Dialog,
    HotelFilterHeader,
  },
  mounted() {
    this.setHeaderAccess();
  },
  watch: {
    $route() {
      this.setHeaderAccess();
    },
  },
  created() {
    eventBus.$on('checkHeaderAccess', () => {
      this.setHeaderAccess();
    });
  },
  methods: {
    async setHeaderAccess() {
      const isLoggedIn = this.$cookies.get('isLoggedIn');
      const token = this.$cookies.get('token');
      this.isMobile = window.innerWidth <= 768;
      if ((isLoggedIn === 'false' && !token) || isLoggedIn === null) {
        this.hasAccess = false;
      } else {
        const userDetails = await getLoggedInUserDetailService();
        this.meUser = userDetails.data;
        this.guestProfile = userDetails.data.provider === 'Guest' ? true : false;
        this.hasAccess = true;
      }
    },
    navigateToAdminSignup() {
      window.open('https://admin.timeflyz.co/signup', '_self');
    },
    navigateToLogin() {
      Vue.$cookies.set('afterLoginPath', this.$route.fullPath);
      this.$router.push({ path: '/login' });
    },
    changeTab(tab) {
      switch (tab) {
        case 'Bookings':
          this.$router.push('/bookings/upcoming');
          break;
        case 'Favourites':
          this.$router.push({ path: '/favourites' });
          break;
        case 'Hotels':
          this.$router.push({ path: '/hotels' });
          break;
        case 'Home':
          this.wordPressSite();
          break;
        default:
          this.wordPressSite();
      }
    },
    getSelectedItemsIndex() {
      if (this.$route.name === 'home') {
        return 0;
      }
      return this.items.findIndex(x => x.path && this.$route.path.startsWith(x.path));
    },
    getSelectedTabIndex() {
      if (this.$route.name === 'home') {
        return 0;
      }
      return this.tabList.findIndex(name => name.toLowerCase() === this.$route.name);
    },
    getSelectedMenuItemsIndex() {
      return this.menuItems.findIndex(x => x.path === this.$route.path);
    },
    itemClick(value) {
      switch (value) {
        case 'Log out':
          this.openDialog = true;
          break;
        case 'Bookings':
          this.$router.push('/bookings/upcoming');
          break;
        case 'Favourites':
          this.$router.push({ path: '/favourites' });
          break;
        case 'Hotels':
          this.$router.push({ path: '/hotels' });
          break;
        case 'Home':
          this.wordPressSite();
          break;
        default:
          this.$router.push({
            path: `../account/${value.replace(/ /g, '').toLowerCase()}`,
          });
          break;
      }
    },
    menuItemClick(value) {
      const item = this.menuItems.find(v => v.title === value);
      if (item?.path?.indexOf('http') === 0) {
        window.open(item?.path, '_self');
      } else {
        this.$router.push({
          path: `../${value.replace(/ /g, '').toLowerCase()}`,
        });
      }
    },
    logOut() {
      datadogLogs.clearUser();
      // eslint-disable-next-line no-unused-expressions
      window.FB && window.FB.logout();
      if (this.meUser.provider === 'Google') {
        $scriptjs('https://accounts.google.com/gsi/client', () => {
          google.accounts.id.revoke(this.meUser.providerId, () => {});
        });
      }
      this.$cookies.remove('token');
      this.$cookies.remove('isLoggedIn');
      this.$router.push({ path: '/login' });
      eventBus.$emit('checkHeaderAccess');
      this.openDialog = false;
    },
    routeToLogin() {
      this.$router.push({ path: '/login' });
    },
    routeToSignUp() {
      this.$router.push('/signup');
    },
    closeDialog() {
      this.openDialog = false;
    },
    onSearchClicked(filters) {
      this.filters = filters;
      this.$router.push({
        name: 'hotels',
        query: filters,
      });
      this.$router.go(0);
    },

    wordPressSite() {
      window.location.href = 'https://timeflyz.co/';
    },
  },
};
</script>
<style lang="scss" scoped>
.v-app-bar.v-app-bar--fixed {
  z-index: 10 !important;
}
.theme--light.v-app-bar.v-toolbar.v-sheet {
    background-color: #F8F8F8 !important;
}
.header-container {
  background: #ddd;
  // height: 90px !important;
}

.v-app-bar {
  width: 100%;
  height: 200px ;
  background: #fe4500;
}
.v-toolbar {
  // height: 56px !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0), 0 3px 4px 0 rgba(0, 0, 0, 0),
    0 1px 5px 0 rgba(0, 0, 0, 0.16);
  ::v-deep .v-toolbar__content {
    padding: 0;
    margin: 0 auto;
    //padding: 0 30px;
    // height: 56px !important;
    z-index: 10 !important;
    // max-width: 1200px;
    // min-width: 1200px;
  }
}
.v-toolbar__content .v-btn--icon.v-size--default {
  height: 25px !important;
  width: 25px !important;
}


.otherLinks-Mobile {
  display: none;
}

.otherLinks-Mobile-menu{
  display: none;
}
::v-deep .v-toolbar__content .v-btn--icon.v-size--default {
  height: 36px !important;
  width: 36px !important;
}

.header-outer-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-filter {
  width: 650px;
  //height: 150px;
  //background: #fe4500;
}
.header-logo {
  cursor: pointer;
}
.image-container {
  // width: 60%;
  .header-menu {
    display: none;
  }
}

.outer-menu {
  display: block;
}

.menu-right {
  display: none;
}

.action-content {
  // max-width: 40%;
  display: flex;
  align-items: center;
}
.action-content-tab {
  max-width: 100%;
  display: block;
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.header-content {
  color: #080914;
  font-size: 16px;
  cursor: pointer;
  margin-left: 8px;
  margin-right: 8px;
}

.header-content:hover {
  color: $primary-color;
}

.header-cta-wrapper {
  display: flex;
  align-items: center;
  padding: 10px;
  flex-grow: 1;
  justify-content: end;

  .header-cta-list {
    display: flex;
    list-style: none;
    margin-right: 20px;

    li {
      display: flex;
      &:hover {
        a {
          color: black;
        }
      }
      a {
        text-decoration: none;
        color: #fe4500;
        padding: 0 17px;
        font-size: 15px;
        font-weight: 500;
        line-height: 50px;
        transition: all 100ms;
        font-family: Heebo, sans-serif;
      }
    }
  }

  .login-btn {
    margin-left: 20px;
    .v-btn {
      background-color: white;
      color: #fe4500;
    }
  }
}

.hotel-signup {
  background: #fef1eb !important;
  border: 2px solid #ff4500 !important;
  color: #ff4500;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  padding: 6px 17px;
  margin-right: 4px;
}

// responsiveness design
@media only screen and (max-width: 768px) {
  .header-container {
    height: 90px;
  }
  .header-outer-container {
    flex-direction: row;
    margin-top: 25px !important;
  }
  .otherLinks-Web {
    display: block;
  }
  .otherLinks-Mobile {
    display: none;
  }
  .otherLinks-Mobile-menu{
    display: none;
  }
  .image-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .header-logo {
      width: 100%;
    }
    .header-menu {
      display: none;
    }
  }
  .outer-menu {
    display: block !important;
  }

  .menu-right {
    display: block !important;
  }

  .action-content {
    width: 100% !important;
    max-width: 100% !important;
  }
  .action-content-tab {
    display: none;
  }
  .header-filter {
    display: none;
}

.mobile-header {
  display: flex;
  justify-content: space-between;
}

.mobile-header-logo {
  margin: 0px 20px 0px 55px;
}
}
.homeHeader {
  display: none;
}
.homePageFont {
  font-weight: bold;
  background-color: #ff4500;
  border: 2px solid #ff4500 !important;
  color: #fff;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px;
  padding: 6px 17px;
  margin-right: 6px;
}

@media only screen and (max-width: 567px) {
  .otherLinks-Web {
    display: none;
  }
  .otherLinks-Mobile {
    display: block;
  }
  .otherLinks-Mobile-menu{
    display: block;
  }
}
.web-menu {
  display: flex;
  align-items: center;

  @media screen and (max-width: 1024px) {
    display: none;
  }
}
.mobile-menu {
  display: none;

  @media screen and (max-width: 1024px) {
    display: block;
  }
}

.mobile-header-menu {
    display: none;
    background-color: rgba(255, 255, 255, 0.1);
    color: #fe4500;

  @media screen and (max-width: 1024px) {
    display: block;
  }
}
</style>
<style scoped>
::v-deep .v-btn.v-size--default {
  font-size: 1rem;
  text-transform: uppercase;
}
</style>
