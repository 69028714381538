<template>
<v-card class="dialog-modal">
    <v-card-title>
    <v-row d-flex align="start" justify="space-between">
        <h3>Ratings</h3>
        <Button
        class="alert"
        type="icon"
        color="black"
        @buttonClicked="onCloseDialog"
        icon="mdi-close"
        height="25px"
        />
    </v-row>
    </v-card-title>
    <div class="dialog-container" v-if="ratingUpdate">
    <div style="display: flex; flex-flow: wrap;">
        <div v-for="(category, index) in categories" :key="index"
            class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="row" style="display: flex; align-items: center; justify-content: space-evenly;">
            <span class="radio-category">{{ category }}</span>
            <v-rating
            v-model="ratingUpdate[index][category]"
            color="#ff4500"
            background-color="grey lighten-1"
            @input="updateRatingField($event, category, index)"
            dense
            large
            ></v-rating>
        </div>
        </div>
    </div>
    <div v-if="ratingErrorMessage" style="color: red;">Please rate all categories </div>
    </div>
    <v-card-title>
      <v-row d-flex align-content="start" justify="space-between">
        <h3>Reviews</h3>
      </v-row>
    </v-card-title>
    <div class="dialog-container">
        <h3>
            Please leave a review about your experience below.
        </h3>
        <TextArea
            @emitTextValue="updateReviewField($event, 'review')"
            placeholder="Write a review"
        />
      <div v-if="reviewErrorMessage" style="color: red;">Please write a review </div>
    </div>
    <div class="dialog-footer justify-end">
        <Button
            type="outlined"
            @buttonClicked="onCloseDialog"
            value="Close"
            height="40px"
            class="mr-5"
        />
        <Button
            type="flat"
            @buttonClicked="saveReviewRatingData"
            value="Submit"
            color="primary"
            height="40px"
        />
    </div>
</v-card>
</template>

<script>

const {
  getReviewTags,
  saveRatingReview,
} = require('@/services/reviews/review.service');
const {
  HTTP_STATUS_CODE,
} = require('@/constants');

export default {
  name: 'RatingReviewDialog',
  props: [
    'onCloseDialog',
    'onRatingDialogResponse',
    'onReviewDialogResponse',
    'onDialogErrorResponse',
    'mode',
    'dialogData',
    'title',
    'reviewUpdateId',
  ],
  components: {
  },
  data() {
    return {
      categories: [],
      ratings: 5,
      ratingUpdate: null,
      reviewUpdate: null,
      ratingErrorMessage: false,
      reviewErrorMessage: false,
    };
  },
  // this part ----->
  mounted() {
    this.getReviewCategories();
    const array = [];
    array.push({ review: '' });
    this.reviewUpdate = array;
  },
  methods: {
    async getReviewCategories() {
      try {
        const reviewTags = await getReviewTags();
        if (reviewTags.status === HTTP_STATUS_CODE.SUCCESS) {
          const array = [];
          reviewTags.data.forEach(data => array.push(data.name));
          this.categories = array;
          const reviewArray = [];
          this.categories.forEach((element) => {
            reviewArray.push({ [element]: 0 });
          });
          this.ratingUpdate = reviewArray;
        }
      } catch (err) {
        this.$emit('onDialogErrorResponse');
      }
    },
    async updateRatingField(value, field, index) {
      this.ratingUpdate[index][field] = value;
    },
    validate() {
      const returnType = [];
      this.categories.forEach((element, index) => {
        if (this.ratingUpdate[index][element] === 0) {
          returnType.push(false);
        } else {
          returnType.push(true);
        }
      });
      return returnType.includes(false) ? false : true;
    },
    async saveReviewRatingData() {
      if (this.validate() && this.reviewUpdate[0].review.length > 0) {
        const array = [];
        this.categories.forEach((data, index) => {
          array.push({ key: data, value: this.ratingUpdate[index][data] });
        });
        const payload = {
        // eslint-disable-next-line no-underscore-dangle
          reservationId: this.dialogData._id,
          // eslint-disable-next-line no-underscore-dangle
          hotelId: this.dialogData.hotelId._id,
          comment: this.reviewUpdate[0].review,
          tags: array,
        };
        try {
          const saveRating = await saveRatingReview(payload);
          if (saveRating.status === HTTP_STATUS_CODE.SUCCESS) {
          // eslint-disable-next-line no-underscore-dangle
            this.$emit('onDialogResponse');
          } else {
            this.$emit('onDialogErrorResponse');
          }
        } catch (err) {
          this.$emit('onDialogErrorResponse');
        }
      } else {
        if (!this.validate()) {
          this.ratingErrorMessage = true;
        } else {
          this.ratingErrorMessage = false;
        }
        if (this.reviewUpdate[0].review.length === 0) {
          this.reviewErrorMessage = true;
        } else {
          this.reviewErrorMessage = false;
        }
      }
    },
    async updateReviewField(value, field) {
      this.reviewUpdate[0][field] = value;
    },
  },
};
</script>
<style lang="scss">
.dialog-modal {
.v-card__title {
    padding: 17px 24px !important;
    border-bottom: 1px solid #c4c5cc !important;
    font-size: 18px !important;
    font-weight: bold !important;
}
.dialog-container {
    padding: 24px;
}
.dialog-footer {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ccc;
}
.text-field-container {
    margin-top: 15px;
}
.alert {
    ::v-deep .theme--light.v-btn.v-btn--icon:hover,
    ::v-deep .theme--light.v-btn.v-btn--icon:focus {
    color: $error-color !important;
    }
}
.radio-category{
    font-size: 16px;
    font-weight:bold;
    width: 30%;
}
.rating-flex{
    display: flex;
    justify-content: center;
    align-items: center;
}
.p-0{
    padding: 0px;
}
.m-0{
    margin: 0px;
}
}
@media only screen and (max-width: 768px) {
  .dialog-modal .radio-category{
    width: 26%;
}
}
</style>
