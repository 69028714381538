<template>
  <v-flex class="carousel-container" d-flex justify-center align-center>
    <v-carousel light  prev-icon="mdi-arrow-left"
      next-icon="mdi-arrow-right" cycle height="600"
      delimiter-icon="mdi-checkbox-blank-circle"
      hide-delimiter-background
      active-class="v-window-item--active"
      interval=6000
      :show-arrows="showArrows"
      >
      <v-carousel-item v-for="(url, i) in photos" :key="i">
        <v-sheet height="100%">
          <v-row class="fill-height" align="center" justify="center">
            <v-img class="carousel-img" :src="url" />
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </v-flex>
</template>

<script>

export default {
  name: 'Carousel',
  props: ['photos', 'showArrows'],
};
</script>

<style lang="scss">
.carousel-container {
  overflow: hidden !important;
  .carousel-img{
      height: 100%;
      width: 100%;
  }
  .v-window__prev {
    .theme--light.v-btn.v-btn--icon{
      background: white !important;
    }
  }
  .v-window__next {
    .theme--light.v-btn.v-btn--icon{
      background: white !important;
    }
  }
  .v-carousel__controls {
    .v-btn {
      height: 12px;
      width: 12px;
      min-width: unset;
    }
    .v-btn__content {
      .v-icon {
        font-size: 10px !important;
      }
      .mdi-checkbox-blank-circle::before {
        // margin-bottom: 15px;
        color: transparent;
      }
    }
    .theme--light.v-btn.v-btn--icon{
      border: 2px solid white !important;
      background: transparent !important;
      margin-right: 1px;
    }
    .theme--light.v-btn.v-btn--icon.v-btn--active {
      border: 2px solid #FF4500 !important;
      .mdi-checkbox-blank-circle::before {
        color: #FF4500;
      }
    }
  }
  ::v-deep .v-item-group .v-btn:not(.v-btn--text):not(.v-btn--outlined).v-btn--active:before {
      opacity: 0 !important;
  }
  .v-btn__content .v-icon{
    font-size: 20px !important;
  }
  ::v-deep .v-item--active{
    background-color: #FF4500 !important;
    color: #FF4500 !important;
  }
  ::v-deep .v-window-item--active{
    background-color: #FF4500 !important;
    color: #FF4500 !important;
  }
}
.left-container .carousel-container{
  .carousel-img, .v-carousel, .v-image{
      height: 400px;
  }
  .v-image__image--cover{
    background-size: auto !important;
  }
}
</style>
