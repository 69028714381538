<template>
  <div id="googleLoginId"></div>
</template>

<script>
import $scriptjs from 'scriptjs';

export default {
  emits: ['handleCredential'],
  created() {
    $scriptjs('https://accounts.google.com/gsi/client', () => {
      google.accounts.id.initialize({
        client_id: process.env.VUE_APP_WEB_CLIENT_ID,
        callback: this.handleCredentialResponse,
      });
      google.accounts.id.renderButton(
        document.getElementById('googleLoginId'),
        {
          text: 'continue_with',
          theme: 'outline',
          size: 'large',
          width: 200,
        },
      );
      google.accounts.id.prompt(); // also display the One Tap dialog
    });
  },
  methods: {
    handleCredentialResponse(response) {
      if (response.credential) {
        this.$emit('handleCredential', response.credential);
      }
    },
  },
};
</script>

<style>

</style>
