<template>
  <v-row align-content="center" justify="center" class="number-header">
    <v-row class="header-subcontainer">
      <v-row
        align-content="center"
        justify="start"
        :class="[{ 'active-tab': header.activeTab}, { 'success-tab': header.successTab}]"
        v-for="(header, index) in headerDetails"
        :key="index"
      >
        <div class="tab-number">
          <v-icon v-if="header.successTab">mdi-check</v-icon>
          <b v-if="!header.successTab">{{header.number}}</b>
        </div>
        <span class="tab-label">{{header.label}}</span>
      </v-row>
    </v-row>
  </v-row>
</template>

<script>
export default {
  name: 'NumberTabs',
  props: ['headerDetails'],
};
</script>

<style lang="scss" scoped>
.number-header {
  height: 48px;
  margin-top: 50px !important;
  padding-left: 100px;
  background-color: rgb(231, 230, 234);
  .active-tab {
    .tab-label {
      color: black;
      font-weight: bold;
    }
    .tab-number {
      color: white;
      background: #FF4500;
      border: unset;
    }
  }
  .success-tab {
    .tab-label {
      color: black;
      font-weight: bold;
    }
    .tab-number {
      background: #3ccc6b;
      border: unset;
    }
  }
  .tab-label {
    color: rgba(8, 9, 20, 0.7);
    margin-left: 8px;
  }
  .tab-number {
    color: rgba(8, 9, 20, 0.7);
    border: 1px solid rgba(8, 9, 20, 0.7);
    height: 24px;
    width: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    .v-icon {
      color: white;
      font-size: 16px;
    }
  }
}
.header-subcontainer {
  max-width: 535px;
}

@media only screen and (max-width: 768px) {
  .number-header {
    padding-left: 30px;
  }
}
</style>
