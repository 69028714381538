import Vue from 'vue';
import VueCookies from 'vue-cookies';
import Lodash from 'lodash';
import axios from '../../axios';
import { TOASTER_MESSAGE } from '../../constants';

Vue.use(VueCookies);

export default {
  state() {
    return {
      filteredHotels: '',
      selectedHotel: {},
      searchError: '',
      selectedDate: '',
      selectedTime: '',
      selectedHours: '',
      showMap: false,
      hotelRoom: {},
      roomSelected: {},
      timeSelected: '',
    };
  },
  getters: {
    mapShowStatus(state) {
      return state.showMap;
    },
    roomSelectedStatus(state) {
      return state.roomSelected;
    },
    timeSelectedStatus(state) {
      return state.timeSelected;
    },
  },
  mutations: {
    setSearchHotels(state, payload) {
      payload = payload.length > 0 && payload.map((hotel) => {
        hotel.price = Lodash.minBy(hotel.rooms, 'threeHourRate').threeHourRate;
        hotel.rating = hotel.rating || 0;
        return hotel;
      });
      state.filteredHotels = payload;
    },
    setSelectedHotel(state, hotel) {
      state.selectedHotel = hotel;
    },
    setSearchError(state, payload) {
      if (payload.response && payload.response.data && payload.response.data.error) {
        state.searchError = payload.response.data.error;
      } else {
        state.searchError = TOASTER_MESSAGE.TROUBLE;
      }
    },
    setDate(state, payload) {
      state.selectedDate = payload;
    },
    setTime(state, payload) {
      state.selectedTime = payload;
    },
    setSelectedHours(state, payload) {
      state.selectedHours = payload;
    },
    resetError(state, payload) {
      state.searchError = payload;
    },
    handleMap(state) {
      state.showMap = !state.showMap;
    },
  },
  actions: {
    async searchHotelsByFilterOptions({ commit }, payload) {
      try {
        const { data } = await axios.post('mobile/hotel/search',
          {
            lon: payload.lon,
            lat: payload.lat,
            date: payload.date,
            time: payload.time,
            duration: payload.hours,
            types: payload.microStay,
            name: payload.name,
          });
        commit('setSearchHotels', data);
      } catch (err) {
        commit('setSearchError', err);
      }
    },
    async getHotelById({ commit }, payload) {
      try {
        const { data } = await axios.get(`mobile/hotel/${payload}`);
        commit('setSelectedHotel', data);
      } catch (err) {
        commit('setSearchError', err);
      }
    },
    async getHotelDetails({ commit }, payload) {
      try {
        const { data } = await axios.get(`mobile/hotel/${payload.hotelId}/search?date=${payload.date}&time=${payload.time}&duration=${payload.duration}`);
        commit('setSelectedHotel', data[0]);
      } catch (err) {
        commit('setSearchError', err);
      }
    },
    async setSelectedDate({ commit }, payload) {
      commit('setDate', payload);
    },
    async setSelectedTime({ commit }, payload) {
      commit('setTime', payload);
    },
    async setSelectedHours({ commit }, payload) {
      commit('setSelectedHours', payload);
    },
    async setSearchHotels({ commit }, payload) {
      commit('setSearchHotels', payload);
    },
    resetSearchError({ commit }) {
      commit('resetError', null);
    },
    setMap({ commit }) {
      commit('handleMap');
    },
  },
};
