<template>
  <div class="favourites" v-bind:style= "[isMobile ? { marginTop: '50px'} : { marginTop: '0px'}]">
     <h1>Favourites</h1>
    <v-row v-if="loadingInProgress" align="center" justify="center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-row>
    <div v-if="!favouriteHotels.length && !loadingInProgress" class="no-search-results">
      <v-icon class="favourite-icon">
        mdi-heart
      </v-icon>
      <h3>No Favourites Yet</h3>
      <div class="text">You can add hotels in your favorites by clicking "Heart Icon"</div>
        <v-btn
        class="go-back"
        color="#FF4500"
        rounded="0"
        variant="flat"
        @click="$router.go(-1)"
      >
        Go Back
      </v-btn>
    </div>
    <v-flex class="favourites-results" d-flex>
      <div @click="viewHotel(hotel)" v-for="(hotel,index) in favouriteHotels" :key="index"
        class="hotel-card-outer">
        <HotelCard :hotel="hotel" :currency="getCurrencyData" />
      </div>
    </v-flex>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import HotelCard from '@/common/HotelCard.vue';
import { HTTP_STATUS_CODE, TOASTER_MESSAGE } from '@/constants';

const {
  getFavouriteHotelsService,
} = require('@/services/hotels/favourites.service');

export default {
  name: 'Favourites',
  porps: [],
  data() {
    return {
      snackbar: false,
      toasterMessage: '',
      loadingInProgress: false,
      favouriteHotels: [],
      searchValue: [],
      isMobile: window.innerWidth <= 768,
    };
  },
  components: {
    HotelCard,
  },
  mounted() {
    const { query } = this.$route;
    this.searchValue = query;
  },
  computed: {
    ...mapGetters({
      getCurrencyData: 'getCurrencyData',
    }),
  },
  created() {
    this.getFavourites();
  },
  methods: {
    ...mapMutations(['setSelectedHotel']),
    viewHotel(hotel) {
      this.setSelectedHotel(hotel);
      // eslint-disable-next-line no-underscore-dangle
      this.$router.push({ path: `/view-hotel/${hotel._id}` });
    },
    async getFavourites() {
      try {
        this.loadingInProgress = true;
        const getFavouriteHotelsResponse = await getFavouriteHotelsService();
        if (getFavouriteHotelsResponse.status === HTTP_STATUS_CODE.SUCCESS) {
          this.favouriteHotels = getFavouriteHotelsResponse.data;
          this.loadingInProgress = false;
        } else {
          throw new Error();
        }
      } catch (err) {
        this.snackbar = true;
        this.toasterMessage = TOASTER_MESSAGE.TROUBLE;
        this.loadingInProgress = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.favourites{
  padding: 2.9rem 0 2.9rem 0;
  .favourites-results{
    width: 100%;
    flex-wrap: wrap;
      .hotel-card-outer {
      margin: 15px 25px 0 0;
      width: 100%;
      cursor: pointer;
      }
  }
  .no-search-results {
    font-size: 18px;
    line-height: 24px;
    padding: 30px;
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
    min-height: 145px;
    margin-top: 10%;
      .text {
    color: #FF4500;
    margin-top: 10px;
  }
  }
    .favourite-icon {
    font-size: 35px;
    margin-bottom: 10px;
  }
  .go-back {
    color: #fff;
    margin-top: 20px;
  }
}

// responsiveness design
@media only screen and (max-width: 768px) {
  .search-results {
    .hotel-card-outer {
      margin-right: 8px !important;
    }
  }
  .favourites .favourites-results .hotel-card-outer {
   width: 43%;
  }
}
</style>
