import { datadogLogs } from '@datadog/browser-logs';
import axios from '../../axios';

const constants = require('@/constants');

export const getLoggedInUserDetailService = async () => {
  const response = await axios.get(constants.API.USER);
  datadogLogs.setUser(response.data);
  return response;
};

export const updateUserDetailService = userDetails => new Promise((resolve, reject) => {
  axios.put(constants.API.USER, userDetails,
    {
      headers: {
        Authorization: `Bearer ${window.$cookies.get('token')}`,
      },
    }).then(response => resolve(response))
    .catch(err => reject(err));
});

export const checkBanUserService = userDetails => new Promise((resolve, reject) => {
  axios.post(constants.API.BANUSER, userDetails,
    {
      headers: {
        Authorization: `Bearer ${window.$cookies.get('token')}`,
      },
    }).then(response => resolve(response))
    .catch(err => reject(err));
});
export const updateAppleEmail = (userDetails, token) => new Promise((resolve, reject) => {
  axios.put(constants.API.APPLE_UPDATE_EMAIL, userDetails,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(response => resolve(response))
    .catch(err => reject(err));
});
export const updateUserPasswordService = pass => new Promise((resolve, reject) => {
  axios.put(constants.API.USER_PASS, pass).then(response => resolve(response))
    .catch(err => reject(err));
});
export const resetPasswordService = (currentPassword, newPassword) => new Promise((resolve, reject) => {
  axios.put(`${constants.API.USER}/password`, {
    currentPassword,
    newPassword,
  },
  {
    headers: {
      Authorization: `Bearer ${window.$cookies.get('token')}`,
    },
  }).then(response => resolve(response))
    .catch(err => reject(err));
});
export const bookRoom = bookingDetails => new Promise((resolve, reject) => {
  const { hotelId, roomId, ...rest } = bookingDetails;
  return axios.post(
    `${Constants.API.BOOK_ROOM}/${hotelId}/room/${roomId}`, { ...rest, status: 'Booked' },
    {
      headers: {
        Authorization: `Bearer ${window.$cookies.get('token')}`,
      },
    },
  ).then(response => resolve(response))
    .catch(err => reject(err));
});
export const checkEmailExist = emailID => new Promise((resolve, reject) => {
  axios.get(`${constants.API.USEREMAIL}/confirm/${emailID}`).then(response => resolve(response))
    .catch(err => reject(err));
});
export const createStripeIntent = () => new Promise((resolve, reject) => {
  axios.get(
    `${constants.API.STRIPE_INTENT_CREATE}`,
    {
      headers: {
        Authorization: `Bearer ${window.$cookies.get('token')}`,
      },
    },
  ).then(response => resolve(response))
    .catch(err => reject(err));
});
export const changePasswordService = (newPassword, token) => new Promise((resolve, reject) => {
  axios.put(
    `${constants.API.USEREMAIL}/resetPassword`,
    { password: newPassword },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  ).then(response => resolve(response))
    .catch(err => reject(err));
});
export const updateAliasAppleEmail = userDetails => new Promise((resolve, reject) => {
  axios.put(constants.API.ALIAS_APPLE_UPDATE_EMAIL, userDetails,
    {
      headers: {
        Authorization: `Bearer ${window.$cookies.get('token')}`,
      },
    }).then(response => resolve(response))
    .catch(err => reject(err));
});

export const forgotPasswordEmail = userDetails => new Promise((resolve, reject) => {
  axios.put(constants.API.FORGOT_PASSWORD_EMAIL, userDetails,
    {
      headers: {
        Authorization: `Bearer ${window.$cookies.get('token')}`,
      },
    }).then(response => resolve(response))
    .catch(err => reject(err));
});

export const getCurrencies = () => new Promise((resolve, reject) => {
  axios.get(constants.API.ALL_CURRENCY).then(response => resolve(response))
    .catch(err => reject(err));
});

export const getCurrenciesPreference = () => new Promise((resolve, reject) => {
  axios.get(constants.API.ALL_PREFERENCE_CURRENCY,
    {
      headers: {
        Authorization: `Bearer ${window.$cookies.get('token')}`,
      },
    }).then(response => resolve(response))
    .catch(err => reject(err));
});

export const saveCurrency = payload => new Promise((resolve, reject) => {
  axios.post(constants.API.SAVE_CURRENCY, payload,
    {
      headers: {
        Authorization: `Bearer ${window.$cookies.get('token')}`,
      },
    }).then(response => resolve(response));
});
export const emailVerifyService = userId => new Promise((resolve, reject) => {
  axios.get(`${constants.API.USEREMAIL}/emailVerify/${userId}`).then(response => resolve(response))

    .catch(err => reject(err));
});
