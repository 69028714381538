<template>
  <Login />
</template>

<script>
import { mapState } from 'vuex';
import Login from '@/components/Login.vue';

export default {
  components: {
    Login,
  },
  computed: {
    ...mapState({
      message: state => state.user.message,
    }),
  },
};
</script>
<style>

</style>
