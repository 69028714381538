<template>
  <div class="upcoming-bookings">
    <div class="header">Upcoming Bookings</div>
    <v-row class="loader" v-if="loadingInProgress" align="center" justify="center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-row>
    <v-row class="loader" v-if="!loadingInProgress && !tableItems.length"
    align="center" justify="center">
      No Upcoming bookings found
    </v-row>
    <Table
      v-if="!loadingInProgress"
      mode="reservation"
      :tableHeaders="tableHeaders"
      :tableItems="tableItems"
      tableName="upcoming"
    />
    <!--<v-snackbar
      v-model="snackbar"
      bottom right
      :timeout="timeout"
    >
      {{ bookingError }}
    </v-snackbar>-->
    <div v-if="openDialog">
      <v-dialog v-model="openDialog" max-width="370px">
        <Dialog
          :dialogData="bookingError"
          :onCloseDialog="closeDialog"
          mode="snackbar-error"
        />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import { SNACKBAR_TIMEOUT } from '@/constants';
import Table from '@/common/Table.vue';
import Dialog from '@/common/ErrorDialog.vue';

export default {
  name: 'UpcomingBookings',
  data() {
    return {
      tableHeaders: [
        { text: 'Date', value: 'date', width: '60px' },
        { text: 'Hotel', value: 'hotelId.name', width: '100px' },
        { text: 'Check in', value: 'checkIn', width: '80px' },
        { text: 'Stay', value: 'duration', width: '60px' },
        { text: 'Total', value: 'amount', width: '50px' },
        { text: 'Comment', value: 'userComment', width: '100px' },
        { text: 'Status', value: 'status', width: '90px' },
        {
          text: '', value: 'button', sortable: false, width: '40px',
        },
      ],
      loadingInProgress: false,
      snackbar: false,
      openDialog: false,
      timeout: SNACKBAR_TIMEOUT,
    };
  },
  components: {
    Table,
    Dialog,
  },
  mounted() {
    this.loadingInProgress = true;
    const time = moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    const timezone = moment.tz.guess();
    this.getBookingsByType({ type: 'upcoming', currenTime: time, timeZone: timezone });
  },
  computed: {
    ...mapState({
      tableItems: state => state.bookings.currentBookings,
      bookingError: state => state.bookings.bookingError,
    }),
  },
  watch: {
    tableItems() {
      this.tableItems.forEach((booking) => {
        booking.date = moment(booking.reservationStart).tz(moment.tz.guess() || 'Australia/Sydney').format('MMM D YYYY');
        booking.checkIn = moment(booking.reservationStart).tz(moment.tz.guess() || 'Australia/Sydney').format('hh:mm A');
        if (booking.charge && booking.charge.amount) {
          booking.amount = booking.charge.amount;
        } else if (booking.duration === 3) {
          booking.amount = booking.roomId.threeHourRate;
        } else if (booking.duration === 6) {
          booking.amount = booking.roomId.sixHourRate;
        } else {
          booking.amount = booking.roomId.twelveHourRate;
        }
      });
      this.loadingInProgress = false;
    },
    bookingError() {
      if (this.bookingError !== null) {
        this.snackbar = true;
        this.openDialog = true;
      }
      // setTimeout(() => this.resetError(), SNACKBAR_TIMEOUT);
      this.loadingInProgress = false;
    },
  },
  methods: {
    ...mapActions(['getBookingsByType', 'resetBookingError']),
    resetError() {
      this.snackbar = false;
      this.resetBookingError();
    },
    closeDialog() {
      this.snackbar = false;
      this.openDialog = false;
      this.resetBookingError();
    },
  },
};
</script>

<style lang="scss">
.upcoming-bookings {
  background-color: #FFFFFF;
  width: 100%;
  .header {
    font-size: 18px;
    line-height: 24px;
    padding: 12px 16px;
    color: #080914;
    font-weight: bold;
    border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  }
  .loader {
    min-height: 200px;
  }
}
</style>
