<template>
  <div class="top-margin">
  <SignUpForm
    title="Sign up"
    @signup="signUp"
    @emitGoogleSignup="googleSignup"
    @emitFacebookSignup="facebookSignup"
    @emitAppleLogin="emitAppleLogin"
  />
  <Footer />
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SignUpForm from '@/common/SignupForm.vue';
import Footer from '@/components/HomeFooter.vue';

export default {
  components: {
    SignUpForm,
    Footer,
  },
  computed: {
    ...mapGetters({
      getCurrencyData: 'getCurrencyData',
    }),
  },
  methods: {
    ...mapActions([
      'signUpUser',
      'googleSignin',
      'facebookSignin',
      'appleSignin',
    ]),
    async signUp(payload) {
      await this.signUpUser({ ...payload, vatgst: this.getCurrencyData._id, fromLogin: false });
    },
    async googleSignup(token) {
      await this.googleSignin(token);
    },
    async facebookSignup(token) {
      await this.facebookSignin(token);
    },
    async emitAppleLogin(token) {
      await this.appleSignin(token);
    },
  },
};
</script>

<style>
	@media only screen and (max-width: 768px) {
		.top-margin {
      /* margin-top: 90px; */
		}
	}
</style>
