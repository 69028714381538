export default {
  install(Vue) {
    Vue.filter('$price', (value) => {
      if (!value) {
        return '';
      }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    });
  },
};
