<template>
<div class="hover-icon">
  <v-menu v-model="picker"
          :close-on-content-click="false"
          max-width="290">
    <template v-slot:activator="{ on, attrs }">
      <v-btn id="date-selector"
             height="40"
             color="#ffffff"
             width="100%"
             v-bind="attrs"
             v-on="on"
             :ripple="false"
             elevation="0"
             retain-focus-on-click>
        <!-- <v-icon color="grey" class="mr-2">
          mdi-calendar
        </v-icon> -->
        {{ computedDate }}
      </v-btn>
    </template>
    <v-date-picker @change="emitDateValue"
                   color="#FF4500"
                   no-title
                   v-model="dateValue"
                   :max="maxDateValue"
                   :allowed-dates="allowedDates">
    </v-date-picker>
  </v-menu>
</div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'DatePicker',
  props: ['value'],
  emits: ['input', 'onChange'],
  data() {
    return {
      picker: false,
      dateValue: '',
      maxDateValue: '',
    };
  },
  computed: {
    computedDate() {
      return this.dateValue
        ? moment(this.dateValue).format('ddd DD MMM, YYYY')
        : '';
    },
  },
  mounted() {
    if (this.value) {
      this.dateValue = moment(this.value, 'YYYY-MM-DD').format('YYYY-MM-DD');
      this.maxDateValue = moment(this.value).add(60, 'day').format('YYYY-MM-DD');
    } else {
      this.dateValue = '';
      this.maxDateValue = '';
    }
  },
  methods: {
    invokeDatePicker() {
      this.$refs.datePicker.focus();
      this.picker = true;
    },
    emitDateValue() {
      this.picker = false;
      this.$emit('input', this.dateValue);
      this.$emit('onChange', this.dateValue);
    },
    removeDate() {
      this.dateValue = '';
      this.emitDateValue();
    },
    allowedDates(date) {
      return +moment(date).startOf('d') >= +moment().startOf('d');
    },
  },
};
</script>

<style lang="scss" scoped>
.hover-icon{
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
}
#date-selector {
  ::v-deep .v-btn__content {
    justify-content: flex-start;
    text-transform: none;
    font-size: 16px;
    letter-spacing: 0px;
  }
}
</style>
