<template>
  <div>
    <v-menu offset-y min-width="150">
      <template v-slot:activator="{ on }">
        <div v-on="on" style="align-items: center; justify-content: center;">
          <v-btn v-if="type === 'icon'" icon>
            <v-icon color="#fe4500" v-bind:style= "[icon === 'mdi-account-circle' && {'background-color': '#fff', 'width': '30px', 'height': '30px', 'border-radius': '30px', 'font-size': '38px'}]" >{{icon}}</v-icon>
          </v-btn>
          <div class="avatar-container" v-if="type === 'image'">
            <Avatar />
          </div>
        </div>
      </template>
      <v-list>
        <v-list-item-group
          :value="selectedTabIndex"
          color="primary"
        >
          <v-list-item  active-color="primary" v-for="(item, index) in items" :key="index" @click="itemClick(item.title)">
            <v-list-item-title>
              <v-row align-content="center" justify="space-between">
                <span>{{ item.title }}</span>
                <svgicon
                  v-if="item.icon && item.customIcon"
                  :icon="item.icon"
                  :width="item.width"
                  :height="item.height"
                  color="inherit"
                ></svgicon>
                <v-icon v-if="item.icon && !item.customIcon">{{item.icon}}</v-icon>
              </v-row>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import Avatar from '@/common/Avatar.vue';

export default {
  name: 'Menu',
  props: ['items', 'itemClick', 'icon', 'label', 'type', 'selectedTabIndex'],
  components: {
    Avatar,
  },
};
</script>

<style lang="scss" scoped>
.v-btn--icon.v-size--default .v-icon, .v-btn--fab.v-size--default .v-icon {
  font-size: 30px;
}
.v-list {
  padding: unset;
}
.v-list-item {
  min-height: 40px;
  padding: 0 12px;
  border-bottom: 1px solid #dcdee6;
  .v-icon.v-icon {
    font-size: 20px;
    color: #d7d7d8;
  }
  ::v-deep svg {
    fill: #c4c5cc;
  }
}
.avatar-container {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  color: $primary-color !important;
  .v-icon.v-icon {
    color: $primary-color;
  }
  ::v-deep svg {
    fill: $primary-color;
  }
}
</style>
