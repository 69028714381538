<template>
<div class="row">
  <div class="col-9">
    <splide
      :options="primaryOptions"
      ref="primary"
      class="carousel-container" d-flex justify-center align-center
    >
      <splide-slide v-for="(slide, i) in photos" :key="i">
        <img class="carousel-img" :src="slide" alt="slide.alt">
      </splide-slide>
    </splide>
  </div>
  <div class="col-3 carousel-thumbnails">
    <splide
      :options="secondaryOptions"
      ref="secondary"
    >
      <splide-slide v-for="(slide, i) in photos" :key="i">
        <img class="carousel-thumb" :src="slide" alt="slide.alt">
      </splide-slide>
    </splide>
  </div>
</div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export default {
  name: 'Carousel',
  props: ['photos', 'showArrows'],
  components: {
    Splide,
    SplideSlide,
  },
  data() {
    return {
      primaryOptions: {
        type: 'loop',
        perPage: 1,
        perMove: 1,
        gap: '1rem',
        pagination: false,
        focus: 'center',
        isNavigation: false,
      },
      secondaryOptions: {
        type: 'slide',
        rewind: true,
        gap: '1rem',
        pagination: false,
        fixedHeight: '70%',
        fixedWidth: '100%',
        cover: true,
        focus: false,
        isNavigation: true,
        updateOnMove: true,
      },
      count: 0,
    };
  },
  mounted() {
    // Set the sync target.
    this.$refs.primary.sync(this.$refs.secondary.splide);
  },
};
</script>

<style lang="scss">
.splide__arrows .splide__arrow{
    background: transparent !important;
    border: 1px solid #ccc;
    display: none;
}
.splide__arrows .splide__arrow:hover{
  background: #fff0eb !important;
  border: 1px solid #FF4500;
}
.splide--nav {
    display: flex;
    justify-content: center;
    .splide__track {
      overflow: visible;
    }
    .splide__track .splide__list{
      display: flex;
      flex-direction: column;
      max-height: 400px;
      overflow-y: scroll;
    }
    .splide__track>.splide__list>.splide__slide.is-active {
      border-color: #FF4500;
    }
    .splide__track>.splide__list>.splide__slide{
      margin-bottom: 7px;
    }
}
.carousel-container {
  overflow-y: scroll;
  .carousel-thumb{
    height: 50px;
    width: 50px;
  }
}
.left-container .carousel-container{
  .carousel-img{
    object-fit: cover !important;
    height: 400px !important;
  }
  .v-image__image--cover{
    background-size: auto !important;
  }
  .carousel-thumbnails{
    border: 1px solid red;
  }
}
</style>
