<template>
  <div class="hotel-cards">
    <div class="search-results-outer-container">
      <div v-if="mapShowStatus" class="map-main-div">
      <div class="mapView-div" >
        <gmap-map :center="center" :options="mapOptions" :zoom="11" class="map-view">
        <gmap-marker
          :key="index"
          v-for="(hotel, index) in hotels"
          :position="{ lat: hotel.location.coordinates[1], lng: hotel.location.coordinates[0]}"
          @mouseover="showByIndex = index;"
          @mouseout="showByIndex = null"
          @click="isMobile ? handleCardHover(index) : viewHotel(hotel)"
        >
        <gmap-info-window
             style="width: 50px; height: 10px;"
            :options="{
              maxWidth: 400,
              maxHeight: 100,
              pixelOffset: { width: 0, height: -3 , }
            }"
            :opened="showByIndex === index"
            @closeclick="infoWindow.open=false"
            >
            <infowindow :hotel = hotel :available = checkPrice(hotel) @selectHotel="viewHotel(hotel)" :currency = currency />
        </gmap-info-window>
    </gmap-marker>
        </gmap-map>
      </div >
      <div class="map-hotel-view">
        <v-flex class="search-results" d-flex>
          <div
            v-for="(hotel, index) in hotels"
            :key="hotel._id"
            class="hotel-card-outer"
            @click="viewHotel(hotel)"
          >
            <HotelCard :hotel="hotel" :cardStyle="mapShowStatus" @card-hover="handleCardHover(index)" :currency="currency"/>
          </div>
        </v-flex>

      </div>
      </div>
      <div v-else class="hotel-view">
        <v-flex class="search-results for-display-flex">
          <div
            v-for="hotel in hotels"
            :key="hotel._id"
            class="hotel-card-outer"
            @click="viewHotel(hotel)"
          >
            <HotelCard :hotel="hotel" :cardStyle="mapShowStatus" :noCard="hotel.length" @room-selected="saveRoom" @card-duration="saveTime" :currency="currency"/>
          </div>
        </v-flex>
          <!-- <v-flex class="search-results" d-flex v-else>
          <div
            v-for="n in 22"
            :key="n"
            class="hotel-card-outer"
          >
            <HotelCard :cardStyle="viewMap"/>
          </div>
        </v-flex> -->
      </div>
    </div>
    <div v-if="openDialog">
      <v-dialog v-model="openDialog" max-width="370px">
        <Dialog
          :dialogData="dialogMessage"
          :onCloseDialog="closeDialog"
          mode="snackbar-error"
        />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Lodash from 'lodash';
import HotelCard from '@/common/HotelCard.vue';
import Dialog from '@/common/ErrorDialog.vue';
import Infowindow from '@/common/Infowindow.vue';
import { HTTP_STATUS_CODE, TOASTER_MESSAGE } from '@/constants';
import markerIcon from '@/assets/marker-icon.svg';
import markerBlack from '@/assets/marker-black.svg';
import { mapGetters } from 'vuex';
// import { InfoWindow } from 'vue2-google-maps';
// import InfowindowVue from '../../common/Infowindow.vue';
// import Infowindow from '@/common/Infowindow.vue';
// import hotels from '../../store/modules/hotels';
// import hotels from '../../store/modules/hotels';

const {
  getLoggedInUserDetailService,
  checkBanUserService,
} = require('@/services/user/user.service');

export default {
  name: 'HotelCards',
  props: ['hotels', 'viewMap', 'hotelVisible', 'searchValue', 'currency'],
  data() {
    return {
      userID: null,
      openDialog: false,
      dialogMessage:
        'Unfortunately we cannot process a booking for you at this property. Please contact the property directly.',
      center: { lat: -33.8353, lng: 151.0655 },
      mapValue: this.viewMap,
      hotelList: this.hotels,
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        disableDefaultUI: true,
        // zoomControlOptions: {
        //   position: 1,
        // },
      },
      markerBlack,
      markerIcon,
      infoWindow: {
        position: { lat: 0, lng: 0 },
        open: true,
        template: '',
      },
      showByIndex: 0,
      room: {},
      duration: '',
      isMobile: window.innerWidth <= 768,
    };
  },
  components: {
    HotelCard,
    Dialog,
    Lodash,
    Infowindow,
  },
  methods: {
    async viewHotel(hotel) {
      if (window.$cookies.get('token') !== null) {
        await this.getLoggedInUserDetails();
      }
      const userDetails = this.userID;
      if (userDetails) {
        const userDetailsCheck = {
          mobile: userDetails.mobile,
          email: userDetails.email,
          hotelId: hotel._id,
        };
        const updatedUserResponse = await checkBanUserService(userDetailsCheck);
        if (updatedUserResponse.status === 201 && updatedUserResponse.data.checkingStatus === false) {
          this.openDialog = true;
        } else {
          const newTabUrlRedirect = `${this.$router.resolve({
            path: `/view-hotel/${hotel._id}${window.location.search}`,
            query: {
              searchValue: this.searchValue,
              room: this.room,
              cardDuration: this.duration,
              currency: this.currency,
            },
          }).href}`;
          let popUpWindow;

          if (popUpWindow === null || typeof popUpWindow === 'undefined') {
            if (this.isMobile) {
              this.$router.push({
                path: `/view-hotel/${hotel._id}${window.location.search}`,
                query: {
                  searchValue: this.searchValue,
                  room: this.room,
                  cardDuration: this.duration,
                  currency: this.currency,
                },
              });
            } else {
              popUpWindow = window.open(newTabUrlRedirect, '_blank');
            }
          } else {
            popUpWindow.focus();
          }

          if (popUpWindow === null || typeof popUpWindow === 'undefined' || this.isMobile) {
            this.$router.push({
              path: `/view-hotel/${hotel._id}${window.location.search}`,
              query: {
                searchValue: this.searchValue,
                room: this.room,
                cardDuration: this.duration,
                currency: this.currency,
              },
            });
          }
        }
      } else {
        // eslint-disable-next-line no-underscore-dangle
        const newTabUrl = `${this.$router.resolve({
          path: `/view-hotel/${hotel._id}${window.location.search}`,
          query: {
            searchValue: this.searchValue,
            room: this.room,
            cardDuration: this.duration,
            currency: this.currency,
          },
        }).href}`;
        let popUpWindow;

        if (popUpWindow === null || typeof popUpWindow === 'undefined') {
          if (this.isMobile) {
            this.$router.push({
              path: `/view-hotel/${hotel._id}${window.location.search}`,
              query: {
                searchValue: this.searchValue,
                room: this.room,
                cardDuration: this.duration,
                currency: this.currency,
              },
            });
          } else {
            popUpWindow = window.open(newTabUrl, '_blank');
          }
        } else {
          popUpWindow.focus();
        }

        if (popUpWindow === null || typeof popUpWindow === 'undefined' || this.isMobile) {
          this.$router.push({
            path: `/view-hotel/${hotel._id}${window.location.search}`,
            query: {
              searchValue: this.searchValue,
              room: this.room,
              cardDuration: this.duration,
              currency: this.currency,
            },
          });
        }
      }
    },
    async getLoggedInUserDetails() {
      try {
        const getLoggedInUserResponse = await getLoggedInUserDetailService();
        if (getLoggedInUserResponse.status === HTTP_STATUS_CODE.SUCCESS) {
          this.userID = getLoggedInUserResponse.data;
        } else {
          throw new Error();
        }
      } catch (err) {
        this.toasterMessage = TOASTER_MESSAGE.TROUBLE;
      }
    },
    closeDialog() {
      this.openDialog = false;
    },
    openInfoWindowTemplate(index) {
      const { price } = this.hotelList[index];
      this.infoWindow.position = { lat: this.hotelList[index].location.coordinates[1], lng: this.hotelList[index].location.coordinates[0] };
      this.infoWindow.template = `<b>${price}<br>`;
      this.infoWindow.open = true;
    },
    handleCardHover(value) {
      this.showByIndex = value;
    },
    checkPrice(hotel) {
      return Lodash.some(hotel.rooms, room => room.isAvailable) ? hotel.minimumPrice : '--';
    },

    saveRoom(room) {
      this.room = room;
    },
    saveTime(time) {
      this.duration = time;
    },
  },
  computed: {
    ...mapGetters(['mapShowStatus']),
    getMarkerIcon() {
      return {
        url: 'https://maps.google.com/mapfiles/kml/shapes/parking_lot_maps.png', // Replace with your own icon URL
        scaledSize: new google.maps.Size(20, 20),
      };
    },
  },
};

</script>

<style lang="scss">
.vue-map-container .gm-ui-hover-effect{
display: none!important;
}
.gm-style-iw-d {
    overflow: hidden !important;
}
.gm-style .gm-style-iw-c {
  padding: 0px;
}
.hotel-cards {
  .search-results-outer-container {
    margin-right: -25px;
  }
  .search-results {
    flex-wrap: wrap;
    gap: 5px;
    .hotel-card-outer {
      @media #{map-get($display-breakpoints, 'md-and-down')} {
        padding: 3px;
      }
      cursor: pointer;
    }
  }
  .capitalize {
    text-transform: capitalize;
  }
}

.mapView-div{
  width: 65%;
  position: sticky;
  top: 150px;
  margin-top: 10px;
}

.map-view {
  width: 100%;
  height: 1320px;
}
.map-main-div{
 // position: sticky;
  top: 0;
  z-index: .99;
  display: flex;

  .map-hotel-view {
    width: 435px;
    // margin-left: 10px;
    height: 1328px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px;
    position: relative;
  }
}
  .hotel-view {
    width: 100%;
    height: 1340px;
    overflow-x: hidden;
    margin-left: 10px;
    margin-top: 8px;
    margin-bottom: 2px;
    overflow-y: auto;
    position: relative;
  }
.map-pointer-icon{
  width: 20px;
  height: 20px;
  background-color: #000;
}

 .custom-marker {
   // background-color: #F28827;
    color: #F28827;
    padding: 10px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    width: 52px;
  }
// responsiveness design
@media only screen and (max-width: 768px) {
  .search-results {
    .hotel-card-outer {
      margin-right: 5px !important;
    }
  }
  .for-display-flex{
    display: flex;
  }

  .hotel-cards .search-results-outer-container {
   margin-right: 0px;
}
.hotel-cards .search-results {
  width: 100%;
}
.hotel-cards .search-results .hotel-card-outer {
    padding: 3px;
    width: calc((100% - 15px) / 2);
}
  .map-main-div{
    display:initial;
    .map-hotel-view {
       width: 100%;
      padding: 0px;
      margin-left: 0px;
      overflow-y: hidden;
      height: 100%;
    }
  }

    .hotel-view {
    width: 100%;
    padding: 0px;
    margin-left: 0px;
    height: auto;
  }
  .mapView-div{
  width: 100%;
  position: sticky;
  top: 150px;
  margin: 6% auto 10%;
  }
  .map-view {
  height: 480px;
}
}

</style>
