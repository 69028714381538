import Vue from 'vue';
import VueCookies from 'vue-cookies';
import moment from 'moment';
import router from '../../router';
import axios from '../../axios';
import { TOASTER_MESSAGE } from '../../constants';

Vue.use(VueCookies);

export default {
  state() {
    return {
      currentBookings: [],
      selectedBooking: {},
      totalAmount: 0,
      bookingSteps: {
        confirm: 'pending',
        payment: 'pending',
        guests: 'pending',
      },
      booking: {},
      guests: {
        firstName: '',
        lastName: '',
        email: '',
        mobile: undefined,
        comments: '',
        userType: 'APP',
        selectedUserType: 'Myself',
        updateEmail: false,
      },
      couponCode: '',
      cancelledBooking: {},
      selectedUserType: 'Myself',
      bookingError: '',
      bookingFormValidate: false,
    };
  },
  getters: {
    booking: (state) => {
      const { booking } = state;
      const newBooking = { ...booking };

      newBooking.date = moment(newBooking.date, 'YYYY-MM-DD').format('ddd DD MMM YY');
      newBooking.time = newBooking.time;
      switch (newBooking.duration) {
        case '3h':
          newBooking.durationInHours = '3 hours';
          break;
        case '6h':
          newBooking.durationInHours = '6 hours';
          break;
        case '12h':
          newBooking.durationInHours = '12 hours';
          break;
        default:
          newBooking.durationInHours = '3 hours';
          break;
      }
      return newBooking;
    },
    bookingAmount: state => state.totalAmount,
    bookingSteps: state => state.bookingSteps,
    guests: state => state.guests,
    bookingFormValidate: state => state.bookingFormValidate,
    couponCode: state => state.couponCode,
  },
  mutations: {
    setBookingStepStatus(state, { step, status }) {
      const { bookingSteps } = state;

      bookingSteps[step] = status;
      state.bookingSteps = { ...bookingSteps };
    },
    setCurrentBookings(state, payload) {
      state.currentBookings = payload;
    },
    setCouponCode(state, payload) {
      state.couponCode = payload;
    },
    setBooking(state, booking) {
      state.selectedBooking = booking;
    },
    setSelectedRoom(state, roomDetails) {
      state.booking = roomDetails;
    },
    setTotalAmount(state, { total }) {
      state.totalAmount = total;
      Vue.$cookies.set('bookingAmount', { total });
    },
    cancelBooking(state, payload) {
      state.cancelledBooking = payload;
    },
    setSelectedUserType(state, payload) {
      state.selectedUserType = payload;
    },
    setField(state, { field, value }) {
      const { guests } = state;
      guests[field] = value;
    },
    setBookingError(state, payload) {
      if (payload.cardError) {
        state.bookingError = payload.error;
      } else if (payload.response) {
        if (payload.response.data && payload.response.data.error) {
          state.bookingError = payload.response.data.error;
        } else {
          state.bookingError = TOASTER_MESSAGE.TROUBLE;
        }
      } else {
        state.bookingError = TOASTER_MESSAGE.TROUBLE;
      }
    },
    setError(state, payload) {
      if (payload) {
        state.bookingError = TOASTER_MESSAGE.TROUBLE;
      }
    },
    setCancelBookingError(state, payload) {
      if (payload.response) {
        if (payload.response.data && payload.response.data.error) {
          state.bookingError = payload.response.data.error;
        } else {
          state.bookingError = TOASTER_MESSAGE.TROUBLE;
        }
      } else {
        state.bookingError = TOASTER_MESSAGE.TROUBLE;
      }
    },
    resetGuest(state) {
      state.guests = {
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        comments: '',
        password: '',
        userType: 'APP',
        selectedUserType: 'Myself',
      };
    },
    resetBookingSteps(state) {
      state.bookingSteps = {
        confirm: 'pending',
        payment: 'pending',
        guests: 'pending',
      };
    },
    resetSelectedUserType(state) {
      state.selectedUserType = 'Myself';
    },
    resetError(state, payload) {
      state.bookingError = payload;
    },
    setBookingFormValidate(state, payload) {
      state.bookingFormValidate = payload;
    },
  },
  actions: {
    setField({ commit }, payload) {
      commit('setField', payload);
    },
    setSelectedUserType({ commit }, payload) {
      commit('setSelectedUserType', payload);
    },
    setBookingStepStatus({ commit }, payload) {
      commit('setBookingStepStatus', payload);
    },
    setTotalAmount({ commit }, amount) {
      commit('setTotalAmount', amount);
    },
    setBookingError({ commit }, err) {
      commit('setBookingError', err);
    },
    async getBookingsByType({ commit }, payload) {
      try {
        const { data } = await axios.get(`mobile/user/bookings?type=${payload.type}&currentTime=${payload.currenTime}&timeZoneUser=${payload.timeZone}`);
        commit('setCurrentBookings', data);
      } catch (err) {
        commit('setError', err);
      }
    },
    async cancelBooking({ commit }, payload) {
      try {
        // eslint-disable-next-line no-underscore-dangle
        const { data } = await axios.put(`reservation/hotel/${payload.hotelId._id}/room/${payload.roomId._id}/reservation/${payload._id}`, { status: 'Cancelled' });
        commit('cancelBooking', data);
      } catch (err) {
        commit('setCancelBookingError', err);
      }
    },
    async bookHotel({ commit }, payload) {
      try {
        // eslint-disable-next-line no-underscore-dangle
        await axios.post(`reservation/hotel/${payload.hotelId}/room/${payload.roomId}`, payload);
        const createdAt = +new Date(moment(payload.reservationStart).toISOString());
        const dateDiff = moment().diff(createdAt, 'days');
        let printMessage = null;
        if (dateDiff < 0) {
          printMessage = 'A confirmation has been sent to your email. Payment will be deducted from your credit card within 24 hours of your reservation.';
        } else {
          printMessage = 'Thanks for your payment. A confirmation has been sent to your email.';
        }
        localStorage.setItem('displayMessage', printMessage);
        router.push('/booking-success');
      } catch (err) {
        commit('setBookingError', err);
      }
    },
    resetBookingError({ commit }) {
      commit('resetError', null);
    },
    resetGuest({ commit }) {
      commit('resetGuest');
    },
    resetBookingSteps({ commit }) {
      commit('resetBookingSteps');
    },
    resetSelectedUserType({ commit }) {
      commit('resetSelectedUserType');
    },
    setBookingFormValidate({ commit }, payload) {
      commit('setBookingFormValidate', payload);
    },
  },
};
