const axios = require('axios');
const constants = require('@/constants');

module.exports = {
  getFavouriteHotelsService: () => new Promise((resolve, reject) => {
    axios.get(constants.API.FAVOURITE_HOTELS,
      {
        headers: {
          Authorization: `Bearer ${window.$cookies.get('token')}`,
        },
      }).then(response => resolve(response))
      .catch(err => reject(err));
  }),
  setFavorite: hotelId => new Promise((resolve, reject) => {
    axios.put(`${constants.API.FAVOURITE_HOTELS}/${hotelId}`, null,
      {
        headers: {
          Authorization: `Bearer ${window.$cookies.get('token')}`,
        },
      }).then(response => resolve(response))
      .catch(err => reject(err));
  }),
  setUnfavorite: hotelId => new Promise((resolve, reject) => {
    axios.delete(`${constants.API.FAVOURITE_HOTELS}/${hotelId}`,
      {
        headers: {
          Authorization: `Bearer ${window.$cookies.get('token')}`,
        },
      }).then(response => resolve(response))
      .catch(err => reject(err));
  }),
};
